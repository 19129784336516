import React from "react";

export default function MinusIcon({ fill, size, className, onClickCallback }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={className}
      onClick={onClickCallback}
    >
      <defs>
        <path
          id="yl73qgqi7a"
          d="M12 7L6.857 7 5.143 7 0 7 0 5 5.143 5 6.857 5 12 5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g transform="translate(-254 -1862) translate(32 586) translate(0 136) translate(0 812) translate(16 233) translate(190 83) translate(16 12)">
                        <path d="M0 0H12V12H0z" />
                        <mask id="safzzl9mxb" fill="#fff">
                          <use xlinkHref="#yl73qgqi7a" />
                        </mask>
                        <use
                          fill="#000"
                          fillRule="nonzero"
                          xlinkHref="#yl73qgqi7a"
                        />
                        <path
                          fill={fill}
                          d="M0 0H12V12H0z"
                          mask="url(#safzzl9mxb)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
