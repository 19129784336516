export default function (url, callback, id) {
  let script;

  if (id && document.getElementById(id)) {
    script = document.getElementById(id);
    const oldFunc = script.onload;
    setupCallback(script, () => {
      oldFunc && oldFunc();
      callback();
    });
  } else {
    script = document.createElement("script");
    script.type = "text/javascript";
    script.crossorigin = true;
    script.defer = true;
    setupCallback(script, callback);
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}

function setupCallback(script, callback) {
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
}
