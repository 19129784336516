import React from "react";
import Shimmer from "react-shimmer-effect";

import { ITEM_MINIMAL_DATA } from "../../../mock-data/index.mock-data";
import "./index.component.scss";

const StoreLocatorShimmer = () => {
  return (
    <div className="store-locator-wrapper-shimmer">
      <div className="container">
        <div className="store-locator-container-shimmer">
          <div className="map-wrapper-shimmer">
            <div className="map-wrapper-two">
              <Shimmer>
                <div className="store-locator-map-shimmer" />
              </Shimmer>
            </div>
          </div>
          <div className="store-locator-search-shimmer">
            <Shimmer>
              <div className="store-locator-header-shimmer" />
            </Shimmer>

            {ITEM_MINIMAL_DATA.map((item) => (
              <div key={item} className="store-locator-card-shimmer">
                <Shimmer>
                  <div className="store-locator-placeholder-one-shimmer" />
                  <div className="store-locator-placeholder-two-shimmer" />
                </Shimmer>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreLocatorShimmer;
