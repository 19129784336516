import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import TimeSlot from "../../../store/models/TimeSlot";
import { getOffsetTime } from "./getOffsetTime";

export function getAvailableSlots({ fulfillmentType, dateTime, store, biz }) {
  dayjs.extend(customParseFormat);

  if (!store || !biz || !dateTime) {
    throw "Store | Biz | DateTime is Undefined";
  }

  /**
   * cannot use a day name here, instead we need to use a number, because day names
   * can change according to the activeLanguage and may cause unwanted behaviour of date and time slots.
   * ex: tuesday(english) and martes(spanish) both are 2 day of the week.
   *
   * We can rather use position of the day in the week
   * and translate it to english to implement the logic.
   */
  const weekArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let dayOfTheWeek = dayjs(dateTime).day();

  const slots = store.time_slots || biz.time_slots || [];
  let offset = getOffsetTime({ fulfillmentType, store, biz, dateTime });

  let filteredSlots = slots.filter(function (slot) {
    return slot.day === weekArray[dayOfTheWeek];
  });

  filteredSlots = filteredSlots.filter(function (slot) {
    let slotEndTime = dayjs(slot.end_time, "hh:mm:ss");
    let endTime = dateTime.clone();

    endTime = endTime
      .set("hour", slotEndTime.hour())
      .set("minute", slotEndTime.minute())
      .set("second", slotEndTime.second())
      .set("millisecond", 0);

    //  offset should subtracted from slotend time even if its a future date.
    //  https://github.com/urbanpiper/issues-meraki/issues/326
    // if(!dateTime.isAfter()){
    endTime = endTime.subtract(offset, "milliseconds");
    // }

    return dateTime <= endTime;
  });

  let sortedSlots = filteredSlots.map((slot) => new TimeSlot(slot));
  return sortedSlots.sort((a, b) => a.startTime - b.startTime);
}
