import {
  FETCH_COUPONS_ERROR,
  FETCH_COUPONS_PENDING,
  FETCH_COUPONS_SUCCESS,
} from "../actions/coupons";

const initialState = {
  pending: false,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COUPONS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_COUPONS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
      };
    case FETCH_COUPONS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
