import React from "react";

export default function SearchIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <defs>
        <path
          id="1qww4bajpa"
          d="M7.429 0c4.102 0 7.428 3.326 7.428 7.429 0 1.84-.674 3.531-1.783 4.834l.309.308h.903L20 18.286 18.286 20l-5.715-5.714v-.903l-.308-.309c-1.303 1.109-2.994 1.783-4.834 1.783C3.326 14.857 0 11.531 0 7.43 0 3.326 3.326 0 7.429 0zm0 2.286c-2.858 0-5.143 2.285-5.143 5.143 0 2.857 2.285 5.142 5.143 5.142 2.857 0 5.142-2.285 5.142-5.142 0-2.858-2.285-5.143-5.142-5.143z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-975 -76) translate(32 62) translate(927) translate(16 14)">
                  <path d="M0 0H20V20H0z" />
                  <use fill={fill} fillRule="nonzero" xlinkHref="#1qww4bajpa" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
