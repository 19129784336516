export const RESET_DELIVERY_DETAILS = "RESET_DELIVERY_DETAILS";
export const SELECTED_SLOT = "SELECTED_SLOT";
export const DELIVER_DATE_TIME = "DELIVER_DATE_TIME";
export const DELIVERY_TYPE = "DELIVERY_TYPE";

export const setDeliveryTimeCallback = (data) => ({
  type: DELIVER_DATE_TIME,
  data: data,
});

export const setSelectedSlotCallback = (data) => ({
  type: SELECTED_SLOT,
  data: data,
});

export const setSelectedType = (data) => {
  return {
    type: DELIVERY_TYPE,
    data: data,
  };
};

export const resetDeliveryDetails = (data) => ({
  type: RESET_DELIVERY_DETAILS,
  data: data,
});
