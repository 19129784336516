import toast from "react-hot-toast";

export const showNotification = (message, type) => {
  if (type === "success") {
    toast.success(message);
  } else if (type === "error") {
    toast.error(message);
  } else {
    toast(message);
  }
};

export const getCDNLogoImageURL = (src, size) => {
  if (src) {
    try {
      const url = new URL(src);
      const encodedUrl = encodeURIComponent(url.origin + url.pathname);
      return `https://luna-meraki.svc.urbanpiper.com/_next/image?url=${encodedUrl}&q=80&w=${size}`;
    } catch (e) {
      return src;
    }
  }
};
