import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "../typography/index.component";
import AppButton from "../button/index.component";
import Dialog from "@material-ui/core/Dialog";

import { COLOR_WHITE } from "../../constants/colors.constants";
import CrossIcon from "../../assets/icons/cross.icon";
import ByRefAlreadySignedInIcon from "../../assets/icons/byRefAlreadySignedIn.icon";

import "./index.styles.scss";

function GenericErrorDialog({ primaryColor, open, handleClose, forComponent }) {
  const { t } = useTranslation();

  const handlePageRefresh = () => {
    handleClose();
  };

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={handleClose}
      classes={{
        root: "confirmation-dialog-root",
        container: "confirmation-dialog-container",
        paper: "confirmation-dialog-paper",
      }}
      disableScrollLock={true}
    >
      <div className="generic-error-dialog-wrapper">
        <div className="generic-error-dialog-header">
          <CrossIcon
            fill="#A3A3A4"
            size={{ width: 16, heigth: 16 }}
            className="close-dialog-icon"
            onClickCallback={handleClose}
          />
        </div>
        <ByRefAlreadySignedInIcon size={{ width: 250, heigth: 150 }} />
        <Typography
          variant="h1"
          weight="bold"
          className="generic-error-dialog-title"
        >
          {t("common.wentWrongTitle")}
        </Typography>
        <Typography
          variant="h3"
          weight="regular"
          className="generic-error-dialog-desc"
        >
          {t("common.loadingError", { componentName: forComponent })}
        </Typography>
        <AppButton
          fullWidth
          variant="contained"
          className="generic-error-dialog-refresh"
          onClickCallback={handlePageRefresh}
          buttonColor={primaryColor}
        >
          <Typography variant="h3" weight="bold" fontColor={COLOR_WHITE}>
            {t("common.refresh")}
          </Typography>
        </AppButton>
      </div>
    </Dialog>
  );
}

export default GenericErrorDialog;
