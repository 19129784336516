import HeroSection from "../views/landing-screen/hero-section/index.component";
import BrandValueSection from "../views/landing-screen/brand-values-section/index.component";
import RecommendedSection from "../views/landing-screen/recommended-section/index.component";
import TestimonialSection from "../views/landing-screen/testimonial-section/index.component";
import MenuSection from "../views/landing-screen/menu-section/index.component";
import Form from "../common/form/index.component";

const HERO_BANNER = "heroBanner";
const BRAND_VALUES = "brandValues";
const MENU_SECTION = "menuSection";
const TESTIMONIALS = "testimonials";
const RECOMMENDED_ITEMS = "recommendedItems";
const FORM = "form";

function renderComponent(element, props = {}) {
  if (!element) return null;
  if (element.customizations && !element.customizations.enabled) return null;
  switch (element._id) {
    case HERO_BANNER:
      return (
        <HeroSection
          data={element.customizations}
          {...props}
          key={HERO_BANNER}
        />
      );
    case BRAND_VALUES:
      return (
        <BrandValueSection
          data={element.customizations}
          {...props}
          key={BRAND_VALUES}
        />
      );
    case MENU_SECTION:
      return <MenuSection data={element.customizations} key={MENU_SECTION} />;
    case TESTIMONIALS:
      return (
        <TestimonialSection
          data={element.customizations}
          {...props}
          key={TESTIMONIALS}
        />
      );
    case RECOMMENDED_ITEMS:
      return (
        <RecommendedSection
          data={element.customizations}
          {...props}
          key={RECOMMENDED_ITEMS}
        />
      );
    default:
      return null;
  }
}

function renderGenericComponent(element, props = {}) {
  if (!element) return null;
  switch (element._id) {
    case FORM:
      return <Form componentData={element.customizations} {...props} />;
    default:
      return null;
  }
}

export default function renderComponents(data, props = {}) {
  const comp = [];
  data.forEach((element) => {
    comp.push(renderComponent(element, props));
  });
  return comp;
}

export function renderGenericComponents(data, props = {}) {
  const comp = [];
  data.forEach((element) => {
    comp.push(renderGenericComponent(element, props));
  });
  return comp;
}
