import { clearLikes, fetchFavorites, fetchLikes } from "./likes";
import upsdkService from "../../services/upsdkService";
export const USER_LOGOUT = "CLEAR_USER";

export const FETCH_USER_PENDING = "FETCH_USER_PENDING";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";

function logout() {
  return {
    type: USER_LOGOUT,
  };
}

function setUser(data) {
  return {
    type: FETCH_USER_SUCCESS,
    payload: data,
  };
}

export function userLogin(data) {
  return (dispatch) => {
    dispatch(setUser(data));
    dispatch(fetchLikes());
    dispatch(fetchFavorites());
  };
}

export function guestUserLogin(data) {
  return (dispatch) => {
    data.guest_checkout = true;
    dispatch(setUser(data));
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch(logout());
    dispatch(clearLikes());
  };
}

function fetchUserPending() {
  return {
    type: FETCH_USER_PENDING,
  };
}

function fetchUserSuccess(data) {
  return {
    type: FETCH_USER_SUCCESS,
    payload: data,
  };
}

function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    error: error,
  };
}

export default function fetchUser() {
  return (dispatch) => {
    dispatch(fetchUserPending());
    upsdkService
      .getUserDetails()
      .then((response) => {
        dispatch(fetchUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserError(error));
      });
  };
}
