import React, { useEffect, useState } from "react";
import MinusIcon from "../../assets/icons/minus.icon";
import PlusIcon from "../../assets/icons/plus.icon";
import { COLOR_WHITE } from "../../constants/colors.constants";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function Counter({
  itemCount,
  primaryColor,
  fontColor,
  countIncrementCallback,
  countDecrementCallback,
  currentStock,
  cart,
  isDisabled = false,
}) {
  const [itemLimitReached, setItemLimitReached] = useState(false);
  const [animateAdd, setAnimateAdd] = useState(false);
  const [animateRemove, setAnimateRemove] = useState(false);

  useEffect(() => {
    if (currentStock) {
      if (itemCount === currentStock) {
        setItemLimitReached(true);
      } else {
        setItemLimitReached(false);
      }
    }
  }, [currentStock, itemCount]);

  const countIncrementCallbackanimate = () => {
    if (isDisabled) return;
    setAnimateRemove(false);
    setAnimateAdd(true);
    countIncrementCallback();
  };

  const countDecrementCallbackAnimate = () => {
    if (isDisabled) return;
    setAnimateAdd(false);
    setAnimateRemove(true);
    countDecrementCallback();
  };

  return (
    <div
      className="counter-wrapper"
      style={{
        backgroundColor: primaryColor,
        borderColor: primaryColor,
        opacity: isDisabled ? 0.5 : 1,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <MinusIcon
        className={`decrementor ${!cart ? "animate-decrementor" : ""}`}
        fill={fontColor || COLOR_WHITE}
        onClickCallback={countDecrementCallbackAnimate}
      />
      <Typography
        variant="h3"
        weight="bold"
        className={`item-count ${
          animateAdd ? "animateAdd" : animateRemove ? "animateRemove" : ""
        }`}
        fontColor={fontColor || COLOR_WHITE}
        key={!cart ? itemCount : null}
      >
        {itemCount}
      </Typography>
      <PlusIcon
        fill={itemLimitReached ? "#D3D3D3" : fontColor || COLOR_WHITE}
        className={`incrementor ${!cart ? "animate-incrementor" : ""}`}
        size={{ width: 18, height: 18 }}
        onClickCallback={
          itemLimitReached ? null : countIncrementCallbackanimate
        }
      />
    </div>
  );
}
