import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import Typography from "../typography/index.component";

import "./index.component.scss";

export default function ItemBadge({ isBestSeller, isFeatured }) {
  // Callbacks
  const { t } = useTranslation();

  return (
    <div
      className={classNames([
        "item-badge-wrapper",
        { bestseller: isBestSeller, featured: isFeatured },
      ])}
    >
      <Typography variant="h4" weight="bold" className="badge-text">
        {isBestSeller
          ? t("itemCard.bestseller")
          : isFeatured
          ? t("itemCard.featured")
          : null}
      </Typography>
    </div>
  );
}
