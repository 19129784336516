import React from "react";
export default function FavouriteContainedIcon({ fill, size, className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99999 2.66675C2.975 2.66675 1.33333 4.30842 1.33333 6.33342C1.33333 10.0001 5.66666 13.3334 8 14.1087C10.3333 13.3334 14.6667 10.0001 14.6667 6.33342C14.6667 4.30842 13.025 2.66675 11 2.66675C9.76 2.66675 8.66333 3.28242 8 4.22475C7.66189 3.74315 7.21272 3.35011 6.69051 3.07891C6.1683 2.80771 5.58843 2.66633 4.99999 2.66675Z"
        fill="#CC0000"
        stroke="#CC0000"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
