export default function storeStatus(store, bizInfo) {
  if (store) {
    if (
      (!store.opening_time || !store.closing_time) &&
      (!store.temporarily_closed || !store.closing_day)
    ) {
      if (store.closing_day || store.temporarily_closed) {
        return {
          closed: true,
          futureOrders: false,
          title: "Store Closed",
          message: "Store is closed for the day",
        };
      } else {
        return {
          closed: true,
          futureOrders: false,
          title: "Error",
          message: "Store timings are not configured",
        };
      }
    }
  }

  let message = "";
  let title = "";
  let {
    enabled_for_ordering,
    temporarily_closed,
    closing_day,
    closed,
    close_msg,
  } = store;

  if (
    temporarily_closed ||
    closing_day ||
    closed ||
    (enabled_for_ordering !== undefined && !enabled_for_ordering)
  ) {
    if (
      store.closing_day ||
      (enabled_for_ordering !== undefined && !enabled_for_ordering)
    ) {
      if (!bizInfo) {
        return;
      }
      title = "Store Closed";
      message =
        bizInfo.msg_nearest_store_closed ||
        close_msg ||
        "Store is closed for the day";
    } else {
      title = "Temporarily closed!";
      message =
        bizInfo.msg_store_closed_temporary || "Store is temporarily closed";
    }
    return {
      closed: true,
      futureOrders: false,
      title: title,
      message: message,
    };
  }

  return {
    closed: false,
    futureOrders: true,
    title: title,
    message: message,
  };
}
