import React, { memo } from "react";
import { constructSlug } from "common/helpers/constructSlug";
import Typography from "../../../../common/typography/index.component";
import LazyImage from "../../../../common/LazyImage";
import useRouteHook from "../../../../hooks/useRoute.hook";

import "./index.component.scss";

function RecommendedItem(props) {
  const { historyPush } = useRouteHook();
  const { item, primaryTextColor } = props;

  const handleItemClick = ({ item }) => {
    const { id, name } = item;
    const itemSlug = constructSlug(name, item.raw_data.slug || item.slug);
    const contentEle = document.getElementById("content-wrapper");
    sessionStorage.setItem("landingScrollPos", contentEle.scrollTop);
    historyPush(`/item-list/${id}/${itemSlug}`);
  };

  return (
    <div
      key={item.id}
      className="recommended-item"
      onClick={() => handleItemClick({ item })}
    >
      <LazyImage
        size={320}
        className="item-image"
        src={item.thumbnail}
        image_blur_hash={item.image_blur_hash}
      />
      <Typography
        variant="h3"
        weight="semiBold"
        className="item-name"
        fontColor={primaryTextColor}
      >
        {item.name}
      </Typography>
    </div>
  );
}

export default memo(RecommendedItem);
