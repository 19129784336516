import React from "react";
import { COLOR_DARK_GREY } from "../../constants/colors.constants";

export default function CardOnDeliveryIcon({
  fill = COLOR_DARK_GREY,
  size,
  className,
}) {
  return (
    <svg
      width="46"
      height="36"
      className={className}
      viewBox="0 0 46 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.5 4.5625V31.4375C45.5 32.6042 45.115 33.5733 44.345 34.345C43.5733 35.115 42.6042 35.5 41.4375 35.5H4.5625C3.39583 35.5 2.4275 35.115 1.6575 34.345C0.885833 33.5733 0.5 32.6042 0.5 31.4375V4.5625C0.5 3.39583 0.885833 2.4275 1.6575 1.6575C2.4275 0.885833 3.39583 0.5 4.5625 0.5H41.4375C42.6042 0.5 43.5733 0.885833 44.345 1.6575C45.115 2.4275 45.5 3.39583 45.5 4.5625ZM3 10H43V4.5625C43 4.14583 42.8433 3.78167 42.53 3.47C42.2183 3.15667 41.8542 3 41.4375 3H4.5625C4.14583 3 3.78167 3.15667 3.47 3.47C3.15667 3.78167 3 4.14583 3 4.5625V10ZM3 16V31.4375C3 31.8542 3.15667 32.2183 3.47 32.53C3.78167 32.8433 4.14583 33 4.5625 33H41.4375C41.8542 33 42.2183 32.8433 42.53 32.53C42.8433 32.2183 43 31.8542 43 31.4375V16H3Z"
        fill={fill}
      />
    </svg>
  );
}
