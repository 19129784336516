class TextHelpers {
  /**
   * Decode HTML (removes escape html)
   *
   * @param {string} encoded html
   * @returns {string} decoded html
   */
  static htmlDecode(content) {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
}

export default TextHelpers;
