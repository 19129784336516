import React from "react";

import Typography from "../typography/index.component";

import "./index.component.scss";

export default function FloatingActionButton(props) {
  const { style, buttonText } = props;
  const { clickHandler } = props;

  return (
    <div
      className="action-button-wrapper"
      style={{ ...style }}
      onClick={clickHandler}
    >
      <Typography
        variant="h2"
        weight="bold"
        className="button-label"
        fontColor="#FFFFFF"
      >
        {buttonText}
      </Typography>
    </div>
  );
}
