import React from "react";

export default function PickUpIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <defs>
        <path
          id="qy8stoc4va"
          d="M12.167 13.571c-2.813 0-5.093-2.345-5.093-5.238h2.037c0 1.736 1.368 3.143 3.056 3.143 1.687 0 3.055-1.407 3.055-3.143h2.037c0 2.893-2.28 5.238-5.092 5.238zm0-10.476c1.687 0 3.055 1.407 3.055 3.143h-6.11c0-1.736 1.367-3.143 3.055-3.143zm7.13 3.143h-2.038c0-1.39-.536-2.721-1.491-3.704C14.813 1.552 13.518 1 12.167 1 9.354 1 7.074 3.345 7.074 6.238H5.037C3.907 6.238 3 7.17 3 8.333v12.572C3 22.062 3.912 23 5.037 23h14.26c1.124 0 2.036-.938 2.036-2.095V8.333c0-1.163-.916-2.095-2.037-2.095z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-270 -74) translate(32 62) translate(238 12)">
                  <path d="M0 0H24V24H0z" />
                  <mask id="acm0rm3hhb" fill="#fff">
                    {/* <use xlinkHref="#qy8stoc4va"/> */}
                  </mask>
                  <use fill={fill} fillRule="nonzero" xlinkHref="#qy8stoc4va" />
                  <path fill="none" d="M0 0H24V24H0z" mask="url(#acm0rm3hhb)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
