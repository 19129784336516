export const ITEM_DUMMY_DATA = [
  { id: 1, placeholder: 1 },
  { id: 2, placeholder: 2 },
  { id: 3, placeholder: 3 },
  { id: 4, placeholder: 4 },
  { id: 5, placeholder: 5 },
  { id: 6, placeholder: 6 },
  { id: 7, placeholder: 7 },
  { id: 8, placeholder: 8 },
  { id: 9, placeholder: 9 },
  { id: 10, placeholder: 10 },
  { id: 11, placeholder: 11 },
  { id: 12, placeholder: 12 },
  { id: 13, placeholder: 13 },
];

export const ITEM_CATEGORY_DUMMY_DATA = [
  { id: 1, placeholder: 1 },
  { id: 2, placeholder: 2 },
  { id: 3, placeholder: 3 },
  { id: 4, placeholder: 4 },
  { id: 5, placeholder: 5 },
  { id: 6, placeholder: 6 },
  { id: 7, placeholder: 7 },
  { id: 8, placeholder: 8 },
];

export const ITEM_MINIMAL_DATA = [1, 2, 3];

export const RATINGS_ARRAY_LONG = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const DEFAULT_LANDING_PAGE_CONFIG = {
  _id: "landing_screen",
  name: "Landing Screen",
  enabled: true,
  seo: {
    en: {
      title: "PiperCafe",
      description: "PiperCafes",
      keywords: "PiperCafess",
    },
  },
  customizations: {},
  components: [
    {
      _id: "heroBanner",
      name: "Hero banner",
      customizations: {
        enabled: true,
        title: "Start ordering!",
        description: "Life is uncertain. Eat dessert first.",
        backgroundImage:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/Pasta2.png",
        subTitle: "Amazing offers",
        enableLocationSearch: true,
        featuredImage:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/Untitled_design_2.png",
        mobileFeaturedImage:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/05/Olive_pasta.jfif",
        subTitleColor: "#FF4C77",
        logoUrl:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/Untitled_design_1-PhotoRoom.png",
      },
    },
    {
      _id: "brandValues",
      name: "Brand values",
      customizations: {
        enabled: true,
        values: [
          {
            id: 1,
            label: "Delivery",
            icon: "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/BV_2.png",
            description:
              "We put only the A-grade quality ingriedients in every dish and plate we serve hygienely",
          },
          {
            id: 2,
            label: "Hygiene",
            icon: "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/4.png",
            description:
              "We are dedicated to serve only the finest and tastiest food to our customers",
          },
          {
            id: 3,
            label: "Nutritious",
            icon: "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/3.png",
            description:
              "Worry not about waiting too long for your dish, We got that covered for you!",
          },
          {
            id: "elgp",
            label: "Safety",
            description:
              "We are dedicated to serve only the finest and tastiest food to our customers",
            icon: "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/BV_1.png",
          },
        ],
        title: "Why choose us?",
        description:
          "Running a restaurant is difficult, which is why we work hard to make sure your business has what it needs to thrive. When you're with UrbanPiper, you get solutions that not only work well independently but also integrate seamlessly together.",
        readMoreCtaUrl: "https://www.urbanpiper.com/about-us",
      },
    },
    {
      _id: "menuSection",
      name: "Menu section",
      customizations: {
        enabled: true,
        description:
          "Have a look at our exotic menu curated by award winning chefs.",
      },
    },
    {
      _id: "recommendedItems",
      name: "Recommended Items",
      customizations: {
        enabled: true,
        description:
          "The best of italian, continental, Indian, and American cusines cooked to perfection. Click on menu below to see all that we have to offer. ",
        backgroundImage:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/05/Cheesy_Grilled_Chicken_Pasta.jfif",
        featuredDishImage:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/Untitled_design_3.png",
      },
    },
    {
      _id: "testimonials",
      name: "Testimonials",
      customizations: {
        enabled: true,
        backgroundImage: "",
        values: [
          {
            id: 1,
            author_img:
              "https://res.cloudinary.com/dmwvws7yf/image/upload/v1635078984/testimonial-author_p0pais.png",
            content:
              "If more of us valued food & cheer and song above hoarded gold, it would be a merrier world.",
            author_name: "Ben burnley",
            author_position: "chef & founder of Piper Cafe",
          },
          {
            id: 2,
            author_img:
              "https://res.cloudinary.com/dmwvws7yf/image/upload/v1635401602/download_yvjy4x.jpg",
            content:
              "If more of us valued food and cheer and song above hoarded gold, it would be a merrier world.",
            author_name: "Arthuro Roman",
            author_position: "Manager, Bank Of Spain",
          },
          {
            id: "9qs9",
            author_name: "Emma",
            author_position: "Line Chef, Piper Cafe",
            content: "Perfect food elegantly delivered",
            author_img:
              "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/05/Untitled_design_1.jpg",
          },
          {
            id: "qe0w",
            author_name: "John Doe",
            author_position: "Waiter",
            content: "Love the food, a must visit",
            author_img:
              "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/05/2.png",
          },
        ],
      },
    },
    {
      _id: "appPromotion",
      name: "App Promotion",
      customizations: {
        enabled: true,
        title: "Download our app",
        description: "We have exclusive offers for app users, get it now.",
        availableOnAndroid: true,
        androidLink:
          "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026382_creativeid_535350509675_device_c&gclid=CjwKCAiAwc-dBhA7EiwAxPRylN7G2ik0Z2kEamKSz_Jwaa72BQtam39USjVHo93Hy52aA76W6lZjihoC66IQAvD_BwE&gclsrc=aw.ds&pli=1",
        availableOnIOS: true,
        iOSLink:
          "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026382_creativeid_535350509675_device_c&gclid=CjwKCAiAwc-dBhA7EiwAxPRylN7G2ik0Z2kEamKSz_Jwaa72BQtam39USjVHo93Hy52aA76W6lZjihoC66IQAvD_BwE&gclsrc=aw.ds&pli=1",
        previewImageSmall:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/05/Tesla_new.gif",
        previewImageLarge:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/05/IMG_6276.jpg",
        phoneImage:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/1-PhotoRoom.png-PhotoRoom.png",
        phoneLargeImage:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/AP_2.png",
      },
    },
    {
      _id: "aboutUs",
      name: "About US",
      customizations: {
        enabled: true,
        description:
          "UrbanPiper provides restauranteurs all the essential tools to build a successful restaurant. Our products—Hub, Prime & Meraki are designed to help you thrive, even in light of an increasingly competitive marketplace. With UrbanPiper, you'll gain access to the building blocks you need for a profitable business, even as the industry evolves around you.",
        image:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/Untitled_design_7.png",
        imageTwo:
          "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/gallery_images/2023/01/11/Untitled_design_3.png",
        ctaUrl: "https://www.urbanpiper.com/about-us",
      },
    },
    {
      _id: "topbar",
      name: "Top Bar",
      customizations: {
        enabled: true,
        leftLink: "",
        rightLink: "",
      },
    },
    {
      _id: "footer",
      name: "Footer",
      customizations: {
        copyrightText: "",
        externalPageLinks: [],
        socialMediaLinks: "",
        contactUs: "",
      },
    },
  ],
  type: "theme",
  variant: "theme1",
};
export const DEFAULT_RECOMMENDED_ITEMS = [
  {
    category: {
      id: 96477,
      name: "Salads",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 200",
    food_type: "1",
    has_options: true,
    id: 845916,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Salad1_1673443357.png",
    item_desc:
      "The most traditional vegetables and herbs used in this salad are tomatoes, red onions, cucumbers, and parsley",
    item_price: 200,
    item_title: "Classic Veggie Salad with Tahini",
    likes: 0,
    markup_price: 300,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "classic-veggie-salad-with-tahini",
    sort_order: 0,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
  {
    category: {
      id: 96479,
      name: "Juices and Shakes",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 120",
    food_type: "1",
    has_options: false,
    id: 845927,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Juice1_1673443413.png",
    item_desc: "Seasonal Fruit Juice made from fruits available in the season",
    item_price: 120,
    item_title: "Seasonal Fruit Juice",
    likes: 0,
    markup_price: 0,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "seasonal-fruit-juice",
    sort_order: 0,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
  {
    category: {
      id: 96480,
      name: "Pastas",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 250",
    food_type: "1",
    has_options: true,
    id: 845933,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Pasta1_1673443437.png",
    item_desc: "Cream cheese, pasta, olive oil, chili flakes",
    item_price: 250,
    item_title: "Creamy Cheese Sauce Pasta",
    likes: 0,
    markup_price: 0,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "creamy-cheese-sauce-pasta",
    sort_order: 0,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
  {
    category: {
      id: 96471,
      name: "Sandwiches",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 200",
    food_type: "1",
    has_options: true,
    id: 845905,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Salad2_1673443468.png",
    item_desc:
      "made with a large assortment of vegetables, oodles of butter, a lavish splash of chutneys and a peppy sandwich masala",
    item_price: 200,
    item_title: "Chef's Special Veg Grilled Sandwich",
    likes: 0,
    markup_price: 300,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "chefs-special-veg-grilled-sandwich",
    sort_order: 1,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
  {
    category: {
      id: 96471,
      name: "Sandwiches",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 200",
    food_type: "1",
    has_options: true,
    id: 845908,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Pasta1_1673443508.png",
    item_desc:
      "Made with mushrooms stuffed in bread slices and fried to perfection",
    item_price: 200,
    item_title: "Mushroom Sandwich",
    likes: 0,
    markup_price: 0,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "mushroom-sandwich",
    sort_order: 5,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
  {
    category: {
      id: 96477,
      name: "Salads",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 250",
    food_type: "2",
    has_options: true,
    id: 845918,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Salad3_1673443537.png",
    item_desc:
      "The chicken is served over a bed of romaine lettuce, cucumbers, and radishes. The dressing is a savoury mixture of tahini, toasted sesame oil, and soy sauce.",
    item_price: 250,
    item_title: "Classic Chicken Salad with Honey Tahini Dressing",
    likes: 0,
    markup_price: 0,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "classic-chicken-salad-with-honey-tahini-dressing",
    sort_order: 5,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
  {
    category: {
      id: 96471,
      name: "Sandwiches",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 250",
    food_type: "1",
    has_options: true,
    id: 845909,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Sandwich1_1673443856.png",
    item_desc:
      "made with paneer (cottage cheese), bread, chopped vegetables & spiced up with black pepper, salt & sauces.",
    item_price: 250,
    item_title: "Paneer Sandwich",
    likes: 0,
    markup_price: 300,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "paneer-sandwich",
    sort_order: 6,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
  {
    category: {
      id: 96471,
      name: "Sandwiches",
      __typename: "Category",
    },
    current_stock: -1,
    display_price: "UPN 250",
    food_type: "3",
    has_options: true,
    id: 845911,
    image_blur_hash: null,
    image_url:
      "https://s3-ap-southeast-1.amazonaws.com/staging.urbanpiper.com/media/bizmedia/2023/01/11/Sandwich2_1673443913.png",
    item_desc:
      "This egg sandwich is a New Market–style grilled egg sandwich with a creamy filling of mayonnaise and soft-boiled eggs, and a buttery brown crust.",
    item_price: 250,
    item_title: "Boiled Egg Grilled Sandwich",
    likes: 0,
    markup_price: 300,
    price_descriptor: null,
    service_tax_rate: 0,
    slug: "boiled-egg-grilled-sandwich",
    sort_order: 7,
    vat_rate: 0,
    extras: [],
    __typename: "Item",
  },
];
export const DEFAULT_COUPONS = [
  {
    description: "15% off select items",
    redemption_code: "LIMITED15",
    title: "15% off select items",
    valid_until: 1699020802000,
  },
  {
    description: "Buy a pizza and get choco lava cake for free",
    redemption_code: "Buy2get1",
    title: "BUYXGETY",
    valid_until: 1702113817000,
  },
  {
    description:
      "20% discount with a max value of Rs.75/- .\nOnly Valid on orders placed through website\nCoupon valid till 20th of November",
    redemption_code: "LETGO2020",
    title: "20% discount",
    valid_until: 1702563378000,
  },
];
