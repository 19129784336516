import React, { useState } from "react";

import placeholder from "../../assets/images/placeholder.webp";
import Blurhash from "../BlurHash";

export default function LazyImage(props) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const {
    image_blur_hash,
    alt,
    src,
    itemCard,
    paddingTopProp,
    isMobileView,
    size,
    mobileHeight,
    className,
    imageProps = {},
  } = props;

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const handleImageError = () => {
    setError(true);
  };

  const imageStyle =
    !loaded && image_blur_hash
      ? { display: "none" }
      : itemCard
        ? !isMobileView
          ? { position: "absolute" }
          : { height: mobileHeight, objectFit: "cover" }
        : {};
  const wrapperStyle =
    itemCard && !isMobileView ? { paddingTop: paddingTopProp, height: 0 } : {};

  const getCDNImageURL = (src, size) => {
    if (src) {
      try {
        const url = new URL(src);
        const encodedUrl = encodeURIComponent(url.origin + url.pathname);
        return `https://luna-meraki.svc.urbanpiper.com/_next/image?url=${encodedUrl}&q=80&w=${size}`;
      } catch (e) {
        return src;
      }
    }
  };

  return (
    <div className={className} style={{ overflow: "hidden", ...wrapperStyle }}>
      {!loaded && image_blur_hash && (
        <Blurhash
          hash={image_blur_hash}
          width={400}
          height={2000} //makes sure to covers the full container of blurhash
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      {error ? (
        <img
          className={className}
          loading={imageProps.loading}
          fetchpriority={imageProps.fetchpriority}
          decoding={imageProps.decoding}
          src={placeholder}
          alt={alt}
          style={imageStyle}
        />
      ) : (
        <img
          className={className}
          loading={imageProps.loading}
          fetchpriority={imageProps.fetchpriority}
          decoding={imageProps.decoding}
          src={getCDNImageURL(src, size)}
          style={imageStyle}
          onLoad={handleImageLoaded}
          onError={handleImageError}
          alt={alt}
        />
      )}
    </div>
  );
}
