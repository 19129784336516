export default function debounce(func, delay, { leading } = {}) {
  let timer;
  if (!func) {
    return;
  }
  if (!delay) {
    return (...args) => {
      func(...args);
    };
  }

  return (...args) => {
    if (leading && !timer) {
      func(...args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
