export default function getFilterTitleArr(filterIdsArr, filtersData) {
  const filterTitleArr = [];
  if (filterIdsArr && filterIdsArr.length) {
    filterIdsArr.forEach((filterId) => {
      const filters = filtersData;
      for (let filter in filters) {
        const filterOptions = filters[filter].options;
        filterOptions?.find((filter) => {
          if (filter.id === filterId) {
            filterTitleArr.push(filter.title);
          }
        });
      }
    });
  }
  return filterTitleArr;
}
