let formatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

function currencyLocalesHelper(currencyCode) {
  switch (currencyCode) {
    case "INR":
      return {
        locale: "en-IN",
        formatOptions: {
          style: "currency",
          currency: currencyCode,
          useGrouping: true,
          minimumFractionDigits: 2,
        },
      };

    case "USD":
      return {
        locale: "en-US",
        formatOptions: {
          style: "currency",
          currency: currencyCode,
          useGrouping: true,
          minimumFractionDigits: 2,
        },
      };

    case "COP":
      return {
        locale: "es-CO",
        formatOptions: {
          style: "currency",
          currency: currencyCode,
          useGrouping: true,
          minimumFractionDigits: 0,
        },
      };

    case "CLP":
      return {
        locale: "es-CL",
        formatOptions: {
          style: "currency",
          currency: currencyCode,
          useGrouping: true,
          minimumFractionDigits: 0,
        },
      };

    case "JPY":
      return {
        locale: "ja-JP",
        formatOptions: {
          style: "currency",
          currency: currencyCode,
          useGrouping: true,
          minimumFractionDigits: 0,
        },
      };

    default:
      return {
        locale: "en-US",
        formatOptions: {
          style: "currency",
          currency: currencyCode,
          useGrouping: true,
          minimumFractionDigits: 2,
        },
      };
  }
}

export default class CurrencyHelper {
  /**
   * creates an instance currencyFormatter with provided currency
   * if no currency is provided, defaults to INR
   *
   * @param {string} currency
   */
  static setCurrency(currencyCode = "INR") {
    const { locale, formatOptions } = currencyLocalesHelper(currencyCode);
    formatter = new Intl.NumberFormat(locale, formatOptions);
  }

  /**
   * formats the number to a currency and value
   *
   * @param {number} amount
   * @returns {string} formattedAmount eg($9000)
   */
  static format(amount) {
    return formatter.format(amount);
  }

  /**
   * returns the currency
   *
   * @returns {string} the currency eg($)
   */
  static getCurrencySymbol() {
    const currencySymbol = formatter
      .formatToParts(0)
      ?.find((item) => item.type === "currency")?.value;
    return currencySymbol;
  }

  /**
   * formats the number to a localised number
   *
   * @param {number} amount
   * @returns {string} formattedNumber eg(9000.00)
   */
  static formatNumericOnly(amount) {
    let formattedArray = formatter.formatToParts(amount);
    let formattedInteger = "";
    formattedArray.forEach((item) => {
      if (item.type !== "currency" && item.type !== "literal") {
        formattedInteger += item.value;
      }
    });
    return formattedInteger;
  }
}
