import React from "react";
import Shimmers from "react-shimmer-effect";

import "./index.component.scss";

const OrderSuccessShimmer = () => {
  return (
    <div className="order-success-wrapper-shimmer">
      <div className="container">
        <div className="order-success-container-shimmer">
          <div className="order-success-section-shimmer">
            <Shimmers>
              <div className="order-success-image-shimmer"></div>
            </Shimmers>
            <Shimmers>
              <div className="order-success-text-one"></div>
            </Shimmers>
            <div className="order-success-group-texts">
              <Shimmers>
                <div className="order-success-text-two"></div>
              </Shimmers>
              <Shimmers>
                <div className="order-success-text-three"></div>
              </Shimmers>

              <Shimmers>
                <div className="order-success-text-four"></div>
              </Shimmers>
            </div>
          </div>
          <div className="order-success-summary-shimmer">
            <div className="order-success-summary-one-shimmer">
              <div className="order-success-group-one">
                <div className="order-success-first">
                  <Shimmers>
                    <div className="order-success-text-one"></div>
                  </Shimmers>
                  <Shimmers>
                    <div className="order-success-text-one"></div>
                  </Shimmers>
                </div>
                <div className="order-success-second">
                  <Shimmers>
                    <div className="order-success-text-two"></div>
                  </Shimmers>
                  <Shimmers>
                    <div className="order-success-text-two"></div>
                  </Shimmers>
                </div>
              </div>
            </div>
            <div className="order-success-summary-two-shimmer">
              <div className="order-success-summary-two-group">
                <Shimmers>
                  <div className="order-success-text-one"></div>
                </Shimmers>
                <Shimmers>
                  <div className="order-success-text-one"></div>
                </Shimmers>
              </div>
              <div className="order-success-summary-three-group">
                <Shimmers>
                  <div className="order-success-text-two"></div>
                </Shimmers>
                <Shimmers>
                  <div className="order-success-text-two"></div>
                </Shimmers>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessShimmer;
