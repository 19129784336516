import React from "react";

export default function OfficeIcon({ fill, size, className }) {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 16 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g
        id="⚙️Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons/24px/Restaurant"
          transform="translate(-4.000000, -1.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <g id="building" transform="translate(4.000000, 1.000000)">
            <path
              d="M5.184,22.0285217 L5.184,17.9387826 L11.28,17.9387826 L11.28,22.0285217 L15.874087,22.0285217 L15.874087,0 L0.589217391,0 L0.589217391,22.0285217 L5.184,22.0285217 Z M11.28,2.02365217 L13.8511304,2.02365217 L13.8511304,4.59478261 L11.28,4.59478261 L11.28,2.02365217 Z M11.28,5.7613913 L13.8511304,5.7613913 L13.8511304,8.33321739 L11.28,8.33321739 L11.28,5.7613913 Z M11.28,9.49982609 L13.8511304,9.49982609 L13.8511304,12.0702609 L11.28,12.0702609 L11.28,9.49982609 Z M11.28,13.2368696 L13.8511304,13.2368696 L13.8511304,15.8093913 L11.28,15.8093913 L11.28,13.2368696 Z M6.94678261,2.02365217 L9.5186087,2.02365217 L9.5186087,4.59478261 L6.94678261,4.59478261 L6.94678261,2.02365217 Z M6.94678261,5.7613913 L9.5186087,5.7613913 L9.5186087,8.33321739 L6.94678261,8.33321739 L6.94678261,5.7613913 Z M6.94678261,9.49982609 L9.5186087,9.49982609 L9.5186087,12.0702609 L6.94678261,12.0702609 L6.94678261,9.49982609 Z M6.94678261,13.2368696 L9.5186087,13.2368696 L9.5186087,15.8093913 L6.94678261,15.8093913 L6.94678261,13.2368696 Z M2.61356522,2.02365217 L5.18469565,2.02365217 L5.18469565,4.59478261 L2.61356522,4.59478261 L2.61356522,2.02365217 Z M2.61356522,5.7613913 L5.18469565,5.7613913 L5.18469565,8.33321739 L2.61356522,8.33321739 L2.61356522,5.7613913 Z M2.61356522,9.49982609 L5.18469565,9.49982609 L5.18469565,12.0702609 L2.61356522,12.0702609 L2.61356522,9.49982609 Z M2.61356522,13.2368696 L5.18469565,13.2368696 L5.18469565,15.8093913 L2.61356522,15.8093913 L2.61356522,13.2368696 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
