import React from "react";
import { COLOR_DARK_GREY } from "../../constants/colors.constants";

export default function MoneyIcon({ fill = COLOR_DARK_GREY, size, className }) {
  return (
    <svg
      width="48"
      height="34"
      viewBox="0 0 48 34"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.75 18.0625C26.3333 18.0625 25.1458 17.5833 24.1875 16.625C23.2292 15.6667 22.75 14.4792 22.75 13.0625C22.75 11.6458 23.2292 10.4583 24.1875 9.5C25.1458 8.54167 26.3333 8.0625 27.75 8.0625C29.1667 8.0625 30.3542 8.54167 31.3125 9.5C32.2708 10.4583 32.75 11.6458 32.75 13.0625C32.75 14.4792 32.2708 15.6667 31.3125 16.625C30.3542 17.5833 29.1667 18.0625 27.75 18.0625ZM12.25 26.0625C11.1667 26.0625 10.2292 25.6667 9.4375 24.875C8.64583 24.0833 8.25 23.125 8.25 22V4.125C8.25 3 8.64583 2.04167 9.4375 1.25C10.2292 0.458333 11.1667 0.0625 12.25 0.0625H43.25C44.3333 0.0625 45.2708 0.458333 46.0625 1.25C46.8542 2.04167 47.25 3 47.25 4.125V22C47.25 23.125 46.8542 24.0833 46.0625 24.875C45.2708 25.6667 44.3333 26.0625 43.25 26.0625H12.25ZM14.75 23.5625H40.75C40.75 22.4375 41.1458 21.4792 41.9375 20.6875C42.7292 19.8958 43.6667 19.5 44.75 19.5V6.625C43.625 6.625 42.6775 6.22917 41.9075 5.4375C41.1358 4.64583 40.75 3.6875 40.75 2.5625H14.75C14.75 3.6875 14.3542 4.64583 13.5625 5.4375C12.7708 6.22917 11.8333 6.625 10.75 6.625V19.5C11.875 19.5 12.8233 19.8958 13.595 20.6875C14.365 21.4792 14.75 22.4375 14.75 23.5625ZM39.875 33.5625H4.75C3.66667 33.5625 2.72917 33.1667 1.9375 32.375C1.14583 31.5833 0.75 30.625 0.75 29.5V7.5H3.25V29.5C3.25 29.875 3.40667 30.2292 3.72 30.5625C4.03167 30.8958 4.375 31.0625 4.75 31.0625H39.875V33.5625ZM12.25 23.5625H10.75V2.5625H12.25C11.8333 2.5625 11.4792 2.71833 11.1875 3.03C10.8958 3.34333 10.75 3.70833 10.75 4.125V22C10.75 22.4167 10.8958 22.7817 11.1875 23.095C11.4792 23.4067 11.8333 23.5625 12.25 23.5625Z"
        fill={fill}
      />
    </svg>
  );
}
