import React from "react";

import TextField from "@material-ui/core/TextField";
import classNames from "classnames";

import "./index.component.scss";

export default function InputField({
  type,
  label,
  className,
  inputIcon,
  placeholder,
  primaryTextColor,
  inputProps,
  refForm,
  name,
  errors,
  helperText,
  disabled,
  iconBackgroundColor,
  onChange = () => {},
}) {
  return (
    <div
      className={classNames([
        `input-field-wrapper ${className || ""}`,
        {
          disabled: disabled,
        },
      ])}
    >
      {inputIcon && (
        <div
          className="field-icon-wrapper"
          style={{ backgroundColor: iconBackgroundColor }}
        >
          {inputIcon}
        </div>
      )}

      {/**
       * Since this is uncontrolled component, initially the default value will be undefined and it renders
       * empty textfield even if we assign it some default value.
       *
       * Need key as defaultValue here to make sure the TextField rerenders when default value
       * changes from undefined to a particular value.
       */}
      <TextField
        fullWidth
        name={name}
        key={inputProps?.defaultValue}
        type={type}
        inputRef={refForm}
        label={label}
        error={errors && errors[name]}
        style={{ margin: 8, color: primaryTextColor }}
        classes={{ root: "input-field" }}
        placeholder={placeholder}
        helperText={errors && errors[name] ? helperText : null}
        margin="normal"
        onChange={onChange}
        InputProps={inputProps}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}
