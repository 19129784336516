export const SET_SUB_LOCALITY = "SET_SUB_LOCALITY";
export const CLEAR_SUB_LOCALITY = "CLEAR_SUB_LOCALITY";

export const SET_LOCATION_DIALOG = "SET_LOCATION_DIALOG";
export const UNSET_LOCATION_DIALOG = "UNSET_LOCATION_DIALOG";

export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
export const CLEAR_SELECTED_ADDRESS = "CLEAR_SELECTED_ADDRESS";

export const SET_DASH_DELIVERY = "SET_DASH_DELIVERY";
export const CLEAR_DASH_DELIVERY = "CLEAR_DASH_DELIVERY";

export const SET_SPECIAL_INSTRUCTION = "SET_SPECIAL_INSTRUCTION";
export const CLEAR_SPECIAL_INSTRUCTION = "CLEAR_SPECIAL_INSTRUCTION";

export const SHOW_UPDATE_CART_ON_LOGIN = "SHOW_UPDATE_CART_ON_LOGIN";
export const HIDE_UPDATE_CART_ON_LOGIN = "HIDE_UPDATE_CART_ON_LOGIN";

export const SET_SHOW_DELIVER_NOW = "SET_SHOW_DELIVER_NOW";

export const setSubLocality = (data) => ({
  type: SET_SUB_LOCALITY,
  data: data,
});
export const clearSubLocality = (data) => ({
  type: CLEAR_SUB_LOCALITY,
  data: data,
});

export const setLocationDialog = () => ({
  type: SET_LOCATION_DIALOG,
});
export const unSetLocationDialog = () => ({
  type: UNSET_LOCATION_DIALOG,
});

export const setSelectedAddress = (data) => ({
  type: SET_SELECTED_ADDRESS,
  data: data,
});
export const clearSelectedAddress = () => ({
  type: CLEAR_SELECTED_ADDRESS,
});

export const setDashDelivery = (data) => ({
  type: SET_DASH_DELIVERY,
  data: data,
});
export const clearDashDelivery = () => ({
  type: CLEAR_DASH_DELIVERY,
});

export const setSpecialInstruction = (data) => ({
  type: SET_SPECIAL_INSTRUCTION,
  data: data,
});

export const clearSpecialInstruction = () => ({
  type: CLEAR_SPECIAL_INSTRUCTION,
});

export const showUpdateCartOnLogin = () => ({
  type: SHOW_UPDATE_CART_ON_LOGIN,
});

export const hideUpdateCartOnLogin = () => ({
  type: HIDE_UPDATE_CART_ON_LOGIN,
});

export const setShowDeliverNow = (data) => {
  return {
    type: SET_SHOW_DELIVER_NOW,
    data: data,
  };
};
