import React from "react";
export default function ArrowIcon({ fill, rotate, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      style={rotate ? { transform: `rotate(${rotate})` } : {}}
    >
      <defs>
        <path
          id="241xsjyica"
          d="M11.92 7.354L8.458 3.625 9.966 2 16 8.5 9.966 15 8.458 13.375 11.913 9.652 0 9.652 0 7.354z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1180 -722) translate(928 150) translate(32 488) translate(220 84)">
                  <path d="M0 0H16V16H0z" />
                  <mask id="6tz2s9wiyb" fill="#fff">
                    <use xlinkHref="#241xsjyica" />
                  </mask>
                  <use fill="#222" fillRule="nonzero" xlinkHref="#241xsjyica" />
                  <path fill={fill} d="M0 0H16V16H0z" mask="url(#6tz2s9wiyb)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
