import upsdkService from "../../services/upsdkService";

export const FETCH_COUPONS_PENDING = "FETCH_COUPONS_PENDING";
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_ERROR = "FETCH_COUPONS_ERROR";

function fetchCouponsPending() {
  return {
    type: FETCH_COUPONS_PENDING,
  };
}

function fetchCouponsSuccess(data) {
  return {
    type: FETCH_COUPONS_SUCCESS,
    payload: data,
  };
}

function fetchCouponsError(error) {
  return {
    type: FETCH_COUPONS_ERROR,
    error: error,
  };
}

export default function fetchCoupons() {
  return (dispatch) => {
    dispatch(fetchCouponsPending());
    upsdkService
      .getCoupons()
      .then((response) => {
        dispatch(fetchCouponsSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchCouponsError(error));
      });
  };
}
