import LinearProgress from "@material-ui/core/LinearProgress";

const CustomLinearProgress = (props) => {
  const { classes } = props;
  return (
    <LinearProgress
      {...props}
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
    />
  );
};

export default CustomLinearProgress;
