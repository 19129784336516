import React from "react";
export default function CrossIcon({ fill, size, className, onClickCallback }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size.width}
      height={size.height}
      viewBox="0 0 16 16"
      className={className}
      onClick={onClickCallback}
    >
      <defs>
        <path
          id="wmgx28fjea"
          d="M6.222 8L0 1.778 1.778 0 8 6.222 14.222 0 16 1.778 9.778 8 16 14.222 14.222 16 8 9.778 1.778 16 0 14.222z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-992 -288) translate(400 248) translate(32 32) translate(560 8)">
                <path d="M0 0H16V16H0z" />
                <mask id="q3vk5v9feb" fill="#fff">
                  <use xlinkHref="#wmgx28fjea" />
                </mask>
                <use fill={fill} fillRule="nonzero" xlinkHref="#wmgx28fjea" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
