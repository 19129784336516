import React, { useRef } from "react";
import Shimmer from "react-shimmer-effect";

import useSize from "@react-hook/size";

import CarouselItem from "../../../common/carousel-item/index.component";
import Carousel from "../../../common/carousel/index.component";
import { ITEM_DUMMY_DATA } from "../../../mock-data/index.mock-data";

import "./index.component.scss";

const ItemDetailsShimmer = (props) => {
  const componentRef = useRef();
  const [width, height] = useSize(componentRef);
  const { integrated } = props;

  return (
    <div
      className={`item-details-wrapper-shimmer ${
        integrated ? "" : "route-shimmer"
      }`}
      ref={componentRef}
    >
      <div className="container">
        <div className="item-details-container-shimmer">
          <div className="item-content-placeholder-shimmer">
            <div className="image-wrapper-shimmer">
              <Shimmer>
                <div className="item-img-placeholder-shimmer"></div>
              </Shimmer>
            </div>
            <div className="item-info-placeholder-shimmer">
              <div className="item-info-description-wrapper">
                <Shimmer>
                  <div
                    className="description-placeholder-shimmer-one"
                    style={{ width: "40%" }}
                  />
                </Shimmer>
                <div className="description-price-wrapper">
                  <Shimmer>
                    <div
                      className="description-placeholder-shimmer-one"
                      style={{ width: "20%", marginBottom: "30px" }}
                    />
                  </Shimmer>

                  <Shimmer>
                    <div
                      className="description-placeholder-shimmer-two"
                      style={{ width: "20%", marginBottom: "30px" }}
                    />
                  </Shimmer>
                </div>
                <Shimmer>
                  <div
                    className="description-placeholder-shimmer-one"
                    style={{
                      width: "70%",
                      height: "25px",
                      marginBottom: "30px",
                    }}
                  />
                </Shimmer>
                <Shimmer>
                  {[1, 2, 3].map(() => (
                    <div
                      className="description-placeholder-shimmer-two"
                      style={{ width: "100%" }}
                    />
                  ))}
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="recommendation-placeholder-carousel-shimmer">
            <Carousel items={ITEM_DUMMY_DATA} viewPorts={{ width, height }}>
              {ITEM_DUMMY_DATA.map((item) => (
                <CarouselItem {...props} />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetailsShimmer;
