import React from "react";

export default function PickupStoreIcon({ fill, size, className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.888889 1H15.1111V2.75H0.888889V1ZM16 9.75V8L15.1111 3.625H0.888889L0 8V9.75H0.888889V15H9.77778V9.75H13.3333V15H15.1111V9.75H16ZM8 13.25H2.66667V9.75H8V13.25Z"
        fill="#666666"
      />
      <mask
        id="mask0_4452_2383"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="16"
        height="14"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.888889 1H15.1111V2.75H0.888889V1ZM16 9.75V8L15.1111 3.625H0.888889L0 8V9.75H0.888889V15H9.77778V9.75H13.3333V15H15.1111V9.75H16ZM8 13.25H2.66667V9.75H8V13.25Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4452_2383)">
        <rect width="16" height="16" fill="#666666" />
      </g>
    </svg>
  );
}
