import React from "react";
export default function CalenderIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            d="M0 0H16V16H0z"
                            transform="translate(-88 -593) translate(64 468) translate(0 64) translate(24 24) translate(0 36) translate(0 1)"
                          />
                          <g
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.6"
                            transform="translate(-88 -593) translate(64 468) translate(0 64) translate(24 24) translate(0 36) translate(0 1) translate(1)"
                          >
                            <rect width="14" height="14" y="2" rx="1" />
                            <path d="M10 0L10 3M4 0L4 3M0 6L14 6" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
