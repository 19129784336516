import React from "react";

export default function PinContainedIcon({
  fill,
  className,
  onMouseEnterCallback,
  onMouseLeaveCallback,
  size = { height: "26", width: "30" },
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 26 30"
      className={className}
      onMouseEnter={onMouseEnterCallback}
      onMouseLeave={onMouseLeaveCallback}
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <path
              fill={fill}
              d="M22.146 5.216C19.856 1.901 16.226 0 12.188 0S4.52 1.901 2.23 5.216C-.049 8.514-.575 12.69.82 16.378c.373 1.007.966 1.986 1.758 2.903l8.811 10.35c.2.234.492.369.799.369.307 0 .6-.135.798-.37l8.81-10.346c.795-.922 1.387-1.9 1.757-2.9 1.398-3.695.872-7.87-1.407-11.168z"
              transform="translate(-793 -875) translate(793 875)"
            />
            <path
              fill="#F7F7F7"
              d="M12.188 6.224c-3.239 0-5.874 2.635-5.874 5.874 0 3.24 2.635 5.874 5.874 5.874s5.874-2.635 5.874-5.874-2.635-5.874-5.874-5.874z"
              transform="translate(-793 -875) translate(793 875)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
