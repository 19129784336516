import React from "react";
import Shimmer from "react-shimmer-effect";

import ItemCard from "../../../common/item-card/index.component";
import { ITEM_MINIMAL_DATA } from "../../../mock-data/index.mock-data";

import "./index.component.scss";

function FouvritesShimmer() {
  return (
    <div className="favourites-wrapper-shimmer">
      <div className="container favourites-container-shimmer">
        <Shimmer>
          <div className="favourites-header-shimmer" />
        </Shimmer>
        <div className="favourites-card-shimmer">
          {ITEM_MINIMAL_DATA.map((item) => (
            <div key={item} className="favourites-card-solo-shimmer">
              <ItemCard style={{ minWidth: 254 }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FouvritesShimmer;
