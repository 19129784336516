import React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import classNames from "classnames";
import NonVegDietIndicatorIcon from "../../assets/icons/non-veg-indicator.icon";
import VegDietIndicatorIcon from "../../assets/icons/veg-indicator.icon";
import EggIndicatorIcon from "../../assets/icons/egg-indicator.icon";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function DietIndicator({
  dietType,
  showDietName,
  size,
  className,
}) {
  // Callbacks
  const { t } = useTranslation();

  if (dietType === "veg") {
    return (
      <div className={classNames("diet-indicator-wrapper", className)}>
        <VegDietIndicatorIcon
          fill="green"
          size={size}
          className="diet-indicator"
        />
        {showDietName && (
          <Typography
            variant="h4"
            weight="regular"
            className="diet-name"
            fontColor={"#028103"}
          >
            {t("common.veg")}
          </Typography>
        )}
      </div>
    );
  }

  if (dietType === "non-veg") {
    return (
      <div className={classNames("diet-indicator-wrapper", className)}>
        <NonVegDietIndicatorIcon
          fill="red"
          size={size}
          className="diet-indicator"
        />
        {showDietName && (
          <Typography
            variant="h4"
            weight="regular"
            className="diet-name"
            fontColor={"#954A29"}
          >
            {t("common.nonveg")}
          </Typography>
        )}
      </div>
    );
  }
  if (dietType === "egg") {
    return (
      <div className={classNames("diet-indicator-wrapper", className)}>
        <EggIndicatorIcon
          fill="yellow"
          size={size}
          className="diet-indicator"
        />
        {showDietName && (
          <Typography
            variant="h4"
            weight="regular"
            className="diet-name"
            fontColor={"#F3D55B"}
          >
            {t("common.egg")}
          </Typography>
        )}
      </div>
    );
  }

  return null;
}

DietIndicator.propTypes = {
  dietType: PropTypes.any.isRequired,
};
