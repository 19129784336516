import React from "react";

export default function AddressHomeIcon({
  fill,
  outlineColor = "#1C1B1F",
  className,
}) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill={fill} />
      <mask
        id="mask0_2382_27153"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="24"
        height="24"
      >
        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2382_27153)">
        <path
          d="M8.99927 23.9977V13.4977L15.9993 8.22266L22.9993 13.4977V23.9977H17.8493V17.5727H14.1493V23.9977H8.99927ZM9.99927 22.9977H13.1493V16.5727H18.8493V22.9977H21.9993V13.9977L15.9993 9.49766L9.99927 13.9977V22.9977Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
}
