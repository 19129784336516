import React from "react";
export default function ClockIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <defs>
        <path
          id="1927qva0fa"
          d="M12 21.6c5.302 0 9.6-4.298 9.6-9.6S17.302 2.4 12 2.4 2.4 6.698 2.4 12s4.298 9.6 9.6 9.6zM12 0c3.183 0 6.235 1.264 8.485 3.515C22.735 5.765 24 8.817 24 12c0 6.627-5.373 12-12 12-6.636 0-12-5.4-12-12C0 5.373 5.373 0 12 0zm.6 6v6.3l5.4 3.204-.9 1.476-6.3-3.78V6h1.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g transform="translate(-88 -326) translate(64 182) translate(0 64) translate(24 64) translate(0 16)">
                      <path d="M0 0H24V24H0z" />
                      <mask id="vfq2vafu2b" fill="#fff">
                        <use xlinkHref="#1927qva0fa" />
                      </mask>
                      <use
                        fill="#000"
                        fillRule="nonzero"
                        xlinkHref="#1927qva0fa"
                      />
                      <path
                        fill="#000"
                        d="M0 0H24V24H0z"
                        mask="url(#vfq2vafu2b)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
