import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";

import "./index.component.scss";

export default function SelectField({
  onChange,
  toggleTimeSlotDropdownCallback,
  label,
  className,
  inputIcon,
  defaultValue,
  disabled,
  options,
  iconBackgroundColor,
  open,
}) {
  const handleChange = (obj) => {
    onChange(obj);
  };

  const handleOpen = () => {
    toggleTimeSlotDropdownCallback();
  };

  return (
    <div
      className={classNames([
        `select-field-wrapper ${className || ""}`,
        {
          disabled: disabled,
        },
      ])}
    >
      {inputIcon && (
        <div
          className="field-icon-wrapper"
          style={{ backgroundColor: iconBackgroundColor }}
        >
          {inputIcon}
        </div>
      )}
      <div className="select-field">
        <InputLabel>{label}</InputLabel>
        <Select
          open={open}
          label={label}
          value={defaultValue || ""}
          onOpen={handleOpen}
          onClose={handleOpen}
          onChange={handleChange}
          // MenuProps={{ disableScrollLock: true }}
        >
          {options && options.length
            ? options.map((option) => (
                <MenuItem key={option.value} value={option.value || ""}>
                  {option.title}
                </MenuItem>
              ))
            : null}
        </Select>
      </div>
    </div>
  );
}
