import { SET_CONFIG } from "../actions/config";

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SET_CONFIG: {
      return action.data;
    }
    default:
      return state;
  }
};
