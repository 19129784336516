import React from "react";

import "./index.component.scss";

export default function Loader({ loaderColor, size }) {
  const loaderStyles = {
    border: `2px solid ${loaderColor}`,
    borderColor: `${loaderColor} transparent transparent transparent`,
  };

  if (size) {
    loaderStyles.height = size;
    loaderStyles.width = size;
  }

  return (
    <div className="app-loader">
      <div style={loaderStyles} />
    </div>
  );
}
