import {
  CLEAR_SEARCH,
  FETCH_SEARCH_ERROR,
  FETCH_SEARCH_PENDING,
  FETCH_SEARCH_SUCCESS,
} from "../actions/search";

const initialState = {
  pending: false,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SEARCH_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
      };
    case FETCH_SEARCH_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case CLEAR_SEARCH:
      return {
        pending: false,
        data: null,
        error: null,
      };
    default:
      return state;
  }
}
