import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";

dayjs.extend(customParseFormat);

const defaultTimeSlot = {
  day: null,
  startTime: null,
  endTime: null,
  displayTitle: null,
  rawData: null,
};

export default class TimeSlot {
  constructor(timeSlot = defaultTimeSlot) {
    this.day = timeSlot.day;
    this.startTime = dayjs(timeSlot.start_time, "hh:mm:ss");
    this.endTime = dayjs(timeSlot.end_time, "hh:mm:ss");
    this.start_time = timeSlot.start_time;
    this.end_time = timeSlot.end_time;
    this.displayTitle =
      dayjs(this.startTime).format("hh:mm A") +
      " - " +
      dayjs(this.endTime).format("hh:mm A");
    this.rawData = timeSlot;
  }
}
