import graphqlService from "../../services/graphql/graphqlService";

export const FETCH_CATALOGUE_PENDING = "FETCH_CATALOGUE_PENDING";
export const FETCH_CATALOGUE_SUCCESS = "FETCH_CATALOGUE_SUCCESS";
export const FETCH_CATALOGUE_ERROR = "FETCH_CATALOGUE_ERROR";

export const UPDATE_CATALOGUE_PENDING = "UPDATE_CATALOGUE_PENDING";
export const UPDATE_CATALOGUE = "UPDATE_CATALOGUE";

function fetchCataloguePending() {
  return {
    type: FETCH_CATALOGUE_PENDING,
  };
}

function fetchCatalogueSuccess(catalogue) {
  return {
    type: FETCH_CATALOGUE_SUCCESS,
    payload: catalogue,
  };
}

function fetchCatalogueError(error) {
  return {
    type: FETCH_CATALOGUE_ERROR,
    error: error,
  };
}

export default function fetchCatalogue(sortBy, filters = [], fulfilmentType) {
  return (dispatch) => {
    dispatch(fetchCataloguePending());
    graphqlService
      .getCatalogue(sortBy, filters, fulfilmentType)
      .then((response) => {
        dispatch(fetchCatalogueSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchCatalogueError(error));
      });
  };
}

function updateCataloguePending() {
  return {
    type: UPDATE_CATALOGUE_PENDING,
  };
}

function updateCatalogueSuccess(payload) {
  return {
    type: UPDATE_CATALOGUE,
    payload: payload,
  };
}

export const updateCatalogue =
  (category_id, sort_by = null, filter_by = [], fulfilmentMode = "delivery") =>
  (dispatch) => {
    dispatch(updateCataloguePending());
    return graphqlService
      .getCategoryById(category_id, sort_by, filter_by, fulfilmentMode)
      .then((items) => {
        dispatch(updateCatalogueSuccess({ category_id, items }));
      })
      .catch((error) => {
        console.error(error);
      });
  };
