import TagManager from "react-gtm-module";
import analyticsPubSub, { ANALYTICS_EVENTS } from "./analyticsPubSub";

const GTM_GA_EVENTS = [
  ANALYTICS_EVENTS.APP_LAUNCHED,
  ANALYTICS_EVENTS.DELIVERY_STORE_SELECTED,
  ANALYTICS_EVENTS.NO_STORES_NEARBY,
  ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TEMP,
  ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TODAY,
  ANALYTICS_EVENTS.ITEM_SEARCH,
  ANALYTICS_EVENTS.REORDER,
  ANALYTICS_EVENTS.COUPON_SUCCESS,
  ANALYTICS_EVENTS.COUPON_FAILED,
  ANALYTICS_EVENTS.LOGIN_SUCCESS,
  ANALYTICS_EVENTS.LOGIN_FAILED,
  ANALYTICS_EVENTS.PASSWORD_RESET,
  ANALYTICS_EVENTS.RESEND_OTP,
  ANALYTICS_EVENTS.SIGNUP_START,
  ANALYTICS_EVENTS.SIGNUP_COMPLETE,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_START,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_COMPLETE,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_SUCCESS,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_FAILED,
  ANALYTICS_EVENTS.WALLET_RELOAD_INIT,
  ANALYTICS_EVENTS.SUCCESSFUL_WALLET_RELOAD,
  ANALYTICS_EVENTS.FAILED_WALLET_RELOAD,
  ANALYTICS_EVENTS.REFERRAL_SENT,
  ANALYTICS_EVENTS.LOGOUT,
  ANALYTICS_EVENTS.PRODUCT_CLICKED,
  ANALYTICS_EVENTS.CART_INIT,
  ANALYTICS_EVENTS.ADD_TO_CART,
  ANALYTICS_EVENTS.REMOVE_FROM_CART,
  ANALYTICS_EVENTS.VIEW_CART,
  ANALYTICS_EVENTS.ORDER_PLACED,
  ANALYTICS_EVENTS.ITEM_LIKED,
  ANALYTICS_EVENTS.ITEM_UNLIKED,
  ANALYTICS_EVENTS.FEEDBACK_SUBMITTED,
  ANALYTICS_EVENTS.CHECKOUT_INIT,
  ANALYTICS_EVENTS.PROFILE_UPDATED,
  ANALYTICS_EVENTS.BANNER_CLICKED,
  ANALYTICS_EVENTS.ADDRESS_SELECTED,

  ANALYTICS_EVENTS.PICKUP_STORE_SELECTED,
  ANALYTICS_EVENTS.MULTIPLE_DELIVERY_STORES,
  ANALYTICS_EVENTS.FILTER_APPLIED,
  ANALYTICS_EVENTS.SORT_APPLIED,
  ANALYTICS_EVENTS.ADD_MORE_ITEMS,
  ANALYTICS_EVENTS.CATEGORY_DETAIL,
];

window.dataLayer = window.dataLayer || [];
let currency = "INR";

function setCurrency(currencyCode) {
  currency = currencyCode;
}

function subscribe() {
  GTM_GA_EVENTS.forEach((event) => {
    analyticsPubSub.subscribe(event, (response) => {
      handleEvent(event, response);
    });
  });
}

function handleEvent(type, data) {
  let eventName = "";
  let eventData = {};

  switch (type) {
    case ANALYTICS_EVENTS.APP_LAUNCHED:
      eventName = "app-launched";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.DELIVERY_STORE_SELECTED:
      eventName = "delivery-store-selected";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.NO_STORES_NEARBY:
      eventName = "no-stores-nearby";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TEMP:
      eventName = "nearest-store-closed-temp";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TODAY:
      eventName = "nearest-store-closed";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.ITEM_SEARCH:
      eventName = "item-search";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.REORDER:
      eventName = "re-order";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
        eventValue: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.COUPON_SUCCESS:
      eventData = {
        event_name: "GAEvent",
        eventCategory: "coupon",
        eventLabel: data.coupon_code,
        eventAction: "applied",
      };
      break;
    case ANALYTICS_EVENTS.COUPON_FAILED:
      eventData = {
        event_name: "GAEvent",
        eventCategory: "coupon",
        eventLabel: data.coupon_code,
        eventAction: "failed",
      };

      break;
    case ANALYTICS_EVENTS.LOGIN_SUCCESS:
      eventName = "login-success";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.LOGIN_FAILED:
      eventName = "login-failed";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.PASSWORD_RESET:
      eventName = "password-reset";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.RESEND_OTP:
      eventName = "resend-otp";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.SIGNUP_START:
      eventName = "signup-start";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.SIGNUP_COMPLETE:
      eventName = "signup-complete";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_START:
      eventName = "social-signup-start";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_COMPLETE:
      eventName = "social-signup-complete";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_SUCCESS:
      eventName = "social-login-success";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
        eventValue: data.social_auth,
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_FAILED:
      eventName = "social-login-failed";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.WALLET_RELOAD_INIT:
      eventName = "wallet-reload-init";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
        eventValue: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.SUCCESSFUL_WALLET_RELOAD:
      eventName = "wallet-reload-complete";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
        eventValue: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.FAILED_WALLET_RELOAD:
      eventName = "wallet-reload-failed";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
        eventValue: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.REFERRAL_SENT:
      eventName = "referral-sent";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.LOGOUT:
      eventName = "logout";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.PRODUCT_CLICKED:
      eventName = "ppr-item-view-details";
      eventData = {
        event_name: "view_item",
        eventLabel: eventName,
        currency: currency,
        items: [
          {
            name: data.item_title,
            id: data.item_id,
            price: data.item_price,
            category: data.item_category,
            image_portrait: data.thumbnail,
          },
        ],
      };
      break;
    case ANALYTICS_EVENTS.CART_INIT:
    case ANALYTICS_EVENTS.ADD_TO_CART:
      eventName = "add-to-cart";
      eventData = {
        event_name: "add_to_cart",
        eventLabel: eventName,
        currency: currency,
        items: [
          {
            name: data.item_title,
            id: data.id,
            price: data.item_price,
            category: data.item_category_title,
            quantity: data.qty,
          },
        ],
      };
      break;
    case ANALYTICS_EVENTS.REMOVE_FROM_CART:
      eventData = {
        eventLabel: eventName,
        event_name: "remove_from_cart",
        currency: currency,
        items: [
          {
            name: data.item_title,
            id: data.id,
            price: data.item_price,
            category: data.item_category_title,
            quantity: data.qty,
          },
        ],
      };
      break;
    case ANALYTICS_EVENTS.VIEW_CART:
      eventName = "ppr-view-cart";
      eventData = {
        eventLabel: eventName,
        event_name: "view_cart",
        currency: currency,
        value: data.payable_amount,
        items: data.cart_items,
      };
      break;
    case ANALYTICS_EVENTS.ORDER_PLACED:
      eventName = "ppr-order-placed";
      eventData = {
        eventLabel: eventName,
        event_name: "purchase",
        actionField: data.order_meta,
        items: data.items,
      };
      break;
    case ANALYTICS_EVENTS.ITEM_LIKED:
      eventName = "item-liked";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
        like: data?.like,
      };
      break;
    case ANALYTICS_EVENTS.ITEM_UNLIKED:
      eventName = "item-unliked";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.FEEDBACK_SUBMITTED:
      eventName = "feedback-submitted";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.CHECKOUT_INIT:
      eventName = "checkout-init";
      eventData = {
        event_name: "begin_checkout",
        eventLabel: eventName,
        actionField: data.order_meta,
        items: data.items,
      };
      break;
    case ANALYTICS_EVENTS.PROFILE_UPDATED:
      eventName = "profile-updated";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.BANNER_CLICKED:
      eventName = "banner-clicked";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.ADDRESS_SELECTED:
      eventName = "addr-selected";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "user",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.ADD_MORE_ITEMS:
      eventName = "add-more-items";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.MULTIPLE_DELIVERY_STORES:
      eventName = "multiple-delivery-stores";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.PICKUP_STORE_SELECTED:
      eventName = "pickup-store-selected";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
      };
      break;
    case ANALYTICS_EVENTS.FILTER_APPLIED:
      eventName = "filter-applied";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
        eventValue: data.filters,
      };
      break;
    case ANALYTICS_EVENTS.SORT_APPLIED:
      eventName = "sort-applied";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
        eventValue: data.sort,
      };
      break;

    case ANALYTICS_EVENTS.CATEGORY_DETAIL:
      eventName = "ppr-category-detail";
      eventData = {
        event_name: "GAEvent",
        eventCategory: "ordering",
        eventLabel: eventName,
        eventValue: data,
      };
      break;
  }
  if (eventData) {
    trackEvent(eventData);
  }
}

function trackEvent(eventData) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventData.event_name,
      ...eventData,
    });
  }
}

function init({ gtmId }) {
  TagManager.initialize({ gtmId });
  subscribe();
}

const GTMga4Service = {
  init,
  setCurrency,
};

export default GTMga4Service;
