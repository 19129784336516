import React from "react";
import Shimmer from "react-shimmer-effect";

import ItemCard from "../../../common/item-card/index.component";
import { ITEM_CATEGORY_DUMMY_DATA } from "../../../mock-data/index.mock-data";
import "./index.component.scss";

function ExploreShimmer() {
  return (
    <div className="explore-wrapper-shimmer">
      <div className="container">
        <div className="explore-container-shimmer">
          <Shimmer>
            <div className="explore-hero-shimmer" />
          </Shimmer>
          <Shimmer>
            <div className="explore-menu-shimmer" />
          </Shimmer>
          <div className="item-wrapper-shimmer">
            <div className="item-container-shimmer">
              <Shimmer>
                <div className="item-header-shimmer" />
              </Shimmer>
              <div className="item-card-shimmer">
                {ITEM_CATEGORY_DUMMY_DATA.map((item) => (
                  <ItemCard style={{ minWidth: 254 }} key={item.id} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreShimmer;
