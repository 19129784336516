import React from "react";
import Shimmer from "react-shimmer-effect";

import { ITEM_MINIMAL_DATA } from "../../../mock-data/index.mock-data";

import "./index.component.scss";

const OrdersShimmer = (props) => {
  //variable
  const { integrated } = props;

  const shimmerWithoutWrapper = () => {
    return (
      <div className={"order-integrated"}>
        <div className="order-wrapper-shimmer">
          <div className="container order-container-shimmer">
            <Shimmer>
              <div className="order-header-shimmer" />
            </Shimmer>
            {ITEM_MINIMAL_DATA.map((order, index) => (
              <div className="order-card-shimmer" key={index}>
                <div className="order-shimmer-left-section">
                  <Shimmer>
                    <div className="order-card-sub-one-shimmer"></div>
                  </Shimmer>
                  <Shimmer>
                    <div className="order-card-sub-two-shimmer"></div>
                  </Shimmer>
                  <Shimmer>
                    <div className="order-card-sub-three-shimmer"></div>
                  </Shimmer>
                </div>
                <div className="order-shimmer-right-section">
                  <div className="order-status-shimmer-wrapper">
                    <Shimmer>
                      <div className="order-status-shimmer" />
                    </Shimmer>
                  </div>
                  <Shimmer>
                    <div className="order-price-shimmer" />
                  </Shimmer>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return !integrated ? (
    <div className="order-history-wrapper">
      <div className="container">
        <div className="orders-card">{shimmerWithoutWrapper()}</div>
      </div>
    </div>
  ) : (
    shimmerWithoutWrapper()
  );
};

export default OrdersShimmer;
