import CountryHelper from "../../../themes/frodo/src/helpers/countryCode.helper";

import loadScript from "../../helpers/loadScript";

let googleLocationService,
  googleAutocompleteService,
  geocoder,
  sessionToken,
  sessionTokenInterval,
  isMapsScriptLoaded = false;

const getValue = (address_components) => {
  let value = null;
  address_components.forEach((component) => {
    component.types &&
      component.types.forEach((type) => {
        if (type.toString() === type) {
          value = component.short_name;
        }
      });
  });
  return value;
};

function init({ apiKey, callback }) {
  // do not load script if already loaded else it will cause unwanted behavior
  if (isMapsScriptLoaded) return;
  isMapsScriptLoaded = true;

  const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
  loadScript(
    url,
    () => {
      googleLocationService = new window.google.maps.places.PlacesService(
        document.createElement("div"),
      );

      // autocomplete service
      googleAutocompleteService =
        new window.google.maps.places.AutocompleteService(
          document.createElement("div"),
        );
      geocoder = new window.google.maps.Geocoder();
      sessionToken = new window.google.maps.places.AutocompleteSessionToken();

      //sessionToken valid for 5 min
      sessionTokenInterval = setInterval(() => {
        sessionToken = null;
      }, 300000);

      if (callback) {
        callback();
      }
    },
    "GOOGLE_MAPS_STORE_LOCATOR",
  );
}

function search(query = null, countryIsdCode = "+91") {
  if (googleAutocompleteService && !sessionToken) {
    sessionToken = new window.google.maps.places.AutocompleteSessionToken();

    // sessionToken valid for 5 min
    sessionTokenInterval = setInterval(() => {
      sessionToken = null;
    }, 300000);
  }

  const countryCode = CountryHelper.getCountryCodes(countryIsdCode);
  const request = {
    input: query,
    componentRestrictions: { country: countryCode },
    sessionToken: sessionToken,
  };
  return new Promise((resolve, reject) => {
    if (!query) {
      reject("Invalid search query");
    }
    googleAutocompleteService?.getPlacePredictions(
      request,
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        } else {
          reject(status);
        }
      },
    );
  });
}

const getDetails = (placeId) => {
  return new Promise((resolve, reject) => {
    try {
      googleLocationService.getDetails({ placeId }, (place) => {
        const data = {
          name: place.name,
          place_id: place.place_id,
          formatted_address: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          address_components: place.address_components,
          postal_code: getValue(place.address_components, "postal_code"),
          country: getValue(place.address_components, "country"),
        };
        resolve(data);

        /**
         * clear sessionToken after location is selected since it is invalid
         */
        clearInterval(sessionTokenInterval);
        sessionToken = null;
      });
    } catch (e) {
      reject(e);
    }
  });
};

const getSubLocality = (lat, lng) => {
  return new Promise((resolve, reject) => {
    if (!geocoder) return reject("Geocoder is undefined");
    geocoder.geocode({ location: { lat, lng } }, (results) => {
      if (results === null) return reject("Something went wrong");
      let place = results[0];
      let formatted_address = place.formatted_address;
      if (getValue(place.address_components, "postal_code")) {
        formatted_address = formatted_address.replace(
          getValue(place.address_components, "postal_code"),
          "",
        );
      }
      const data = {
        name: place.formatted_address,
        place_id: place.place_id,
        formatted_address: formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address_components: place.address_components,
        postal_code: getValue(place.address_components, "postal_code"),
        country: getValue(place.address_components, "country"),
      };

      resolve(data);
    });
  });
};

const googlePlacesService = {
  init,
  search,
  getDetails,
  getSubLocality,
};

export default googlePlacesService;
