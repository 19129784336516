import React, { useRef } from "react";
import Shimmer from "react-shimmer-effect";

import useSize from "@react-hook/size";

import CarouselItem from "../../../common/carousel-item/index.component";
import Carousel from "../../../common/carousel/index.component";
import { ITEM_MINIMAL_DATA } from "../../../mock-data/index.mock-data";

import "./index.component.scss";

export const ItemUpdatePlaceholder = () => (
  <div className="item-update-placeholder">
    <div className="image-wrapper">
      <Shimmer>
        <div className="item-image-placeholder"></div>
      </Shimmer>
      <div className="item-update-placeholder-wrapper">
        <Shimmer>
          <div className="placeholder-1"></div>
        </Shimmer>
        <Shimmer>
          <div className="placeholder-2"></div>
        </Shimmer>
      </div>
    </div>
    <div className="counter-add-wrapper">
      <Shimmer>
        <div className="counter-placeholder"></div>
      </Shimmer>
    </div>
  </div>
);

const CartShimmer = () => {
  const componentRef = useRef();
  const [width, height] = useSize(componentRef);

  return (
    <div className="cart-wrapper-shimmer" ref={componentRef}>
      <div className="container">
        <div className="cart-container-shimmer">
          <div className="cart-left-section-shimmer">
            <div className="cart-widget-shimmer">
              <Shimmer>
                <div className="cart-header-shimmer" />
              </Shimmer>
              {ITEM_MINIMAL_DATA.map((item) => (
                <ItemUpdatePlaceholder key={item} />
              ))}
            </div>
            <div
              style={{
                background: "white",
                height: "30px",
                padding: "24px",
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Shimmer>
                <div className="cart-add-more-shimmer" />
              </Shimmer>
            </div>
            <div style={{ padding: "24px" }}>
              <Shimmer>
                <div className="cart-header-shimmer" />
              </Shimmer>
              <Carousel items={ITEM_MINIMAL_DATA} viewPorts={{ width, height }}>
                {ITEM_MINIMAL_DATA.map((item) => (
                  <CarouselItem key={item} />
                ))}
              </Carousel>
            </div>
          </div>
          <div className="cart-right-section-shimmer">
            <Shimmer>
              <div className="cart-header-shimmer" />
            </Shimmer>

            {[1, 2, 3].map((item) => (
              <div className="cart-bill-shimmer-wrapper" key={item}>
                <Shimmer>
                  <div className="cart-bill-text-one-shimmer"></div>
                </Shimmer>
                <Shimmer>
                  <div className="cart-bill-text-two-shimmer"></div>
                </Shimmer>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartShimmer;
