import React, { useRef } from "react";

import ArrowIcon from "../../assets/icons/arrow.icon";

import "./index.component.scss";

export default function Carousel(props) {
  const ref = useRef(null);

  // props
  const { items, viewPorts, children, primaryColor } = props;

  const handleScrollCarousel = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const showCarouselArrows =
    viewPorts.width < 400 && items.length >= 2
      ? true
      : viewPorts.width < 768 && items.length >= 3
      ? true
      : viewPorts.width < 1200 && items.length >= 4
      ? true
      : viewPorts.width > 1200 && items.length >= 4
      ? true
      : false;

  const CarouselArrows = () => (
    <div className="scroll-buttons-wrapper">
      <div
        className="scroll-button left-arrow-wrapper"
        onClick={() => handleScrollCarousel(-300)}
      >
        <ArrowIcon className="left-arrow" fill={primaryColor} />
      </div>
      <div
        className="scroll-button right-arrow-wrapper"
        onClick={() => handleScrollCarousel(300)}
      >
        <ArrowIcon className="right-arrow" fill={primaryColor} />
      </div>
    </div>
  );

  return (
    <div className="app-carousel">
      <div className="item-list" ref={ref}>
        {children}
      </div>
      {showCarouselArrows && <CarouselArrows />}
    </div>
  );
}
