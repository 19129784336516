export const COLOR_BLACK = "#000000";
export const COLOR_LIGHT_BLACK = "#4E4E4E";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_KOURNIKOVA = "#FACD5D";
export const COLOR_NIGHT_RIDER = "#2D2D2D";
export const COLOR_SPUN_PEARL = "#A3A3A4";
export const COLOR_ASH_GREY = "#BABABB";
export const COLOR_DARK_GREY = "#7A7A7A";
export const COLOR_GREY_TWO = "#9A9A9A";
export const COLOR_SCROLLBAR_GREY = "#7f7f7f";
export const COLOR_BACKGROUND_GREY = "#f7f7f7";
export const COLOR_SILVER_SAND = "#C6C6C6";
export const COLOR_DARK_LIVER = "#4E4E4E";
export const COLOR_RED = "#FF0000";
export const COLOR_BURNT_UMBER = "#222222";
export const COLOR_QUICK_SILVER = "#a3a3a429";
export const COLOR_SLATE_GRAY = "#8B8B8B";
export const COLOR_GUNMETAL_GRAY = "#959595";

// ratings colors
export const COLOR_RATING_ONE = "#CC2F2C";
export const COLOR_RATING_TWO = "#D04C2B";
export const COLOR_RATING_THREE = "#D6682D";
export const COLOR_RATING_FOUR = "#E29832";
export const COLOR_RATING_FIVE = "#FFAF13";
export const COLOR_RATING_SIX = "#C0CA44";
export const COLOR_RATING_SEVEN = "#97C24D";
export const COLOR_RATING_EIGHT = "#82B846";
export const COLOR_RATING_NINE = "#71AB45";
export const COLOR_RATING_TEN = "#5F963F";

export const COLOR_SUCCESS = "#009944";
export const COLOR_DANGER = "#cf000f";
export const COLOR_WARNING = "#fed591";
export const COLOR_INFORMATION = "#63c0df";
