import React, { useState } from "react";

import PGDialog from "../common/pg-dialog/index.component";

const PGDialogContext = React.createContext();

const PGDialogProvider = (props) => {
  const [_open, setOpen] = useState(false);
  const [_children, setChildren] = useState(``);
  const [_onClose, setOnCloseFunc] = useState(null);
  const [loading, _setLoading] = useState(false);

  const onClose = (_func) => {
    setOnCloseFunc(() => _func);
  };

  const open = () => {
    setOpen(true);
  };
  const getID = () => {
    return `pg-modal-helper`;
  };

  const setForm = (str) => {
    setChildren(str);
  };

  const close = (closeFunc) => {
    setOpen(false);
    if (typeof closeFunc === "function") {
      closeFunc();
    }
  };

  const setLoading = (state) => {
    _setLoading(state);
  };

  return (
    <PGDialogContext.Provider
      value={{ onClose, open, getID, setForm, close, setLoading }}
      {...props}
    >
      {props.children}
      <PGDialog
        open={_open}
        close={() => close(_onClose)}
        loading={loading}
        config={props.config}
      >
        {_children}
      </PGDialog>
    </PGDialogContext.Provider>
  );
};

const usePGDialog = () => {
  const context = React.useContext(PGDialogContext);
  if (context === undefined) {
    throw new Error("usePGDialog must be used within a PGDialogContext");
  }

  return context;
};

export { PGDialogProvider, usePGDialog };
