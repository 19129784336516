import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import debounce from "common/helpers/debounce";

import { constructSlug } from "common/helpers/constructSlug";
import configService from "common/services/configService";
import upsdkService from "common/services/upsdkService";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import pageSelector from "common/store/selectors/pageSelector";
import { showNotification } from "../../helpers/utils";
import useRouteHook from "../../hooks/useRoute.hook";

const PAGE_ID_MAP = {
  menu: {
    url: "/menu",
  },
  item_detail: {
    url: async () => {
      const catalogue = await upsdkService.getCatalogue();
      const items = catalogue?.items || [];
      if (items.length === 0) {
        return null;
      }

      // select a random item
      const item = items[Math.floor(Math.random() * items.length)];

      const slug = constructSlug(item.name, item.raw_data.slug || item.slug);
      return `/item-list/${item.id}/${slug}`;
    },
  },
  order_history: {
    auth: true,
    url: "/past-orders",
  },
  checkout: {
    cart: true,
    auth: true,
    url: "/checkout",
  },
  favourite: {
    auth: true,
    url: "/favourites",
  },
  cart: {
    cart: true,
    url: "/cart",
  },
  wallet: {
    auth: true,
    url: "/wallet",
  },
  refer_earn: {
    auth: true,
    url: "/refer-and-earn",
  },
  by_ref: {
    url: "/by_ref",
  },
  profile: {
    auth: true,
    url: "/profile",
  },
  store_locator: {
    url: "/store-locator",
  },
  coupons: {
    auth: true,
    url: "/coupons",
  },
  page_not_found: {
    url: "/404",
  },
  landing_screen: {
    url: "/",
  },

  // privacy -> need to figure out URL from config
  // terms -> need to figure out URL from config
  // order_success -> Needs to be implemented
};

export default function MerakiEditorListener() {
  const dispatch = useDispatch();
  const token = useSelector((s) => upsdkSelector.getAuthHeader(s));
  const cart = useSelector((s) => upsdkSelector.getCart(s));
  const { pathname } = useLocation();
  const { historyPush } = useRouteHook();

  const isAuthenticated = token !== null;
  const isCartEmpty = cart ? cart.items.length === 0 : true;

  useEffect(() => {
    const onConfig = debounce((config) => {
      configService.init(config);
    }, 100);

    function listener(e) {
      if (e?.data?.type === "meraki/config-updated") {
        const config = e?.data?.config;
        if (!config) {
          return;
        }

        //dynamically sets menuRoute
        const landingScreenConfig = pageSelector.getLandingPage({ config });
        const menuRoute = landingScreenConfig?.enabled ? "/menu" : "/";
        PAGE_ID_MAP.menu.url = menuRoute;

        onConfig(config);
      }

      if (e?.data?.type === "meraki/page-updated") {
        const page = e?.data?.page;
        if (!page) {
          return;
        }

        if (page.type === "theme") {
          navigateToThemeUrl(page, historyPush, {
            isAuthenticated,
            isCartEmpty,
          });
        }
      }
    }

    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, [dispatch, historyPush, isAuthenticated, isCartEmpty]);

  useEffect(() => {
    if (!window.parent) {
      return;
    }

    window.parent.postMessage(
      {
        type: "meraki/navigate",
        uri: pathname,
        pageId: getPageIdFromPathname(pathname),
      },
      "*",
    );
  }, [pathname]);

  return null;
}

/**
 * When a theme page is selected, navigate to theme page
 */
function navigateToThemeUrl(
  page,
  historyPush,
  { isAuthenticated, isCartEmpty },
) {
  if (!page || !PAGE_ID_MAP[page._id]) {
    return;
  }

  const pageType = PAGE_ID_MAP[page._id];
  if (pageType.auth && !isAuthenticated) {
    showNotification(
      `Please Signup/Login to preview the ${page.name} page`,
      "error",
    );
    return;
  }

  if (pageType.cart && isCartEmpty) {
    showNotification(
      `Please add items to cart to preview the ${page.name} page`,
      "error",
    );
    return;
  }

  let url = pageType.url;
  if (typeof url === "function") {
    url = url();
  }

  if (url && url.then) {
    // when URL is a promise
    url.then((u) => (u ? historyPush(u) : null));
  } else if (url) {
    historyPush(url);
  }
}

/**
 * Get page ID from pathname
 */
function getPageIdFromPathname(pathname) {
  if (pathname === undefined || pathname === null) {
    return null;
  }

  if (pathname === "" || pathname === "/") {
    return "landing_screen";
  }

  if (/^\/item-list/.test(pathname)) {
    return "item_detail";
  }

  for (const pageId of Object.keys(PAGE_ID_MAP)) {
    const pageUrl = PAGE_ID_MAP[pageId]?.url;
    if (pageUrl === pathname) {
      return pageId;
    }
  }

  return null;
}
