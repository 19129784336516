import React from "react";

import CardOnDeliveryIcon from "../assets/icons/card-on-delivery.icon";
import CreditCardIcon from "../assets/icons/credit-card.icon";
import DeliveryIcon from "../assets/icons/delivery.icon";
import MoneyIcon from "../assets/icons/money.icon";
import PickUpIcon from "../assets/icons/pickup.icon";
import WalletPayIcon from "../assets/icons/wallet-pay.icon";

export function getFulfilmentTypeObject(type, primaryColor, secondaryColor, t) {
  switch (type) {
    case "delivery":
      return {
        key: "delivery",
        value: t("header.delivery"),
        icon: (
          <DeliveryIcon className="fulfilment-type-icon" fill={primaryColor} />
        ),
      };
    case "pickup":
      return {
        key: "pickup",
        value: t("header.pickup"),
        icon: (
          <PickUpIcon className="fulfilment-type-icon" fill={secondaryColor} />
        ),
      };
    default:
  }
}

export function getPaymentTypeObject(type, t) {
  switch (type) {
    case "cash":
      return {
        key: type,
        value: t("common.cash"),
        icon: (fill) => <MoneyIcon fill={fill} className="payment-icon" />,
      };
    case "prepaid":
      return {
        key: type,
        value: t("wallet.wallet"),
        icon: (fill) => <WalletPayIcon fill={fill} className="payment-icon" />,
      };
    case "card_on_delivery":
      return {
        key: type,
        value: t("common.cardOnDelivery"),
        icon: (fill) => (
          <CardOnDeliveryIcon fill={fill} className="payment-icon" />
        ),
      };
    case "payment_gateway":
      return {
        key: type,
        value: t("common.paymentGateway"),
        icon: (fill) => <CreditCardIcon fill={fill} className="payment-icon" />,
      };
    case "paytm":
      return {
        key: type,
        value: t("common.payTM"),
        icon: (fill) => <WalletPayIcon fill={fill} className="payment-icon" />,
      };
    default:
      return {
        value: "Unknown",
      };
  }
}

export const limitText = (text, length, isMobileView) => {
  if (!text || !length || text.length <= length) {
    return text;
  }

  const limited = isMobileView
    ? text.slice(0, 50).trim()
    : text.slice(0, length).trim();

  return `${limited}.`;
};
