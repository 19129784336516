import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  Suspense,
  lazy,
} from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import i18next from "i18next";
import clone from "common/helpers/deepClone";
import { bindActionCreators } from "redux";

import bannerChooser from "common/helpers/bannerChooser";
import seoHelper from "common/helpers/seoHelper";
import upsdkService from "common/services/upsdkService";
import { fetchLikes, likeItem, unlikeItem } from "common/store/actions/likes";
import configSelector from "common/store/selectors/configSelector";
import itemSelector from "common/store/selectors/itemSelector";
import pageSelector from "common/store/selectors/pageSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import ImageCarousel from "../../common/image-carousel/index.component";

import ItemList from "../../common/item-list/index.component";
import { BaseContext } from "../../context/BaseContext";
import renderHelmet from "../../helpers/helmetHelper";
import { translateOptions } from "../../i18n";
import graphqlService from "common/services/graphql/graphqlService";

import "./index.component.scss";

const FloatingCart = lazy(() =>
  import("../../common/floating-cart/index.component")
);

const FloatingOrder = lazy(() =>
  import("../../common/floating-order/index.component")
);

function Explore(props) {
  const [banners, setBanners] = useState(window.BANNER_IMAGES || []);
  const BaseConsumer = useContext(BaseContext);
  const [recommendedApiData, setRecommendedApiData] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(false);
  const [bannerApiLoading, setBannerApiLoading] = useState(false);
  const [recommendedError, setRecommendedError] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);

  // props
  const {
    config,
    selectedStore,
    catalogueLoading,
    categories,
    searchResults,
    appliedOptions,
    primaryTextColor,
    catalogueError,
    fulfillmentType,
    cart,
  } = props;

  // variables
  const {
    isMobileView,
    screenWidth,
    searchActive,
    isQrModeEnabled,
    isQsrEnabled,
  } = BaseConsumer;
  const pageData = pageSelector.getMenuPage({ config });
  const seoData = seoHelper(pageData.seo);
  const location_id = selectedStore ? selectedStore.id : null;
  const primaryColor = configSelector.getPrimaryColor({ config });
  const showRecommendedItemsWidget = itemSelector.showRecommendedItemsWidget({
    config,
  });
  const activeLanguage = i18next.language;

  // callbacks
  const { t } = props;

  useEffect(() => {
    if (location_id) {
      new Promise((res, rej) => {
        setBannerApiLoading(true);
        upsdkService
          .getBanners(location_id)
          .then((response) => {
            setBanners(response.data);
            res();
          })
          .catch(() => rej());
      }).finally(() => {
        setBannerApiLoading(false);
      });
    }
  }, [activeLanguage, location_id]);

  useEffect(() => {
    if (location_id) {
      upsdkService.getOrderHistory().then((response) => {
        const selectedStatusOrders = response.filter(
          (e) =>
            ["Placed", "Acknowledged", "Food Ready"].indexOf(e.order_state) >= 0
        );
        if (selectedStatusOrders && selectedStatusOrders.length > 0) {
          const selectedStatusOrdersSorted = selectedStatusOrders.sort(
            (a, b) => b.id - a.id
          );
          const activeOrder = isQrModeEnabled
            ? selectedStatusOrdersSorted.find(
                (e) => e.biz_location_id === location_id
              )
            : selectedStatusOrdersSorted[0];
          setCurrentOrder(activeOrder);
        }
      });
    }
  }, [isQrModeEnabled, location_id]);

  const bannersForDisplay = useMemo(() => {
    return bannerChooser(banners);
  }, [banners]);

  useEffect(() => {
    if (!showRecommendedItemsWidget) return;

    setRecommendedLoading(true);
    graphqlService
      .getRecommendedItems(
        null,
        isQsrEnabled || !isQrModeEnabled ? fulfillmentType : null
      )
      .then((res) => setRecommendedApiData(res))
      .catch(() => setRecommendedError(true))
      .finally(() => setRecommendedLoading(false));
  }, [
    location_id,
    setRecommendedApiData,
    showRecommendedItemsWidget,
    activeLanguage,
    fulfillmentType,
    isQrModeEnabled,
    isQsrEnabled,
  ]);

  let recommended = recommendedApiData;

  if (appliedOptions && appliedOptions.filters) {
    if (appliedOptions.sort_by || appliedOptions.filters.length > 0) {
      recommended = [];
    }
  }

  let finalData = clone(categories) || [];
  if (showRecommendedItemsWidget && recommended && recommended.length > 0) {
    finalData.unshift({
      id: "recommended",
      name: t("common.recommended"),
      slug: "recommended",
      items: recommended,
      item_count: recommended.length,
      sub_categories: [],
    });
  }

  if (searchResults && searchActive) {
    finalData = [
      {
        id: "search-results",
        name: "Search Results",
        slug: "search-results",
        items: searchResults,
        item_count: searchResults?.length || 0,
        sub_categories: [],
      },
    ];
  }

  return (
    <div className="explore-wrapper ">
      {renderHelmet(seoData)}
      <div className="container">
        <div className="explore-container">
          {bannersForDisplay.length > 0 ? (
            <ImageCarousel
              isMobileView={isMobileView}
              data={bannersForDisplay}
              primaryColor={primaryColor}
              loading={bannerApiLoading}
              screenWidth={screenWidth}
              widthToHeightRatio={isMobileView ? 5 / 2.5 : 5 / 2}
            />
          ) : null}

          <ItemList
            {...props}
            isMobileView={isMobileView}
            loading={catalogueLoading || recommendedLoading}
            primaryColor={primaryColor}
            categoryWiseItemList={finalData}
            primaryTextColor={primaryTextColor}
            screenWidth={screenWidth}
            recommendedError={recommendedError}
            catalogueError={catalogueError}
            cart={cart}
            currentOrder={currentOrder}
          />
        </div>
      </div>

      {cart?.totalItemsCount > 0 && isMobileView && (
        <Suspense fallback={null}>
          <FloatingCart detailed={true} config={config} cart={cart} />
        </Suspense>
      )}

      {currentOrder && cart?.totalItemsCount <= 0 && isMobileView && (
        <Suspense fallback={null}>
          <FloatingOrder config={config} order={currentOrder} />
        </Suspense>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    upsdk: state.upsdk,
    selectedStore: upsdkSelector.getStore(state),
    cart: upsdkSelector.getCart(state),
    categories: state.catalogue.data ? state.catalogue.data.categories : [],
    catalogueLoading: state.catalogue.pending || state.search.pending,
    updateCatalogueLoading: state.catalogue.updateCataloguePending,
    catalogueError: state.catalogue.error === null ? false : true,
    appliedOptions: state.catalogue.data
      ? state.catalogue.data.appliedOptions
      : {},
    searchResults: state.search.data,
    fulfillmentType: upsdkSelector.getFullfilment(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      likeItem: likeItem,
      unlikeItem: unlikeItem,
      fetchLikes: fetchLikes,
    },
    dispatch
  );

export default withTranslation(
  ["translations"],
  translateOptions
)(connect(mapStateToProps, mapDispatchToProps)(Explore));
