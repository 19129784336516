import React, { useState, useEffect } from "react";

import Typography from "../typography/index.component";

import "./index.component.scss";

export default function ServerError({ message, duration }) {
  const [visible, setVisible] = useState(true);

  const hideMessage = () => {
    if (duration > 0) {
      setTimeout(() => {
        setVisible(false);
      }, duration);
    }
  };

  useEffect(() => {
    setVisible(true);
    hideMessage();

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, message]);

  if (!visible) return null;

  return (
    <div className="server-error-wrapper">
      <Typography variant="h3" weight="regular" className="error-message">
        {message}
      </Typography>
    </div>
  );
}
