import React from "react";
import { Helmet } from "react-helmet";

let FAVICON = "";
export function setDefaults({ favicon }) {
  FAVICON = favicon;
}

export default function renderHelmet({ title, description, keywords, author }) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={FAVICON} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
    </Helmet>
  );
}
