import React, { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import upsdkService from "common/services/upsdkService";
import { clearSubLocality } from "common/store/actions/ui";
import configSelector from "common/store/selectors/configSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import { useDialog } from "../../context/DialogContext";
import { getFulfilmentTypeObject } from "../../helpers/dataTransformer";
import { translateOptions } from "../../i18n";
import Typography from "../typography/index.component";

import "./index.component.scss";

function FulFilmentTypeSelector({
  clearSubLocality,
  fulfilmentCallback,
  fulfilmentType,
  config,
  upsdk,
  cart,
  primaryColor,
  secondaryColor,
  secondaryTextColor,
}) {
  const { t } = useTranslation();
  const { showDialog, hideDialog } = useDialog();

  const fulfilmentTypes = configSelector
    .getFulfilmentTypes({ config })
    .map((type) =>
      getFulfilmentTypeObject(type, primaryColor, secondaryColor, t),
    );

  const selectedType = upsdkSelector.getFullfilment({ upsdk });
  const selectedValue = getFulfilmentTypeObject(
    selectedType,
    primaryColor,
    secondaryColor,
    t,
  );

  useEffect(() => {
    if (fulfilmentTypes) {
      if (fulfilmentTypes.length < 2) {
        upsdkService.setFulfilmentType(
          fulfilmentTypes[0] && fulfilmentTypes[0]["key"],
        );
      }
    }

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set default fulfilmentMode
  useEffect(() => {
    if (!selectedValue) {
      const defaultMode = configSelector.getDefaultFulfilmentMode({ config });
      upsdkService.setFulfilmentType(defaultMode);
    }
  }, [config, selectedValue]);

  const validateFulfilmentModeChanges = (fulmentType) => {
    showDialog({
      title: t("header.clearCartHeading"),
      description: t("header.clearCartDescription"),
      buttonText: t("header.clearCartConfirm"),
      buttonColor: primaryColor,
      onClickCallback: () => {
        upsdkService.clearCart();
        upsdkService.removeStore();
        upsdkService.removeDeliveryAddress();
        clearSubLocality(null);
        hideDialog();
        fulfilmentCallback(fulmentType);
      },
    });
  };

  const onSelect = (fulmentType) => {
    if (cart.items.length) {
      validateFulfilmentModeChanges(fulmentType);
    } else {
      fulfilmentCallback(fulmentType);
    }
  };

  if (!selectedValue) return null;

  if (fulfilmentTypes && fulfilmentTypes.length < 2) {
    return null;
  }

  return (
    <div className="fulfilment-type-selector-wrapper">
      {fulfilmentTypes.map((type) => (
        <div
          key={type.key}
          className={`fulfilment-value`}
          style={
            type.key === fulfilmentType
              ? { borderBottom: `3px solid ${primaryColor}` }
              : {}
          }
          onClick={() => onSelect(type)}
        >
          <Typography
            variant="h2"
            weight="semiBold"
            fontColor={
              type.key === fulfilmentType ? primaryColor : secondaryTextColor
            }
          >
            {type.value}
          </Typography>
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    upsdk: state.upsdk,
    cart: upsdkSelector.getCart(state),
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSubLocality: clearSubLocality,
    },
    dispatch,
  );

export default withTranslation(
  ["translations"],
  translateOptions,
)(connect(mapStateToProps, mapDispatchToProps)(FulFilmentTypeSelector));
