import React from "react";
export default function AddressOthersIcon({
  fill,
  size,
  outlineColor = "#1C1B1F",
  className,
}) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill={fill} />
      <mask
        id="mask0_2382_27184"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="24"
        height="24"
      >
        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2382_27184)">
        <path
          d="M14.95 16.6029L19.2 12.3529L18.5 11.6279L14.95 15.1779L13.55 13.7779L12.825 14.4779L14.95 16.6029ZM16 24.2529C18.0833 22.4363 19.6873 20.6196 20.812 18.8029C21.9373 16.9863 22.5 15.4529 22.5 14.2029C22.5 12.2363 21.871 10.6279 20.613 9.37793C19.3543 8.12793 17.8167 7.50293 16 7.50293C14.1833 7.50293 12.646 8.12793 11.388 9.37793C10.1293 10.6279 9.5 12.2363 9.5 14.2029C9.5 15.4529 10.0627 16.9863 11.188 18.8029C12.3127 20.6196 13.9167 22.4363 16 24.2529ZM16 25.6279C13.4667 23.3779 11.5833 21.2946 10.35 19.3779C9.11667 17.4613 8.5 15.7363 8.5 14.2029C8.5 11.8529 9.25433 9.98226 10.763 8.59093C12.271 7.19893 14.0167 6.50293 16 6.50293C17.9833 6.50293 19.7293 7.19893 21.238 8.59093C22.746 9.98226 23.5 11.8529 23.5 14.2029C23.5 15.7363 22.8833 17.4613 21.65 19.3779C20.4167 21.2946 18.5333 23.3779 16 25.6279Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
}
