import React from "react";
export default function ChevronIcon({
  rotate,
  size,
  className,
  backgroundColor,
  fill,
  onClickCallback = () => {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size.width}
      height={size.height}
      viewBox="0 0 10 10"
      className={className}
      style={{
        backgroundColor: backgroundColor || "none",
        transform: rotate ? `rotate(${rotate})` : "",
      }}
      onClick={onClickCallback}
    >
      <defs>
        <path
          id="3mhl0yrwka"
          d="M1.175 1.875L5 5.741 8.825 1.875 10 3.071 5 8.125 0 3.071z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-380 -768) translate(32 753) translate(264) translate(84 15)">
                    <path d="M0 0H10V10H0z" />
                    <mask id="6fx1qz6odb" fill="#fff">
                      <use xlinkHref="#3mhl0yrwka" />
                    </mask>
                    <use
                      fill={fill || "#000"}
                      fillRule="nonzero"
                      xlinkHref="#3mhl0yrwka"
                    />
                    <path d="M0 0H10V10H0z" mask="url(#6fx1qz6odb)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
