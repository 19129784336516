import React from "react";

export default function PinOutlinedIcon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6501 0.85C7.40602 0.85 3.85 3.93828 3.85 8.3246C3.85 9.98845 4.51948 11.9345 5.23522 13.2128L10.7929 23.1522C10.793 23.1524 10.7931 23.1526 10.7932 23.1528C10.8762 23.3038 11.0002 23.4294 11.151 23.5167C11.302 23.6041 11.4746 23.65 11.6501 23.65C11.8257 23.65 11.9982 23.6041 12.1493 23.5167C12.3001 23.4294 12.4241 23.3038 12.5071 23.1527C12.5072 23.1525 12.5073 23.1524 12.5074 23.1522L18.0651 13.2128C18.7808 11.9345 19.4503 9.98842 19.4503 8.3246C19.4503 3.93828 15.8943 0.85 11.6501 0.85ZM11.6501 2.71956C13.3035 2.71956 14.7685 3.27 15.8186 4.24165C16.8675 5.21215 17.511 6.61032 17.511 8.3246C17.511 8.8939 17.3627 9.64633 17.1418 10.3859C16.9213 11.1242 16.6327 11.8359 16.3604 12.3223C16.3604 12.3224 16.3604 12.3224 16.3604 12.3224L11.6501 20.7295L6.93992 12.3224C6.93991 12.3224 6.9399 12.3224 6.9399 12.3223C6.66756 11.8359 6.37897 11.1242 6.1585 10.3859C5.93763 9.64634 5.78932 8.8939 5.78932 8.3246C5.78932 6.61032 6.43277 5.21215 7.48168 4.24165C8.53183 3.27 9.99677 2.71956 11.6501 2.71956ZM11.6501 4.5123C9.47068 4.5123 7.67507 6.22236 7.67507 8.3246C7.67507 10.4269 9.47069 12.1369 11.6501 12.1369C13.8296 12.1369 15.6252 10.4269 15.6252 8.3246C15.6252 6.22236 13.8296 4.5123 11.6501 4.5123ZM11.6501 6.38186C12.7904 6.38186 13.6859 7.2485 13.6859 8.3246C13.6859 9.40076 12.7904 10.2673 11.6501 10.2673C10.5099 10.2673 9.61439 9.40076 9.61439 8.3246C9.61439 7.2485 10.5099 6.38186 11.6501 6.38186Z"
        fill="black"
        stroke="black"
        strokeWidth="0.3"
      />
    </svg>
  );
}
