import React from "react";
import Shimmer from "react-shimmer-effect";

import Card from "../../../common/card/index.component";
import { ITEM_CATEGORY_DUMMY_DATA } from "../../../mock-data/index.mock-data";

import "./index.component.scss";

const CouponsShimmer = () => {
  return (
    <div className="coupons-wrapper-shimmer">
      <div className="container">
        <div className="coupons-container-shimmer">
          <Card styles={{ backgroundColor: "white" }}>
            <Shimmer>
              <div className="coupons-header-shimmer" />
            </Shimmer>
            <div className="coupons-items-shimmer">
              {ITEM_CATEGORY_DUMMY_DATA.map((item) => (
                <div className="coupons-solo-shimmer" key={item}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Shimmer>
                      <div
                        style={{
                          height: 20,
                          width: "60%",
                          borderRadius: 12,
                          marginTop: 10,
                        }}
                      />
                    </Shimmer>
                    <Shimmer>
                      <div
                        style={{
                          height: 10,
                          width: "20%",
                          borderRadius: 12,
                          marginTop: 10,
                        }}
                      />
                    </Shimmer>
                  </div>
                  <Shimmer>
                    <div
                      style={{
                        height: 10,
                        width: "100%",
                        borderRadius: 12,
                        marginTop: 20,
                      }}
                    />
                  </Shimmer>
                  <Shimmer>
                    <div
                      style={{
                        height: 10,
                        width: "100%",
                        borderRadius: 12,
                        marginTop: 10,
                      }}
                    />
                  </Shimmer>
                  <Shimmer>
                    <div
                      style={{
                        height: 15,
                        width: "20%",
                        borderRadius: 12,
                        marginTop: 10,
                      }}
                    />
                  </Shimmer>
                </div>
              ))}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CouponsShimmer;
