import React from "react";
import Shimmer from "react-shimmer-effect";

import "./index.component.scss";

const ReferAndEarnShimmer = () => {
  return (
    <div className="refer-and-earn-wrapper-shimmer">
      <div className="container">
        <div className="refer-and-earn-container-shimmer">
          <Shimmer>
            <div className="refer-and-earn-header-shimmer"></div>
          </Shimmer>
          <Shimmer>
            <div className="refer-and-earn-image-shimmer"></div>
          </Shimmer>
          <Shimmer>
            <div className="refer-and-earn-placeholder-one-shimmer" />
          </Shimmer>
          <Shimmer>
            <div className="refer-and-earn-placeholder-one-shimmer" />
          </Shimmer>
          <Shimmer>
            <div className="refer-and-earn-placeholder-two-shimmer" />
          </Shimmer>
          <div className="share-links-shimmers">
            <Shimmer>
              <div className="refer-and-earn-placeholder-three-shimmer" />
            </Shimmer>
            <Shimmer>
              <div className="refer-and-earn-placeholder-three-shimmer" />
            </Shimmer>
            <Shimmer>
              <div className="refer-and-earn-placeholder-three-shimmer" />
            </Shimmer>
            <Shimmer>
              <div className="refer-and-earn-placeholder-three-shimmer" />
            </Shimmer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferAndEarnShimmer;
