import React from "react";
export default function LandingScreenRadioActiveIcon({
  fill,
  size,
  className,
  innerFill,
  border,
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        transform="translate(4 4)"
        fillRule="nonzero"
        stroke={border || fill}
        fill={"none"}
      >
        <rect fill={fill} x=".5" y=".5" width="15" height="15" rx="7.5" />
        <rect fill={innerFill} x="3.5" y="3.5" width="9" height="9" rx="4.5" />
      </g>
    </svg>
  );
}
