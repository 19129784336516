import React from "react";

export default function GenericErrorInlineIcon({ className }) {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 46 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M45.2242 34.728L26.7442 2.90402C25.0642 0.0240187 20.8882 0.0240187 19.2562 2.90402L0.776229 34.728C-0.903771 37.608 1.20823 41.256 4.52023 41.256H41.4322C44.7922 41.256 46.9042 37.656 45.2242 34.728ZM22.9042 9.52802C24.2962 9.48002 25.4482 10.536 25.5442 11.928C25.5442 12.024 25.5442 12.072 25.5442 12.168L24.9202 25.512C24.8722 26.568 23.9602 27.384 22.9522 27.336C21.9442 27.288 21.1762 26.472 21.1282 25.512L20.5042 12.12C20.4082 10.776 21.5122 9.57602 22.9042 9.52802ZM23.0002 35.736C21.4162 35.736 20.1202 34.44 20.1202 32.856C20.1202 31.272 21.4162 29.976 23.0002 29.976C24.5842 29.976 25.8802 31.272 25.8802 32.856C25.8802 34.44 24.5842 35.736 23.0002 35.736Z"
        fill="#FF0000"
      />
    </svg>
  );
}
