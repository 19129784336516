import React from "react";
export default function ByRefAlreadySignedInIcon({ size }) {
  const height = size?.height || 174;
  const width = size?.width || 250;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_106_19965)">
        <path
          d="M157.399 124.02L166.267 107.796L157.432 121.963L157.528 116.066L163.64 104.329L157.553 114.506L157.726 103.901L164.27 94.5568L157.753 102.233L157.86 82.7878L157.213 107.428L150.586 97.2835L157.133 109.507L156.513 121.351L156.494 121.036L148.824 110.318L156.471 122.147L156.394 123.628L156.38 123.651L156.386 123.772L154.813 139.577H156.915L157.167 138.3L164.795 126.5L157.186 137.133L157.399 124.02Z"
          fill="#3F3D56"
        />
        <path
          d="M250 68.0094C250 97.941 232.206 108.392 210.256 108.392C188.307 108.392 170.513 97.941 170.513 68.0094C170.513 38.0778 210.256 0 210.256 0C210.256 0 250 38.0778 250 68.0094Z"
          fill="#F2F2F2"
        />
        <path
          d="M208.809 103.813L209.215 78.7634L226.155 47.7728L209.279 74.8337L209.462 63.5701L221.137 41.1488L209.511 60.5894V60.5896L209.84 40.3315L222.342 22.4814L209.892 37.146L210.097 0L208.805 49.1746L208.911 47.146L196.201 27.6904L208.707 51.0401L207.523 73.6647L207.488 73.0643L192.835 52.5902L207.444 75.1857L207.295 78.0152L207.269 78.0578L207.281 78.29L204.276 135.691H208.291L208.772 106.042L223.345 83.502L208.809 103.813Z"
          fill="#3F3D56"
        />
        <path
          d="M248.368 130.679C248.368 139.613 190.415 173.141 122.668 173.141C54.9202 173.141 0 155.789 0 146.855C0 137.921 53.5722 148.54 121.32 148.54C189.067 148.54 248.368 121.745 248.368 130.679Z"
          fill="#858585"
          fillOpacity="0.6"
        />
        <path
          opacity="0.1"
          d="M248.368 130.679C248.368 139.613 190.415 173.141 122.668 173.141C54.9202 173.141 0 155.789 0 146.855C0 137.921 53.5722 148.54 121.32 148.54C189.067 148.54 248.368 121.745 248.368 130.679Z"
          fill="#858585"
          fillOpacity="0.6"
        />
        <path
          d="M248.368 130.679C248.368 139.613 190.415 163.031 122.668 163.031C54.9202 163.031 0 155.789 0 146.855C0 137.921 53.5722 138.43 121.32 138.43C189.067 138.43 248.368 121.745 248.368 130.679Z"
          fill="#858585"
        />
        <path
          opacity="0.1"
          d="M124.04 152.581C152.81 152.581 176.132 150.777 176.132 148.552C176.132 146.327 152.81 144.523 124.04 144.523C95.2712 144.523 71.9492 146.327 71.9492 148.552C71.9492 150.777 95.2712 152.581 124.04 152.581Z"
          fill="black"
        />
        <path
          d="M82.7736 67.813C82.7736 67.813 98.0237 68.0954 95.1996 70.7783C92.3755 73.4612 81.7852 70.7783 81.7852 70.7783L82.7736 67.813Z"
          fill="#A0616A"
        />
        <path
          d="M50.9997 35.226C50.9997 35.226 57.1569 34.1264 59.2978 39.097C61.4387 44.0676 65.8355 58.2434 65.8355 58.2434C65.8355 58.2434 77.6018 66.0319 79.742 65.776C81.8822 65.5201 85.5408 66.0422 84.8697 68.3771C84.1986 70.7119 84.4965 73.3594 82.9515 73.6839C81.4065 74.0084 78.9492 70.911 77.0686 72.4029C75.1881 73.8948 58.4282 68.4456 57.3523 67.3165C56.2763 66.1874 47.3345 41.7386 47.3345 41.7386C47.3345 41.7386 47.6921 35.1463 50.9997 35.226Z"
          fill="#858585"
        />
        <path
          d="M64.2053 140.18L64.9113 146.111L58.9808 147.664L55.5918 146.111V140.886L64.2053 140.18Z"
          fill="#A0616A"
        />
        <path
          d="M48.2488 143.993L48.9547 149.924L43.0242 151.477L39.6353 149.924V144.699L48.2488 143.993Z"
          fill="#A0616A"
        />
        <path
          d="M64.7701 75.3675L67.453 85.1106L71.4067 104.879C71.4067 104.879 72.5363 109.68 70.2771 115.47C68.0178 121.259 63.9228 140.18 64.7701 141.31C65.6173 142.44 55.1682 143.287 55.3094 142.157C55.4506 141.028 56.8626 125.495 56.8626 125.495C56.8626 125.495 58.8395 111.798 59.8279 110.104C60.8163 108.409 55.3094 104.173 55.3094 104.173C55.3094 104.173 53.8973 120.412 51.7792 121.824C49.6612 123.236 49.9436 145.123 48.9551 145.405C47.9667 145.687 40.7653 146.817 39.918 145.405C39.0708 143.993 39.918 120.412 39.918 120.412C39.918 120.412 44.719 102.479 43.7306 99.9371C42.7421 97.3954 43.307 86.5226 43.307 86.5226C43.307 86.5226 39.6356 78.1916 41.6125 73.8142L64.7701 75.3675Z"
          fill="#2F2E41"
        />
        <path
          d="M64.3461 21.145C64.3461 21.145 58.4155 28.4877 58.6979 31.7354C58.9804 34.9831 49.3784 25.8048 49.3784 25.8048C49.3784 25.8048 56.2975 16.4853 56.2975 14.5084C56.2975 12.5315 64.3461 21.145 64.3461 21.145Z"
          fill="#A0616A"
        />
        <path
          d="M64.2756 23.8983C69.4226 23.8983 73.5951 19.7258 73.5951 14.5788C73.5951 9.43177 69.4226 5.25928 64.2756 5.25928C59.1285 5.25928 54.9561 9.43177 54.9561 14.5788C54.9561 19.7258 59.1285 23.8983 64.2756 23.8983Z"
          fill="#A0616A"
        />
        <path
          d="M40.7894 70.0851C40.3672 71.6581 39.8433 73.4416 39.9182 74.944C39.9662 75.9169 42.5488 76.5551 46.1199 76.9731C49.4311 77.3614 53.5925 77.5591 57.3753 77.6608C61.3474 77.7681 64.8987 77.7681 66.6059 77.7681C71.6892 77.7681 67.5943 74.5204 65.6174 72.9671C63.6406 71.4139 64.0642 49.3859 64.2054 46.5618C64.3466 43.7377 62.9346 35.5479 62.9346 33.9946C62.9346 32.4414 59.612 28.3874 59.612 28.3874C59.612 28.3874 58.8396 30.6057 55.7331 27.6404C52.6266 24.6751 48.5317 23.8279 48.5317 23.8279C45.4252 24.9575 40.2006 37.3835 39.9182 39.5016C39.8038 40.3615 39.8984 44.7657 40.0905 49.794C40.3686 57.1437 40.8544 65.832 41.189 66.7541C41.4531 67.4813 41.1608 68.7013 40.7894 70.0851Z"
          fill="#858585"
        />
        <path
          d="M55.8743 78.3328L56.8626 81.8629L53.0501 85.8167L51.7793 80.3095L55.8743 78.3328Z"
          fill="#A0616A"
        />
        <path
          d="M57.1447 144.558C57.1447 144.558 60.11 147.947 62.3693 145.687C64.6286 143.428 64.4874 142.016 64.911 142.016C65.3346 142.016 71.6888 147.664 71.6888 147.664C71.6888 147.664 82.5616 150.206 76.631 152.042C70.7004 153.877 53.897 152.607 53.897 152.042C53.897 151.477 53.4734 143.993 55.0266 143.993L57.1447 144.558Z"
          fill="#2F2E41"
        />
        <path
          d="M41.1887 148.37C41.1887 148.37 44.154 151.759 46.4132 149.5C48.6725 147.241 48.5313 145.829 48.9549 145.829C49.3785 145.829 55.7328 151.477 55.7328 151.477C55.7328 151.477 66.6055 154.019 60.6749 155.854C54.7443 157.69 37.941 156.419 37.941 155.854C37.941 155.289 37.5173 147.805 39.0706 147.805L41.1887 148.37Z"
          fill="#2F2E41"
        />
        <path
          d="M53.8125 22.0182C53.5115 22.2127 53.1195 22.4098 52.8181 22.2159C51.6973 19.4207 50.8596 16.5203 50.3175 13.5581C50.1272 12.5176 49.9951 11.3609 50.5731 10.475C50.8011 10.1256 51.1293 9.83993 51.2963 9.45763C51.5492 8.87867 51.3827 8.20851 51.4667 7.58234C51.6427 6.27051 52.8828 5.35554 54.1298 4.91179C55.3768 4.46804 56.7312 4.33039 57.9198 3.74811C59.0484 3.19521 59.9524 2.27827 60.9989 1.58244C62.0455 0.886612 63.3861 0.41085 64.5516 0.881041C65.5826 1.29699 66.2249 2.34795 67.1994 2.88306C67.8607 3.24619 68.6282 3.34724 69.3664 3.50312C71.4181 3.93842 73.3444 4.83337 75.0004 6.12062C75.4033 6.40802 75.7507 6.76615 76.0257 7.1776C77.2763 9.23661 75.0372 12.0359 76.025 14.2331L73.8893 12.5479C73.319 12.049 72.6758 11.6402 71.9819 11.3357C71.2745 11.0677 70.4367 11.0353 69.7941 11.4345C68.8923 11.9946 68.6477 13.1649 68.3327 14.1787C68.0176 15.1925 67.321 16.3168 66.2597 16.2908C64.8164 16.2555 64.3116 14.2412 63.0163 13.6035C62.172 13.1877 61.1091 13.4615 60.3893 14.0678C59.6695 14.6741 59.2375 15.5538 58.9207 16.44C58.7228 16.9938 58.537 17.6181 58.1854 18.0981C57.7976 18.6276 57.3016 18.6702 56.8874 19.0656C55.8092 20.0948 55.1463 21.1565 53.8125 22.0182Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M40.7893 70.085C42.5445 72.6309 44.5227 75.2573 46.1198 76.973C49.431 77.3613 53.5923 77.559 57.3752 77.6606C56.6994 76.7444 55.8544 75.9659 54.8858 75.3675C53.0501 74.2378 48.6728 60.8234 48.6728 60.8234C48.6728 60.8234 54.0385 46.9853 55.4506 41.7608C56.8626 36.5362 51.3557 33.5709 51.3557 33.5709C48.814 31.4528 44.4366 36.395 44.4366 36.395C44.4366 36.395 42.2027 43.1333 40.0903 49.7939C40.3685 57.1436 40.8543 65.8319 41.1889 66.754C41.453 67.4812 41.1607 68.7012 40.7893 70.085Z"
          fill="black"
        />
        <path
          d="M50.9319 32.4413C50.9319 32.4413 56.4389 35.4066 55.0268 40.6311C53.6148 45.8557 48.249 59.6938 48.249 59.6938C48.249 59.6938 52.6264 73.1082 54.462 74.2379C56.2977 75.3675 58.8394 78.0504 56.8625 79.4624C54.8856 80.8745 53.4736 83.1338 52.0615 82.4277C50.6495 81.7217 50.6495 77.768 48.249 77.768C45.8485 77.768 36.1054 63.0827 35.9642 61.5294C35.823 59.9762 44.0129 35.2654 44.0129 35.2654C44.0129 35.2654 48.3902 30.3232 50.9319 32.4413Z"
          fill="#858585"
        />
        <path
          d="M165.443 71.0366L163.854 69.4481C162.928 68.5215 161.671 68.001 160.361 68.001C159.051 68.001 157.794 68.5215 156.867 69.4481L126.055 100.26L95.2426 69.4481C94.316 68.5215 93.0593 68.001 91.749 68.001C90.4386 68.001 89.1819 68.5215 88.2554 69.4481L86.6668 71.0366C86.208 71.4954 85.8441 72.0401 85.5958 72.6395C85.3475 73.2389 85.2197 73.8814 85.2197 74.5302C85.2197 75.1791 85.3475 75.8215 85.5958 76.421C85.8441 77.0204 86.208 77.5651 86.6668 78.0238L117.479 108.836L86.6668 139.648C86.208 140.107 85.8441 140.652 85.5958 141.251C85.3475 141.851 85.2197 142.493 85.2197 143.142C85.2197 143.791 85.3475 144.433 85.5958 145.033C85.8441 145.632 86.208 146.177 86.6668 146.636L88.2554 148.224C89.1819 149.151 90.4386 149.671 91.749 149.671C93.0593 149.671 94.316 149.151 95.2426 148.224L126.055 117.412L156.867 148.224C157.794 149.151 159.051 149.671 160.361 149.671C161.671 149.671 162.928 149.151 163.854 148.224L165.443 146.636C166.37 145.709 166.89 144.452 166.89 143.142C166.89 141.832 166.37 140.575 165.443 139.648L134.631 108.836L165.443 78.0238C166.37 77.0973 166.89 75.8406 166.89 74.5302C166.89 73.2199 166.37 71.9632 165.443 71.0366Z"
          fill="#FF6584"
        />
        <path
          opacity="0.1"
          d="M85.5674 141.324L115.465 111.426L115.177 111.139L86.667 139.649C86.1894 140.126 85.8151 140.696 85.5674 141.324Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M86.2409 72.0382C87.1675 71.1117 88.4242 70.5911 89.7346 70.5911C91.0449 70.5911 92.3016 71.1117 93.2282 72.0382L124.04 102.851L154.853 72.0382C155.779 71.1117 157.036 70.5911 158.346 70.5911C159.657 70.5911 160.913 71.1117 161.84 72.0382L163.429 73.6268C164.11 74.3084 164.577 75.1745 164.773 76.1184C164.968 77.0623 164.883 78.0427 164.528 78.9388L165.443 78.0238C165.902 77.5651 166.266 77.0204 166.514 76.421C166.762 75.8215 166.89 75.1791 166.89 74.5302C166.89 73.8814 166.762 73.2389 166.514 72.6395C166.266 72.0401 165.902 71.4954 165.443 71.0366L163.855 69.4481C162.928 68.5215 161.671 68.001 160.361 68.001C159.051 68.001 157.794 68.5215 156.867 69.4481L126.055 100.26L95.2428 69.4481C94.3162 68.5215 93.0595 68.001 91.7491 68.001C90.4388 68.001 89.1821 68.5215 88.2555 69.4481L86.667 71.0366C86.1894 71.5137 85.8151 72.0839 85.5674 72.7118L86.2409 72.0382Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M134.919 109.124L132.616 111.426L163.428 142.239C164.11 142.92 164.577 143.786 164.773 144.73C164.968 145.674 164.883 146.655 164.528 147.551L165.443 146.636C165.902 146.177 166.266 145.632 166.514 145.033C166.762 144.433 166.89 143.791 166.89 143.142C166.89 142.493 166.762 141.851 166.514 141.251C166.266 140.652 165.902 140.107 165.443 139.648L134.919 109.124Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_106_19965">
          <rect width="250" height="173.141" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
