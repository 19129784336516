import React from "react";
import Shimmer from "react-shimmer-effect";

import "./index.component.scss";

const PageNotFoundShimmer = () => {
  return (
    <div className="page-not-found-wrapper-shimmer">
      <div className="container">
        <div className="page-not-found-container-shimmer">
          <Shimmer>
            <div className="page-not-found-image-shimmer" />
          </Shimmer>
          <Shimmer>
            <div className="page-not-found-placeholder-one-shimmer" />
            <div className="page-not-found-placeholder-two-shimmer" />
          </Shimmer>
          <Shimmer>
            <div className="page-not-found-button-shimmer" />
          </Shimmer>
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundShimmer;
