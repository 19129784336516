import CONSTANTS from "../constants.json";
import { store } from "../store";
import { SET_CONFIG } from "../store/actions/config";

export let current_config = null;

function init(config) {
  current_config = config;
  let finalConfig = { ...current_config, ...{ constants: CONSTANTS } };
  store.dispatch({ type: SET_CONFIG, data: finalConfig });
  return config;
}

function getBuildTimeConfig(key, defaultValue = null) {
  const value = current_config[key];
  return value || defaultValue;
}

const configService = {
  init,
  getBuildTimeConfig,
};

export default configService;
