import React from "react";
export default function AddressWorkIcon({
  fill,
  size,
  outlineColor = "#1C1B1F",
  className,
}) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill={fill} />
      <mask
        id="mask0_2382_27167"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="24"
        height="24"
      >
        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2382_27167)">
        <path
          d="M8.625 24.0029C8.15833 24.0029 7.771 23.8489 7.463 23.5409C7.15433 23.2323 7 22.8446 7 22.3779V12.6279C7 12.1613 7.15433 11.7739 7.463 11.4659C7.771 11.1573 8.15833 11.0029 8.625 11.0029H13V9.62793C13 9.16126 13.1543 8.77393 13.463 8.46593C13.771 8.15726 14.1583 8.00293 14.625 8.00293H17.375C17.8417 8.00293 18.2293 8.15726 18.538 8.46593C18.846 8.77393 19 9.16126 19 9.62793V11.0029H23.375C23.8417 11.0029 24.2293 11.1573 24.538 11.4659C24.846 11.7739 25 12.1613 25 12.6279V22.3779C25 22.8446 24.846 23.2323 24.538 23.5409C24.2293 23.8489 23.8417 24.0029 23.375 24.0029H8.625ZM14 11.0029H18V9.62793C18 9.46126 17.9373 9.3156 17.812 9.19093C17.6873 9.0656 17.5417 9.00293 17.375 9.00293H14.625C14.4583 9.00293 14.3127 9.0656 14.188 9.19093C14.0627 9.3156 14 9.46126 14 9.62793V11.0029ZM8.625 23.0029H23.375C23.5417 23.0029 23.6873 22.9403 23.812 22.8149C23.9373 22.6903 24 22.5446 24 22.3779V12.6279C24 12.4613 23.9373 12.3156 23.812 12.1909C23.6873 12.0656 23.5417 12.0029 23.375 12.0029H8.625C8.45833 12.0029 8.31267 12.0656 8.188 12.1909C8.06267 12.3156 8 12.4613 8 12.6279V22.3779C8 22.5446 8.06267 22.6903 8.188 22.8149C8.31267 22.9403 8.45833 23.0029 8.625 23.0029Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
}
