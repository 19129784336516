import {
  FETCH_LIKES_ERROR,
  FETCH_LIKES_PENDING,
  FETCH_LIKES_SUCCESS,
  FETCH_FAV_PENDING,
  FETCH_FAV_SUCCESS,
  FETCH_FAV_ERROR,
} from "../actions/likes";

const initialState = {
  pending: false,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LIKES_PENDING:
      return {
        ...state,
        likePending: true,
      };
    case FETCH_LIKES_SUCCESS:
      return {
        ...state,
        likePending: false,
        data: action.payload,
      };
    case FETCH_LIKES_ERROR:
      return {
        ...state,
        likePending: false,
        error: action.error,
      };
    case FETCH_FAV_PENDING:
      return {
        ...state,
        favPending: true,
      };
    case FETCH_FAV_SUCCESS:
      return {
        ...state,
        favPending: false,
        favorites: action.payload,
      };
    case FETCH_FAV_ERROR:
      return {
        ...state,
        favPending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
