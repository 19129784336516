import React from "react";

import "./index.component.scss";

export default function Card({
  id,
  styles,
  disabled,
  children,
  className,
  disabledCardHeading,
}) {
  if (disabled) {
    return (
      <div id={id} className="card-wrapper disabled-card">
        {disabledCardHeading}
      </div>
    );
  }

  return (
    <div
      id={id}
      style={styles}
      className={`${className ? className : ""} card-wrapper`}
    >
      {children}
    </div>
  );
}
