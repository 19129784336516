export default function (start, end) {
  let setDateTime = function (date, str) {
    var sp = str.split(":");
    date.setHours(parseInt(sp[0], 10));
    date.setMinutes(parseInt(sp[1], 10));
    date.setSeconds(parseInt(sp[2], 10));
    return date;
  };

  let current = new Date();

  let c = current.getTime(),
    startTime = setDateTime(new Date(current), start),
    endTime = setDateTime(new Date(current), end);

  return c > startTime.getTime() && c < endTime.getTime();
}
