import React from "react";
export default function DeliveryIcon({ fill, size, className }) {
  return (
    <svg
      width="24px"
      height="16px"
      viewBox="0 0 24 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <defs>
        <path
          d="M18.504,8.92307692 L13.692,4 L8.4,4 L8.4,6.46153846 L12.708,6.46153846 L15.108,8.92307692 L5.4,8.92307692 C2.4,8.92307692 -1.77635684e-15,11.3846154 -1.77635684e-15,14.4615385 C-1.77635684e-15,17.5384615 2.4,20 5.4,20 C8.064,20 10.272,18.0061538 10.704,15.4092308 L13.248,13.8461538 C13.2,14.0553846 13.2,14.2523077 13.2,14.4615385 C13.2,17.5384615 15.6,20 18.6,20 C21.6,20 24,17.5384615 24,14.4615385 C24,11.3846154 21.6,8.92307692 18.6,8.92307692 L18.504,8.92307692 Z M8.208,15.3969231 C7.8,16.64 6.696,17.5384615 5.364,17.5384615 C3.708,17.5384615 2.364,16.16 2.364,14.4615385 C2.364,12.7630769 3.708,11.3846154 5.364,11.3846154 C6.708,11.3846154 7.8,12.2953846 8.208,13.5384615 L4.8,13.5384615 L4.8,15.3846154 L8.208,15.3969231 L8.208,15.3969231 Z M18.564,17.5384615 C16.908,17.5384615 15.564,16.16 15.564,14.4615385 C15.564,12.7630769 16.908,11.3846154 18.564,11.3846154 C20.2208542,11.3846154 21.564,12.7622008 21.564,14.4615385 C21.564,16.1608762 20.2208542,17.5384615 18.564,17.5384615 Z"
          id="path-1"
        ></path>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Navigation/Desktop/Top"
          transform="translate(-230.000000, -16.000000)"
        >
          <g id="Icon" transform="translate(230.000000, 12.000000)">
            <rect
              id="Boudning-Box"
              x="-1.77635684e-15"
              y="-1.77635684e-15"
              width="24"
              height="24"
            ></rect>
            <mask id="mask-2" fill="white">
              {/* <use xlinkHref="#path-1"></use> */}
            </mask>
            <use fill={fill} fillRule="nonzero" xlinkHref="#path-1"></use>
            <rect
              id="Rectangle"
              fill={"none"}
              mask="url(#mask-2)"
              x="0"
              y="0"
              width="32"
              height="32"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}
