import React from "react";
import Shimmer from "react-shimmer-effect";

import { ITEM_MINIMAL_DATA } from "../../../mock-data/index.mock-data";

import "./index.component.scss";

const WalletShimmer = () => {
  return (
    <div className="wallet-wrapper-shimmer">
      <div className="container">
        <div className="wallet-container-shimmer">
          <div className="wallet-section-shimmer">
            <div>
              <Shimmer>
                <div className="wallet-header-shimmer" />
              </Shimmer>
              <Shimmer>
                <div className="wallet-icon-placeholder-shimmer" />
              </Shimmer>
              <Shimmer>
                <div className="wallet-placeholder-one-shimmer" />
              </Shimmer>
              <div className="wallet-placeholder-amount-shimmer">
                {ITEM_MINIMAL_DATA.map((item) => (
                  <Shimmer key={item}>
                    <div />
                  </Shimmer>
                ))}
              </div>
            </div>
            <Shimmer>
              <div className="wallet-button-shimmer" />
            </Shimmer>
          </div>
          <div className="wallet-section-shimmer">
            <Shimmer>
              <div className="wallet-header-shimmer" />
            </Shimmer>
            {ITEM_MINIMAL_DATA.map((item) => (
              <div className="wallet-section-card-shimmer" key={item}>
                <Shimmer>
                  <div className="wallet-placeholder-one-shimmer" key={1} />
                </Shimmer>
                <Shimmer>
                  <div className="wallet-placeholder-two-shimmer" key={2} />
                </Shimmer>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletShimmer;
