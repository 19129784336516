import PaymentMode from "../models/PaymentMode";

function getCart({ upsdk }) {
  if (!upsdk || !upsdk.cart) {
    return {
      total: 0,
      items: [],
    };
  }
  return upsdk.cart;
}

function getAddress({ upsdk }) {
  return upsdk?.selected_address;
}

function getStore({ upsdk }) {
  return upsdk.selected_store;
}

function getChannel({ upsdk }) {
  return upsdk.channel;
}

function getFullfilment({ upsdk }) {
  return upsdk.order_type;
}

function getPayment({ upsdk }) {
  let data = upsdk.payment_option;
  if (data) {
    data = new PaymentMode(data);
  }
  return data;
}

function getPaymentOptions({ upsdk }) {
  let data = [];
  if (upsdk.payment_options && upsdk.payment_options) {
    data = upsdk.payment_options.map((mode) => new PaymentMode(mode));
  }
  return data;
}

function getBill({ upsdk }) {
  if (!upsdk) return {};
  return upsdk.bill;
}

function getLang({ upsdk }) {
  return upsdk.lang;
}

function getBizInfo({ upsdk }) {
  if (!upsdk) return {};
  let biz_info = upsdk.biz_info;
  if (!biz_info) {
    biz_info = {
      is_pickup_enabled: false,
      supported_languages: ["en"],
    };
  }
  return biz_info;
}

function getBizConfig({ upsdk }) {
  return upsdk.biz_config;
}

function getISDCode({ upsdk }) {
  return getBizInfo({ upsdk }).isd_code;
}

function getCurrency({ upsdk }) {
  return getBizInfo({ upsdk }).currency;
}

function getAuthHeader({ upsdk }) {
  return upsdk.auth_token;
}

function getCoupon({ upsdk }) {
  return upsdk.coupon;
}

function getMinOrderTotal({ upsdk }) {
  let min_order_value = 0;
  let biz_info = upsdk.biz_info;
  let selected_store = upsdk.selected_store;
  if (biz_info && biz_info.min_order_total) {
    min_order_value = biz_info.min_order_total;
  }
  if (selected_store && selected_store.min_order_total) {
    min_order_value = selected_store.min_order_total;
  }
  return min_order_value;
}

function getDeliveryMinOffsetTime({ upsdk }) {
  let biz_info = getBizInfo({ upsdk });
  return biz_info.delivery_min_offset_time / 1000 / 60;
}

function getWalletTransactionLimit({ upsdk }) {
  let biz_info = getBizInfo({ upsdk });
  return biz_info.wallet_txn_limit || 5000;
}

function getCODMaxOrderLimit({ upsdk }) {
  let biz_info = getBizInfo({ upsdk });
  return biz_info.cod_max_order_value;
}

function getSupportedLanguages({ upsdk }) {
  return upsdk?.biz_info?.supported_languages || [];
}

function getReferralShareLabelText({ upsdk }) {
  let biz_info = getBizInfo({ upsdk });
  return biz_info.referral_share_lbl;
}

function getReferralUiLabelText({ upsdk }) {
  let biz_info = getBizInfo({ upsdk });
  return biz_info.referral_ui_lbl;
}

function getFeedbackChoices({ upsdk }) {
  if (upsdk && upsdk.feedback_config) {
    return upsdk.feedback_config[0]?.choices;
  }
}

const upsdkSelector = {
  getCart,
  getAddress,
  getStore,
  getFullfilment,
  getPayment,
  getPaymentOptions,
  getBill,
  getLang,
  getBizInfo,
  getBizConfig,
  getAuthHeader,
  getCoupon,
  getMinOrderTotal,
  getDeliveryMinOffsetTime,
  getWalletTransactionLimit,
  getCODMaxOrderLimit,
  getISDCode,
  getCurrency,
  getSupportedLanguages,
  getReferralShareLabelText,
  getReferralUiLabelText,
  getFeedbackChoices,
  getChannel,
};

export default upsdkSelector;
