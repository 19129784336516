import React from "react";
import { useTranslation } from "react-i18next";

import { NO_RESULTS_FOUND_ICON } from "../../constants/image.constants";
import Typography from "../typography/index.component";

const EmptySearchResult = ({ primaryTextColor, secondaryTextColor }) => {
  const { t } = useTranslation();

  return (
    <div className="empty-search-result-wrapper">
      <img
        src={NO_RESULTS_FOUND_ICON}
        alt="Search"
        className="no-results-found-icon"
      />
      <Typography
        variant="h2"
        weight="bold"
        className="empty-state-heading"
        fontColor={primaryTextColor}
      >
        {t("common.noResultsFound")}
      </Typography>
      <Typography
        variant="h4"
        weight="regular"
        className="helper-text"
        fontColor={secondaryTextColor}
      >
        {t("common.noResultsFoundHelperText")}
      </Typography>
    </div>
  );
};

export default EmptySearchResult;
