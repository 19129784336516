import React from "react";

import dayjsUtils from "@date-io/dayjs";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";

import "./index.component.scss";

export default function TimeField(props) {
  // Props
  const {
    label,
    className,
    inputIcon,
    primaryTextColor,
    refForm,
    name,
    errors,
    helperText,
    disabled,
    selectedTime,
    onTimeChange,
  } = props;

  // Callbacks
  const { iconBackgroundColor } = props;

  const handleTimeChange = (time) => {
    onTimeChange(time);
  };

  return (
    <div
      className={classNames([
        `time-field-wrapper ${className || ""}`,
        {
          disabled: disabled,
        },
      ])}
    >
      {inputIcon && (
        <div
          className="field-icon-wrapper"
          style={{ backgroundColor: iconBackgroundColor }}
        >
          {inputIcon}
        </div>
      )}
      <MuiPickersUtilsProvider utils={dayjsUtils}>
        <KeyboardTimePicker
          margin="normal"
          label={label}
          name={name}
          value={selectedTime}
          inputRef={refForm}
          inputProps={{ disabled: true }}
          error={errors && errors[name]}
          onChange={handleTimeChange}
          classes={{ root: "time-field" }}
          style={{ margin: 8, color: primaryTextColor }}
          helperText={errors && errors[name] ? helperText : null}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
