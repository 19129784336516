import ReactGA from "react-ga4";
import analyticsPubSub, { ANALYTICS_EVENTS } from "./analyticsPubSub";

const GA_EVENTS = [
  ANALYTICS_EVENTS.APP_LAUNCHED,
  ANALYTICS_EVENTS.DELIVERY_STORE_SELECTED,
  ANALYTICS_EVENTS.NO_STORES_NEARBY,
  ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TEMP,
  ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TODAY,
  ANALYTICS_EVENTS.ITEM_SEARCH,
  ANALYTICS_EVENTS.REORDER,
  ANALYTICS_EVENTS.COUPON_SUCCESS,
  ANALYTICS_EVENTS.COUPON_FAILED,
  ANALYTICS_EVENTS.LOGIN_SUCCESS,
  ANALYTICS_EVENTS.LOGIN_FAILED,
  ANALYTICS_EVENTS.PASSWORD_RESET,
  ANALYTICS_EVENTS.RESEND_OTP,
  ANALYTICS_EVENTS.SIGNUP_START,
  ANALYTICS_EVENTS.SIGNUP_COMPLETE,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_START,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_COMPLETE,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_SUCCESS,
  ANALYTICS_EVENTS.SOCIAL_LOGIN_FAILED,
  ANALYTICS_EVENTS.WALLET_RELOAD_INIT,
  ANALYTICS_EVENTS.SUCCESSFUL_WALLET_RELOAD,
  ANALYTICS_EVENTS.FAILED_WALLET_RELOAD,
  ANALYTICS_EVENTS.REFERRAL_SENT,
  ANALYTICS_EVENTS.LOGOUT,
  ANALYTICS_EVENTS.PRODUCT_CLICKED,
  ANALYTICS_EVENTS.CART_INIT,
  ANALYTICS_EVENTS.ADD_TO_CART,
  ANALYTICS_EVENTS.REMOVE_FROM_CART,
  ANALYTICS_EVENTS.VIEW_CART,
  ANALYTICS_EVENTS.ORDER_PLACED,
  ANALYTICS_EVENTS.ITEM_LIKED,
  ANALYTICS_EVENTS.ITEM_UNLIKED,
  ANALYTICS_EVENTS.FEEDBACK_SUBMITTED,
  ANALYTICS_EVENTS.CHECKOUT_INIT,
  ANALYTICS_EVENTS.PROFILE_UPDATED,
  ANALYTICS_EVENTS.BANNER_CLICKED,
  ANALYTICS_EVENTS.ADDRESS_SELECTED,
  ANALYTICS_EVENTS.PICKUP_STORE_SELECTED,
  ANALYTICS_EVENTS.MULTIPLE_DELIVERY_STORES,
  ANALYTICS_EVENTS.FILTER_APPLIED,
  ANALYTICS_EVENTS.SORT_APPLIED,
  ANALYTICS_EVENTS.ADD_MORE_ITEMS,
  ANALYTICS_EVENTS.CATEGORY_DETAIL,
];
let currency = "INR";

function setCurrency(currencyCode) {
  currency = currencyCode;
  ReactGA.gtag("set", {
    currency: currencyCode,
  });
}

function setUser(user) {
  ReactGA.gtag("set", {
    user_id: user?.username,
  });
}

function resetUser() {
  ReactGA.gtag("set", {
    user_id: null,
  });
}

function subscribe() {
  GA_EVENTS.forEach((event) => {
    analyticsPubSub.subscribe(event, (response) => {
      handleEvent(event, response);
    });
  });
}
function handleEvent(type, data) {
  let eventData = {};

  switch (type) {
    case ANALYTICS_EVENTS.APP_LAUNCHED:
      eventData = {
        event_name: "app-launched",
      };
      break;
    case ANALYTICS_EVENTS.DELIVERY_STORE_SELECTED:
      eventData = {
        event_name: "delivery-store-selected",
      };
      break;
    case ANALYTICS_EVENTS.NO_STORES_NEARBY:
      eventData = {
        event_name: "no-stores-nearby",
      };
      break;
    case ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TEMP:
      eventData = {
        event_name: "nearest-store-closed-temp",
      };
      break;
    case ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TODAY:
      eventData = {
        event_name: "nearest-store-closed",
      };
      break;
    case ANALYTICS_EVENTS.ITEM_SEARCH:
      eventData = {
        event_name: "search",
        search_term: data?.query,
      };
      break;
    case ANALYTICS_EVENTS.REORDER:
      eventData = {
        event_name: "re-order",
        value: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.COUPON_SUCCESS:
      eventData = {
        event_name: "coupon-applied",
        value: data.coupon_code,
        discount: data?.discount,
      };
      break;
    case ANALYTICS_EVENTS.COUPON_FAILED:
      eventData = {
        event_name: "ppr-coupon-applied",
        value: data.coupon_code,
        discount: data?.discount,
      };

      break;
    case ANALYTICS_EVENTS.LOGIN_SUCCESS:
      eventData = {
        event_name: "login-success",
        user: data?.username,
        social_auth: data?.social_auth,
      };
      setUser(data);
      break;
    case ANALYTICS_EVENTS.LOGIN_FAILED:
      eventData = {
        event_name: "login-failed",
        user: data?.username,
        social_auth: data?.social_auth,
      };
      break;
    case ANALYTICS_EVENTS.PASSWORD_RESET:
      eventData = {
        event_name: "password-reset",
      };
      break;
    case ANALYTICS_EVENTS.RESEND_OTP:
      eventData = {
        event_name: "resend-otp",
      };
      break;
    case ANALYTICS_EVENTS.SIGNUP_START:
      eventData = {
        event_name: "signup-start",
        user: data?.username,
      };
      break;
    case ANALYTICS_EVENTS.SIGNUP_COMPLETE:
      eventData = {
        event_name: "signup-complete",
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_START:
      eventData = {
        event_name: "social-signup-start",
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_COMPLETE:
      eventData = {
        event_name: "social-signup-complete",
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_SUCCESS:
      eventData = {
        event_name: "social-login-success",
        value: data.social_auth,
      };
      break;
    case ANALYTICS_EVENTS.SOCIAL_LOGIN_FAILED:
      eventData = {
        event_name: "social-login-failed",
      };
      break;
    case ANALYTICS_EVENTS.WALLET_RELOAD_INIT:
      eventData = {
        event_name: "wallet-reload-init",
        value: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.SUCCESSFUL_WALLET_RELOAD:
      eventData = {
        event_name: "wallet-reload-complete",
        value: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.FAILED_WALLET_RELOAD:
      eventData = {
        event_name: "wallet-reload-failed",
        value: data.amount,
      };
      break;
    case ANALYTICS_EVENTS.REFERRAL_SENT:
      eventData = {
        event_name: "referral-sent",
      };
      break;
    case ANALYTICS_EVENTS.LOGOUT:
      eventData = {
        event_name: "logout",
      };
      resetUser();
      break;
    case ANALYTICS_EVENTS.PRODUCT_CLICKED:
      eventData = {
        event_name: "view_item",
        currency: currency,
        items: [
          {
            name: data.item_title,
            id: data.item_id,
            price: data.item_price,
            category: data.item_category,
            image_portrait: data.thumbnail,
          },
        ],
      };
      break;
    case ANALYTICS_EVENTS.CART_INIT:
      eventData = {
        event_name: "ppr-cart-init",
      };
      break;
    case ANALYTICS_EVENTS.ADD_TO_CART:
      eventData = {
        event_name: "add_to_cart",
        currency: currency,
        items: [
          {
            name: data.item_title,
            id: data.id,
            price: data.item_price,
            category: data.item_category_title,
            quantity: data.qty,
          },
        ],
      };
      break;
    case ANALYTICS_EVENTS.REMOVE_FROM_CART:
      eventData = {
        event_name: "remove_from_cart",
        currency: currency,
        items: [
          {
            name: data.item_title,
            id: data.id,
            price: data.item_price,
            category: data.item_category_title,
            quantity: data.qty,
          },
        ],
      };
      break;
    case ANALYTICS_EVENTS.VIEW_CART:
      eventData = {
        event_name: "view_cart",
        currency: currency,
        value: data.payable_amount,
        items: data.cart_items,
      };
      break;
    case ANALYTICS_EVENTS.ORDER_PLACED:
      eventData = {
        event_name: "purchase",
        value: data.order_meta.revenue,
        coupon: data?.coupon,
        payment_option: data?.payment_option,
        fulfillment_mode: data?.order_type,
        discount: data?.discount,
        items: data.items,
        ...data?.order_meta,
      };
      break;
    case ANALYTICS_EVENTS.ITEM_LIKED:
      eventData = {
        event_name: "item-liked",
        value: data?.item_title,
        like: data?.like,
      };
      break;
    case ANALYTICS_EVENTS.ITEM_UNLIKED:
      eventData = {
        event_name: "item-unliked",
        value: data?.item_title,
      };
      break;
    case ANALYTICS_EVENTS.FEEDBACK_SUBMITTED:
      eventData = {
        event_name: "feedback-submitted",
      };
      break;
    case ANALYTICS_EVENTS.CHECKOUT_INIT:
      eventData = {
        event_name: "begin_checkout",
        items: data.items,
      };
      break;
    case ANALYTICS_EVENTS.PROFILE_UPDATED:
      eventData = {
        event_name: "profile-updated",
      };
      break;
    case ANALYTICS_EVENTS.BANNER_CLICKED:
      eventData = {
        event_name: "banner-clicked",
      };
      break;
    case ANALYTICS_EVENTS.ADDRESS_SELECTED:
      eventData = {
        event_name: "addr-selected",
      };
      break;
    case ANALYTICS_EVENTS.ADD_MORE_ITEMS:
      eventData = {
        event_name: "add-more-items",
      };
      break;
    case ANALYTICS_EVENTS.MULTIPLE_DELIVERY_STORES:
      eventData = {
        event_name: "multiple-delivery-stores",
      };
      break;
    case ANALYTICS_EVENTS.PICKUP_STORE_SELECTED:
      eventData = {
        event_name: "pickup-store-selected",
      };
      break;
    case ANALYTICS_EVENTS.FILTER_APPLIED:
      eventData = {
        event_name: "filter-applied",
        value: data.filter_tags,
      };
      break;
    case ANALYTICS_EVENTS.SORT_APPLIED:
      eventData = {
        event_name: "sort-applied",
        value: data.sort,
      };
      break;

    case ANALYTICS_EVENTS.CATEGORY_DETAIL:
      eventData = {
        event_name: "ppr-category-detail",
        name: data?.name,
      };
      break;
  }
  if (eventData) {
    trackEvent(eventData);
  }
}
function trackEvent(eventData) {
  ReactGA.gtag("event", eventData.event_name, eventData);
}

function init({ gaId }) {
  ReactGA.initialize(gaId);

  subscribe();
}

const GAService = {
  init,
  setCurrency,
  setUser,
  resetUser,
};

export default GAService;
