import React from "react";
import { useTranslation } from "react-i18next";
import GenericErrorInlineIcon from "../../assets/icons/generic-error-inline-icon";
import Typography from "../typography/index.component";
import AppButton from "../button/index.component";
import { COLOR_WHITE } from "../../constants/colors.constants";

import "./index.styles.scss";

function GenericErrorInline({ primaryColor }) {
  const { t } = useTranslation();

  const handlePageRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="generic-error-inline-wrapper">
      <GenericErrorInlineIcon />
      <Typography
        variant="h1"
        weight="bold"
        className="generic-error-inline-title"
      >
        {t("common.wentWrongTitle")}
      </Typography>
      <Typography
        variant="h3"
        weight="regular"
        className="generic-error-inline-desc"
      >
        {t("common.loadingError", { componentName: "menu" })}
      </Typography>
      <AppButton
        fullWidth
        variant="contained"
        className="generic-error-inline-refresh"
        onClickCallback={handlePageRefresh}
        buttonColor={primaryColor}
      >
        <Typography variant="h3" weight="bold" fontColor={COLOR_WHITE}>
          {t("common.refresh")}
        </Typography>
      </AppButton>
    </div>
  );
}

export default GenericErrorInline;
