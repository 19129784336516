import React from "react";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function MultipleStoreDialog({
  secondaryTextColor,
  primaryTextColor,
  data,
  buttonClickCallback,
  handleMouseEnter,
  handleMouseLeave,
}) {
  const handleSelect = (store) => {
    buttonClickCallback(store);
  };

  const getStoreDistanceInKillometer = (distanceInMeter) => {
    if (distanceInMeter) {
      const killomenters = distanceInMeter / 1000;
      return `${killomenters.toFixed(1)} km`;
    }
    return "";
  };

  if (!data) return null;

  return (
    <div className="multiple-store-form">
      <div className="dialog-content">
        <ul className="store-list">
          {data.map((row) => (
            <li
              className="store-details"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSelect(row)}
            >
              <div className="store-header">
                <Typography
                  variant="h2"
                  weight="regular"
                  className="store-name"
                  fontColor={primaryTextColor}
                >
                  {row.name}
                </Typography>
                <Typography
                  variant="h3"
                  weight="regular"
                  className="store-distance"
                  fontColor={secondaryTextColor}
                >
                  {getStoreDistanceInKillometer(row.delivery_distance)}
                </Typography>
              </div>
              <Typography
                variant="h4"
                weight="regular"
                className="store-address"
                fontColor={secondaryTextColor}
              >
                {row.address}
              </Typography>
              <Typography
                variant="h4"
                weight="regular"
                className="store-phone"
                fontColor={secondaryTextColor}
              >
                {row.phone}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
