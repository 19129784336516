function getFontWeight(type) {
  const weight = {
    regular: {
      fontWeight: 400,
    },
    semiBold: {
      fontWeight: 600,
    },
    bold: {
      fontWeight: 700,
    },
  };
  return weight[type];
}

const fonts = {
  "Open Sans": {
    h1: {
      fontFamily: {
        fontFamily: "Open Sans",
      },
      size: {
        fontSize: "1.25rem",
      },
    },
    h2: {
      fontFamily: {
        fontFamily: "Open Sans",
      },
      size: {
        fontSize: "1rem",
      },
    },
    h3: {
      fontFamily: {
        fontFamily: "Open Sans",
      },
      size: {
        fontSize: "0.875rem",
      },
    },
    h4: {
      fontFamily: {
        fontFamily: "Open Sans",
      },
      size: {
        fontSize: "0.75rem",
      },
    },
    para: {
      fontFamily: {
        fontFamily: "Open Sans",
      },
      size: {
        fontSize: "0.625rem",
      },
    },
  },
};

export default function fontHelper(
  selectedFontFamily = "Open Sans",
  variant = "h4",
  weight = "regular"
) {
  let style = {};

  let font =
    selectedFontFamily && fonts[selectedFontFamily]
      ? fonts[selectedFontFamily][variant]
      : fonts["Open Sans"][variant];

  const fontFamily = font ? font["fontFamily"] : {};
  const fontSize = font ? font["size"] : {};
  const fontWeight = weight ? getFontWeight(weight) : {};

  style = { ...fontFamily, ...fontSize, ...fontWeight };

  return style;
}
