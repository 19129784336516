import dayjs from "dayjs";
import { getOpeningClosingForDate } from "./helpers/getOpeningClosing";
import { getOffsetTime } from "./helpers/getOffsetTime";
import { getAvailableSlots } from "./helpers/getAvailableSlots";

// default values
let HIDE_DELIVER_NOW = false;
let ENABLE_TIME_SLOTS = false;
let MAX_PRE_ORDER_DATE = 10;
let HIDE_DELIVER_NOW_AFTER_OFFSET = 10000000000000;

let _bizObject = null;
let _storeObject = null;
let _fulfilmentType = null;
function init({
  bizObject,
  fulfilmentType,
  storeObject,
  hideDeliverNow = HIDE_DELIVER_NOW,
  hideDeliverNowAfterOffset = HIDE_DELIVER_NOW_AFTER_OFFSET,
  enableTimeSlots = ENABLE_TIME_SLOTS,
  maxPreOrderDate = MAX_PRE_ORDER_DATE,
}) {
  if (!bizObject) {
    throw {
      success: false,
      message: "`bizObject` undefined",
    };
  }
  if (!fulfilmentType) {
    throw {
      success: false,
      message: "`fulfilmentType` undefined",
    };
  }
  if (!storeObject) {
    throw {
      success: false,
      message: "`storeObject undefined`",
    };
  }

  _bizObject = bizObject;
  _fulfilmentType = fulfilmentType;
  _storeObject = storeObject;

  HIDE_DELIVER_NOW = hideDeliverNow;
  HIDE_DELIVER_NOW_AFTER_OFFSET = hideDeliverNowAfterOffset;
  ENABLE_TIME_SLOTS = enableTimeSlots;
  MAX_PRE_ORDER_DATE = maxPreOrderDate;
}

//check if deliver or pickup later is available.
function isDeliverPickupLaterAvailable() {
  let data = {
    datePicker: true,
    timeSlots: ENABLE_TIME_SLOTS,
    timePicker: true,
    maxDeliveryDate: dayjs().add(MAX_PRE_ORDER_DATE, "d"),
    minDeliveryDate: dayjs(),
  };
  return {
    success: true,
    data,
  };
}

function isDeliverPickupNowAvailable() {
  let dateNow = dayjs();

  let availableForToday = _getAvailableSlots(dateNow);
  let available = false;

  // current time + offset should be greater than or equals to
  // restaurant start time
  const isStoreOpen = storeOpenStatus(availableForToday, dateNow);

  if (availableForToday.length && isStoreOpen) {
    available = true;
  }

  if (HIDE_DELIVER_NOW) {
    available = false;
  }

  return {
    success: available,
    data: null,
  };
}

function storeOpenStatus(filteredSlots, dateTime) {
  filteredSlots = filteredSlots.filter(function (slot) {
    let slotStartTime = dayjs(slot.start_time, "hh:mm:ss"),
      startTime = dateTime.clone(),
      offset = getOffsetTime({
        fulfillmentType: _fulfilmentType,
        store: _storeObject,
        biz: _bizObject,
      });
    startTime = startTime
      .set("hour", slotStartTime.hour())
      .set("minute", slotStartTime.minute())
      .set("second", slotStartTime.second())
      .set("millisecond", 0);

    const currentTime = dateTime.add(offset, "milliseconds");

    return currentTime >= startTime;
  });

  return filteredSlots.length > 0;
}

//return offset time.
function _getOffsetTime() {
  return getOffsetTime({
    fulfillmentType: _fulfilmentType,
    store: _storeObject,
    biz: _bizObject,
  });
}

//get available slots for dateTime
function _getAvailableSlots(dateTime) {
  return getAvailableSlots({
    fulfillmentType: _fulfilmentType,
    dateTime: dateTime,
    store: _storeObject,
    biz: _bizObject,
  });
}

//return opening and closing time for selected store with offset
function _getOpeningClosingForDate(dateTime) {
  if (!_storeObject || !_bizObject) return "store not set";
  return getOpeningClosingForDate({
    fulfillmentType: _fulfilmentType,
    store: _storeObject,
    biz: _bizObject,
    date: dateTime,
  });
}

//return the next available delivery datetime for store.
function getNextAvailableDateTime() {
  let date = dayjs();
  let arr = [0, 1, 1, 1, 1, 1, 1];
  for (let days of arr) {
    let availableTime = _getOpeningClosingForDate(date.add(days, "days"));
    if (availableTime) {
      return availableTime;
    }
  }
}

const deliveryTimeService = {
  init,
  getOffsetTime: _getOffsetTime,
  isDeliverPickupNowAvailable,
  isDeliverPickupLaterAvailable,
  getAvailableSlots: _getAvailableSlots,
  getOpeningClosingForDate: _getOpeningClosingForDate,
  getNextAvailableDateTime,
};

export default deliveryTimeService;
