import React from "react";

import classNames from "classnames";

import Loader from "../loader/index.component";

import "./index.component.scss";

export default function AppButton({
  isDisabled,
  variant,
  children,
  fullWidth,
  className,
  isLoading,
  buttonColor,
  style,
  loaderColor,
  onClickCallback,
}) {
  // variables
  let buttonStyles = style || {};
  const disabled = isDisabled || isLoading;

  const handleClick = (event) => {
    if (disabled) return;
    event.stopPropagation();
    onClickCallback();
  };

  const ButtonLoader = () => (
    <Loader
      loaderColor={
        variant === "contained"
          ? loaderColor
            ? loaderColor
            : "#FFFFFF"
          : buttonColor
      }
    />
  );

  if (variant === "contained") {
    buttonStyles = {
      backgroundColor: buttonColor,
      borderColor: buttonColor,
      opacity: isDisabled ? 0.5 : 1,
    };
  } else if (variant === "outlined") {
    buttonStyles = {
      backgroundColor: "#FFFFFF",
      borderColor: buttonColor,
      opacity: isDisabled ? 0.5 : 1,
    };
  } else if (variant === "text") {
    buttonStyles = {
      height: 30,
      backgroundColor: "transparent",
      border: "none",
      opacity: isDisabled ? 0.5 : 1,
    };
  }

  return (
    <div
      disabled={disabled}
      style={buttonStyles}
      className={`${className ? className : ""} ${classNames({
        "app-button-xyz": !disabled,
        "full-width": fullWidth,
        "app-button-xyz disabled-button": disabled,
      })}`}
      onClick={handleClick}
    >
      {isLoading ? <ButtonLoader /> : children}
    </div>
  );
}
