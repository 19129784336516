import React from "react";
export default function LocationIcon({
  fill,
  size,
  className,
  onClickCallback,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="143"
      height="120"
      viewBox="0 0 143 120"
    >
      <defs>
        <path
          id="jp0jrrgesa"
          d="M0.2 0.009L47.607 0.009 47.607 105.603 0.2 105.603z"
        />
        <path
          id="xhvecfomec"
          d="M0.064 0.009L22.787 0.009 22.787 76.382 0.064 76.382z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#C6C6C6"
                  d="M52.598 77.375l40.32-11.694 40.01 10.8-40.025-1.98-40.305 2.874m-11.155-.567L0 63.933l18.355-27.186-7.422 12.08 30.51 27.981"
                  transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000) translate(0.000000, 40.075901)"
                />
                <path
                  fill="#B7B7B7"
                  d="M140.418 76.852L92.903 64.026 45.01 77.916 12.979 48.542 41.442 2.215 62.574 15.184 91.116 5.961 119.848 11.73z"
                  transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000) translate(0.000000, 40.075901)"
                />
                <path
                  fill="#FFF"
                  d="M118.614 12.864L138.02 74.554 92.888 62.371 45.426 76.135 15.026 48.256 41.966 4.407 62.366 16.927 91.21 7.606 118.614 12.864 119.848 11.73 118.922 12.864 119.848 11.73 120.162 10.167 91.022 4.317 62.782 13.442 40.918 0.023 10.933 48.828 44.593 79.696 92.919 65.681 142.815 79.15 121.085 10.352 120.162 10.167 119.848 11.73z"
                  transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000) translate(0.000000, 40.075901)"
                />
              </g>
              <path
                fill="#FFF"
                d="M134.607 104.089L92.007 88.287 53.694 84.513 30.571 59.14 32.928 56.993 55.226 81.46 92.729 85.154 92.923 85.226 135.716 101.099 134.607 104.089M125.369 72.092L91.124 63.237 91.051 63.21 66.716 54.318 67.81 51.324 92.073 60.19 126.167 69.006 125.369 72.092"
                transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000)"
              />
              <path
                fill="#FFF"
                d="M22.134 75.313L22.132 72.125 56.611 72.102 90.78 53.55 108.947 48.179 109.851 51.236 92.006 56.512 57.422 75.289 22.134 75.313M49.122 105.603L29.763 105.603 29.763 102.415 48.229 102.415 92.432 75.542 127.427 74.374 127.533 77.56 93.374 78.701 49.122 105.603"
                transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000)"
              />
              <path
                fill="#909090"
                d="M45.428 116.211l3.045-10.608h.65l30.491-18.537 12.393 1.22.469.174.43 13.984-.015.004-.003-.001-47.462 13.764m4.207-14.65l4.872-16.968 19.828 1.953-24.7 15.016m42.74-16.443l-8.225-.81 8.05-4.893.175 5.703m-13.505-1.33l-23.469-2.312 1.777-6.188h.246l13.597-7.383c1.701 5.334 2.138 8.476 2.138 8.476s.308-4.06 2.771-11.14l8.32-4.518 6.803 2.486.073.027.581.15.38 12.366-13.217 8.036M58.364 71.15l4.055-14.126 3.83-1.237c1.56 3.352 2.78 6.396 3.733 9.055L58.364 71.15m19.303-10.48c.272-.66.56-1.339.864-2.034l1.723.629-2.587 1.405m13.935-.652l-3.618-1.323 3.518-1.91.1 3.233m-7.611-2.782l-4.13-1.509c.784-1.648 1.66-3.382 2.637-5.19l8.726-2.82.173 5.65-.617.183-6.79 3.686"
                transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000)"
              />
              <path
                fill="#C6C6C6"
                d="M45.427 116.213L45.428 116.211 92.888 102.447 92.891 102.448 45.427 116.213M92.476 88.46l-.469-.173-12.393-1.221 4.534-2.757 8.225.81.103 3.341m-18.143-1.914l-19.828-1.953.894-3.116 23.469 2.312-4.535 2.757M91.705 63.387l-.58-.15-.074-.027-6.803-2.486 3.736-2.029 3.618 1.323.103 3.37m-11.45-4.123l-1.724-.63c.412-.938.854-1.907 1.33-2.908l4.13 1.51-3.737 2.028"
                transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000)"
              />
              <path
                fill="#C6C6C6"
                d="M57.176 75.29l1.188-4.14 11.618-6.308c.389 1.087.734 2.112 1.037 3.064L57.422 75.29h-.246m18.752-10.048c.49-1.407 1.064-2.933 1.739-4.571l13.113-7.12.617-.182.105 3.417-15.574 8.456"
                transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000)"
              />
              <g transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000) translate(48.273245, 0.000000)">
                <mask id="2kszo2drhb" fill="#fff">
                  <use xlinkHref="#jp0jrrgesa" />
                </mask>
                <path
                  fill="#C6C6C6"
                  d="M0.849 105.603L0.2 105.603 1.36 101.562 43.812 75.753 43.925 79.416 0.849 105.603"
                  mask="url(#2kszo2drhb)"
                />
                <path
                  fill="#494949"
                  d="M47.607 22.726c0-12.55-10.172-22.725-22.723-22.725-12.551 0-22.725 10.174-22.725 22.725 0 4.876 1.553 9.382 4.165 13.086.064.098.126.198.199.302 15.906 22.618 18.36 40.268 18.36 40.268s1.236-16.297 18.578-40.56c.06-.084.104-.163.155-.244 2.514-3.656 3.991-8.08 3.991-12.852"
                  mask="url(#2kszo2drhb)"
                />
              </g>
              <path
                fill="#FFF"
                d="M59.791 23.52c0-7.38 5.983-13.365 13.366-13.365S86.523 16.14 86.523 23.52c0 7.38-5.983 13.365-13.366 13.365s-13.366-5.985-13.366-13.365"
                transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000)"
              />
              <g transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000) translate(73.092979, 0.000000)">
                <mask id="a7v5uzps4d" fill="#fff">
                  <use xlinkHref="#xhvecfomec" />
                </mask>
                <path
                  fill="#FFF"
                  d="M11.747 46.39c1.955-3.315 4.235-6.848 6.894-10.568.06-.084.104-.163.155-.244 1.886-2.742 3.188-5.916 3.721-9.344-.533 3.428-1.835 6.602-3.72 9.344-.052.08-.096.16-.156.244-2.659 3.72-4.939 7.253-6.894 10.568m11.04-23.664c0-3.137-.635-6.126-1.785-8.845 1.15 2.719 1.785 5.708 1.785 8.845"
                  mask="url(#a7v5uzps4d)"
                />
                <path
                  fill="#919191"
                  d="M.064 76.382V36.886c7.383 0 13.366-5.985 13.366-13.365 0-7.381-5.983-13.366-13.366-13.366V0c9.413 0 17.489 5.723 20.938 13.88 1.15 2.719 1.785 5.708 1.785 8.845 0 1.193-.092 2.365-.27 3.508-.533 3.428-1.835 6.602-3.72 9.344-.052.08-.096.16-.156.244-2.659 3.72-4.939 7.253-6.894 10.568-1.971 3.343-3.613 6.467-4.979 9.337-.476 1.001-.918 1.97-1.33 2.909C.567 69.745.064 76.382.064 76.382"
                  mask="url(#a7v5uzps4d)"
                />
              </g>
              <path
                fill="#FFF"
                d="M73.157 36.886V10.155c7.383 0 13.366 5.985 13.366 13.366 0 7.38-5.983 13.365-13.366 13.365"
                transform="translate(-498.000000, -253.000000) translate(400.000000, 112.000000) translate(98.000000, 141.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
