const BREAK_SM = 768;

export default class ResponsiveHelper {
  /**
   * Is this a mobile view
   *
   * @param {number} width
   */
  static isMobile(width) {
    return width < BREAK_SM;
  }

  /**
   * Get the banner image cdn width from the screen size
   *
   * @param {number} width
   * @return {number} pixel width of the image
   */
  static getBannerCdnWidthFromScreenWidth(width) {
    if (width < BREAK_SM) {
      return 540;
    }

    if (width > 1024) {
      return 1200;
    }

    return 800;
  }

  /**
   * Get the height from a width to height ratio
   *
   * @param {number} width
   * @param {number} ratio  ratio in decimal format (Eg: 5/2)
   * @returns {number} height
   */
  static getHeightFromWidthHeightRatio(width, ratio) {
    return width / ratio;
  }
}
