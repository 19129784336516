import React from "react";
import classNames from "classnames";

import { Dialog } from "@material-ui/core";

import CrossIcon from "../../assets/icons/cross.icon";
import AppButton from "../button/index.component";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function ConfirmDialog({
  id,
  open,
  isLoading,
  title,
  description,
  buttonText,
  buttonColor,
  children,
  cancelButtonText,
  onCancelClickCallback,
  toggleConfirmDialogCallback,
  buttonClickCallback,
  openAsBottomSheet = false,
}) {
  const handleClose = () => {
    toggleConfirmDialogCallback();
  };

  const handleConfirmClick = () => {
    buttonClickCallback();
  };

  return (
    <Dialog
      id={id || `confirm-dialog`}
      open={open}
      keepMounted
      onClose={handleClose}
      classes={{
        root: "confirmation-dialog-root",
        container: classNames("confirmation-dialog-container", {
          "confirmation-dialog-bottom-sheet": openAsBottomSheet,
        }),
        paper: "confirmation-dialog-paper",
      }}
      disableScrollLock={true}
    >
      <div className="confirmation-form">
        <div className="dialog-header">
          <Typography
            variant="h1"
            weight="bold"
            className="form-heading"
            fontColor="#182135"
          >
            {title}
          </Typography>
          <CrossIcon
            fill="#A3A3A4"
            size={{ width: 16, heigth: 16 }}
            className="close-dialog-icon"
            onClickCallback={handleClose}
          />
        </div>
        <div className="dialog-content">
          {React.isValidElement(children) ? (
            children
          ) : (
            <Typography
              variant="h3"
              weight="regular"
              className="confirmation-text"
              fontColor="#626264"
            >
              {description}
            </Typography>
          )}
        </div>
        <div className="dialog-footer">
          {cancelButtonText ? (
            <AppButton
              fullWidth
              variant="contained"
              className="confirm-button"
              isLoading={isLoading}
              onClickCallback={() => onCancelClickCallback?.()}
            >
              <Typography variant="h3" weight="bold">
                {cancelButtonText}
              </Typography>
            </AppButton>
          ) : null}
          <AppButton
            fullWidth
            variant="contained"
            className="confirm-button"
            isLoading={isLoading}
            onClickCallback={handleConfirmClick}
            buttonColor={buttonColor}
          >
            <Typography variant="h3" weight="bold" fontColor="#FFFFFF">
              {buttonText}
            </Typography>
          </AppButton>
        </div>
      </div>
    </Dialog>
  );
}
