import upsdkService from "../../services/upsdkService";

export const FETCH_LIKES_PENDING = "FETCH_LIKES_PENDING";
export const FETCH_LIKES_ERROR = "FETCH_LIKES_ERROR";
export const FETCH_LIKES_SUCCESS = "FETCH_LIKES_SUCCESS";
export const FETCH_FAV_PENDING = "FETCH_FAV_PENDING";
export const FETCH_FAV_SUCCESS = "FETCH_FAV_SUCCESS";
export const FETCH_FAV_ERROR = "FETCH_FAV_ERROR";

function fetchFavPending() {
  return {
    type: FETCH_FAV_PENDING,
  };
}
function fetchFavSuccess(favorites) {
  return {
    type: FETCH_FAV_SUCCESS,
    payload: favorites,
  };
}
function fetchFavError(error) {
  return {
    type: FETCH_FAV_ERROR,
    error: error,
  };
}

function fetchLikesPending() {
  return {
    type: FETCH_LIKES_PENDING,
  };
}

function fetchLikesSuccess(catalogue) {
  return {
    type: FETCH_LIKES_SUCCESS,
    payload: catalogue,
  };
}

function fetchLikesError(error) {
  return {
    type: FETCH_LIKES_ERROR,
    error: error,
  };
}

export function fetchLikes() {
  return (dispatch) => {
    dispatch(fetchLikesPending());
    upsdkService
      .getUserLikes()
      .then((response) => {
        dispatch(fetchLikesSuccess(response.data.likes));
      })
      .catch((error) => {
        dispatch(fetchLikesError(error));
      });
  };
}

export function likeItem(item) {
  return (dispatch) => {
    upsdkService.likeItem(item).then(() => {
      dispatch(fetchLikes());
      dispatch(fetchFavorites());
    });
  };
}

export function unlikeItem(item) {
  return (dispatch) => {
    upsdkService.unlikeItem(item).then(() => {
      dispatch(fetchLikes());
      dispatch(fetchFavorites());
    });
  };
}

export function clearLikes() {
  return (dispatch) => {
    dispatch(fetchLikesSuccess(null));
  };
}

function getFavouritesList(likes, catalogueItems) {
  const itemList = [];
  const likedData = likes;

  likedData.forEach((row) => {
    let item = catalogueItems?.find(
      (catalogueItem) => catalogueItem?.id === row?.item?.id,
    );
    if (item) {
      itemList.push(item);
    }
  });

  return itemList;
}

export function fetchFavorites() {
  let catalogueItems = [];
  return (dispatch) => {
    dispatch(fetchFavPending());
    upsdkService
      .getCatalogue()
      .then((res) => {
        catalogueItems = res.items || [];
        const allCategories = res.categories || [];
        const validCategories = [];
        validCategories.push(
          ...(allCategories ?? [])
            .flatMap((category) => [
              category?.id,
              ...(category?.sub_categories ?? []).map(
                (subCategory) => subCategory?.id,
              ),
            ])
            .filter(Boolean),
        );
        const validItems =
          catalogueItems?.filter((item) =>
            validCategories.includes(item?.category?.id),
          ) || [];
        upsdkService
          .getUserLikes()
          .then((response) => {
            const items = getFavouritesList(response.data.likes, validItems);
            dispatch(fetchFavSuccess(items));
          })
          .catch((error) => {
            dispatch(fetchFavError(error));
          });
      })
      .catch((error) => {
        dispatch(fetchFavError(error));
      });
  };
}
