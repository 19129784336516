import React from "react";

export default function HistoryIcon({ size, className, fill = "black" }) {
  return (
    <svg
      width={size.width}
      height={size.height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.865 1.85C7.24444 1.85 2.66188 6.35874 2.58099 11.9333H1H0.687128L0.883052 12.1773L3.81639 15.8294L3.93333 15.975L4.05028 15.8294L6.98389 12.1773L7.17983 11.9333H6.86694H4.82528C4.90594 7.59195 8.48056 4.08379 12.8652 4.08379C17.2997 4.08379 20.9066 7.67305 20.9066 12.0833C20.9066 16.4936 17.2997 20.0829 12.8652 20.0829C10.7006 20.0829 8.67025 19.2401 7.14798 17.7088L7.04275 17.6029L6.93638 17.7076L5.5544 19.0679L5.44694 19.1737L5.55327 19.2806C7.50021 21.2381 10.0973 22.3167 12.865 22.3167C18.5352 22.3167 23.15 17.7268 23.15 12.0833C23.15 6.43984 18.5352 1.85 12.865 1.85ZM11.8931 6.59248H11.7431V6.74248V12.5494V12.6219L11.7999 12.6669L15.1281 15.3054L15.2451 15.3982L15.3384 15.2816L16.5493 13.7691L16.6435 13.6515L16.5254 13.5579L13.9865 11.5451V6.74248V6.59248H13.8365H11.8931Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
    </svg>
  );
}
