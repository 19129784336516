/* eslint-disable no-case-declarations */
import clone from "../../helpers/deepClone";
import {
  FETCH_CATALOGUE_ERROR,
  FETCH_CATALOGUE_PENDING,
  FETCH_CATALOGUE_SUCCESS,
  UPDATE_CATALOGUE,
  UPDATE_CATALOGUE_PENDING,
} from "../actions/catalogue";

const initialState = {
  pending: false,
  data: null,
  error: null,
  updateCataloguePending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CATALOGUE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CATALOGUE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null,
      };
    case FETCH_CATALOGUE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: null,
      };

    case UPDATE_CATALOGUE_PENDING:
      return {
        ...state,
        updateCataloguePending: true,
      };

    case UPDATE_CATALOGUE:
      const data = clone(state.data);
      const categories = data.categories;

      for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === action.payload.category_id) {
          categories[i].items = action.payload.items || [];
          break;
        } else {
          const subCategories = categories[i]?.sub_categories || [];
          for (let j = 0; j < subCategories.length; j++) {
            if (subCategories[j].id === action.payload.category_id) {
              subCategories[j].items = action.payload.items || [];
              break;
            }
          }
        }
      }

      return {
        ...state,
        updateCataloguePending: false,
        data: data,
      };

    default:
      return state;
  }
}
