import React from "react";

export default function EggIndicatorIcon({ fill, size, className }) {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.3"
        y="0.3"
        width="19.4"
        height="19.4"
        rx="1.7"
        fill="white"
        stroke="#F3D55B"
        stroke-width="0.6"
      />
      <circle cx="10" cy="10" r="4" fill="#F3D55B" />
    </svg>
  );
}
