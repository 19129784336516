// polyfills are injected using code splitting
export default async function loadPolyfills() {
  const formatter = new Intl.NumberFormat();
  if (!formatter.formatToParts) {
    await import("@formatjs/intl-numberformat");
  }

  if (!window.IntersectionObserver) {
    await import("intersection-observer");
  }
}
