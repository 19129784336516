import React from "react";
export default function LandingScreenRadioActiveIcon({
  fill,
  className,
  stroke,
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="4.5"
        y="4.5"
        width="15"
        height="15"
        rx="7.5"
        fill="#FFF"
        fillRule="nonzero"
        stroke={stroke || "#D9D9D9"}
      />
    </svg>
  );
}
