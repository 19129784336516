import { gql } from "@apollo/client";
import {
  CATEGORY_FRAGMENT,
  ITEM_EXTENDED_FRAGMENT,
  ITEM_FRAGMENT,
  OPTIONS_FRAGMENT,
  OPTIONS_GROUP_FRAGMENT,
} from "../fragments/catalogue.fragments";

const GET_ALL_ITEMS = gql`
  ${ITEM_FRAGMENT}
  query GetAllItems(
    $keyword: String
    $fulfilment_mode: FulfillmentMode
    $sort_by: String
    $filter_by: [Int!]
  ) {
    getAllItems(
      sort_by: $sort_by
      filter_by: $filter_by
      fulfillment_mode: $fulfilment_mode
      keyword: $keyword
    ) {
      ...ItemFragment
    }
  }
`;

const GET_CATALOGUE = gql`
  ${CATEGORY_FRAGMENT}
  query GetCatalog(
    $fulfilment_mode: FulfillmentMode
    $sort_by: String
    $filter_by: [Int!]
  ) {
    getAllCategories(
      fulfillment_mode: $fulfilment_mode
      sort_by: $sort_by
      filter_by: $filter_by
    ) {
      ...CategoryFragment
      sub_categories {
        description
        id
        image
        item_count
        name
        slug
        sort_order
      }
    }
    getSortFilters {
      filters {
        group
        options {
          id
          title
        }
      }
      sort_by
    }
  }
`;

/**
 * Apollo doesn't support recursive/cyclic fragmentation for now, so we are writing
 * it manually
 */
const GET_ITEM_OPTIONS = gql`
  ${OPTIONS_FRAGMENT}
  ${OPTIONS_GROUP_FRAGMENT}
  query getItemOptions($item_id: Int!) {
    getItemOptionGroups(item_id: $item_id) {
      ...OptionGroupFragment
      options {
        ...OptionFragment
        nested_options_group {
          ...OptionGroupFragment
          options {
            ...OptionFragment
            nested_options_group {
              ...OptionGroupFragment
              options {
                ...OptionFragment
                nested_options_group {
                  ...OptionGroupFragment
                  options {
                    ...OptionFragment
                    nested_options_group {
                      ...OptionGroupFragment
                      options {
                        ...OptionFragment
                        nested_options_group {
                          ...OptionGroupFragment
                          options {
                            ...OptionFragment
                            nested_options_group {
                              ...OptionGroupFragment
                              options {
                                ...OptionFragment
                                nested_options_group {
                                  ...OptionGroupFragment
                                  options {
                                    ...OptionFragment
                                    nested_options_group {
                                      ...OptionGroupFragment
                                      options {
                                        ...OptionFragment
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_ITEM_BY_ID = gql`
  ${ITEM_FRAGMENT}
  ${ITEM_EXTENDED_FRAGMENT}
  query getItemById($item_id: Int!) {
    getItemById(item_id: $item_id) {
      ...ItemFragment
      ...ItemExtendedFragment
    }
  }
`;

const GET_CATEGORY_BY_ID = gql`
  ${ITEM_FRAGMENT}
  query getCategory(
    $category_id: Int!
    $filter_by: [Int!]
    $sort_by: String
    $fulfilment_mode: FulfillmentMode
  ) {
    getCategory(
      id: $category_id
      filter_by: $filter_by
      fulfillment_mode: $fulfilment_mode
      sort_by: $sort_by
    ) {
      items(
        filter_by: $filter_by
        fulfillment_mode: $fulfilment_mode
        sort_by: $sort_by
      ) {
        ...ItemFragment
      }
    }
  }
`;

const GET_RECOMMENDED_ITEMS = gql`
  ${ITEM_FRAGMENT}
  query getRecommendedItems(
    $item_ids: [Int!]
    $fulfilment_mode: FulfillmentMode
  ) {
    getRecommendedItems(
      item_ids: $item_ids
      fulfillment_mode: $fulfilment_mode
    ) {
      objects {
        ...ItemFragment
      }
      total_count
    }
  }
`;

export default {
  GET_CATALOGUE,
  GET_ITEM_OPTIONS,
  GET_ALL_ITEMS,
  GET_ITEM_BY_ID,
  GET_CATEGORY_BY_ID,
  GET_RECOMMENDED_ITEMS,
};
