import React, { useRef } from "react";
import Shimmer from "react-shimmer-effect";

import "./index.component.scss";

const ItemUpdatePlaceholder = (key) => (
  <div className="item-update-placeholder" key={key}>
    <Shimmer>
      <div className="item-image-placeholder"></div>
    </Shimmer>
    <div className="item-update-placeholder-wrapper">
      <Shimmer>
        <div className="placeholder-1"></div>
      </Shimmer>
      <Shimmer>
        <div className="placeholder-2"></div>
      </Shimmer>
    </div>
  </div>
);

const CheckoutShimmer = () => {
  const componentRef = useRef();

  return (
    <div className="checkout-wrapper-shimmer" ref={componentRef}>
      <div className="container">
        <div className="checkout-container-shimmer">
          <div className="checkout-left-section-shimmer">
            <div className="checkout-widget-shimmer">
              <Shimmer>
                <div className="checkout-header-shimmer" />
              </Shimmer>
              <div className="customer-info-wrapper">
                {[1, 2, 3].map((item) => (
                  <ItemUpdatePlaceholder key={item} />
                ))}
              </div>
            </div>
            <div className="checkout-widget-shimmer">
              <Shimmer>
                <div className="checkout-header-shimmer" />
              </Shimmer>
              <ItemUpdatePlaceholder />
            </div>
            <div>
              <Shimmer>
                <div className="checkout-header-shimmer" />
              </Shimmer>
              <Shimmer>
                <div className="checkout-bill-text-one-shimmer" />
              </Shimmer>
              <Shimmer>
                <div className="checkout-bill-text-one-shimmer" />
              </Shimmer>
            </div>
          </div>
          <div className="checkout-right-section-shimmer">
            <Shimmer>
              <div className="checkout-header-shimmer" />
            </Shimmer>
            <Shimmer>
              <div className="checkout-bill-text-one-shimmer" />
            </Shimmer>

            <div className="bill-breakup-wrapper">
              <Shimmer>
                <div className="cart-header-shimmer" />
              </Shimmer>

              {[1, 2, 3].map((item) => (
                <div className="cart-bill-shimmer-wrapper" key={item}>
                  <Shimmer>
                    <div className="cart-bill-text-one-shimmer"></div>
                  </Shimmer>
                  <Shimmer>
                    <div className="cart-bill-text-two-shimmer"></div>
                  </Shimmer>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutShimmer;
