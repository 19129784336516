// Color shades to brighten the colors

export const bright_10 = 10;
export const bright_20 = 20;
export const bright_30 = 30;
export const bright_50 = 50;
export const bright_70 = 70;
export const bright_90 = 90;

export function increaseBrightness(hex, percent) {
  if (!hex || !percent) return;

  hex = hex.replace(/^\s*#|\s*$/g, "");

  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, "$1$1");
  }

  let r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  return (
    "#" +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
}

export function decreaseBrightness(hex, percent) {
  if (!hex || !percent) return;

  var r = parseInt(hex.substr(1, 2), 16),
    g = parseInt(hex.substr(3, 2), 16),
    b = parseInt(hex.substr(5, 2), 16);

  return (
    "#" +
    (0 | ((1 << 8) + (r * (100 - percent)) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + (g * (100 - percent)) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + (b * (100 - percent)) / 100)).toString(16).substr(1)
  );
}
