import React from "react";

export default function HomeIconOutlined({ fill, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      className={className}
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="2zxwyt4cla"
          d="M8.367 21.567V14.27h7.266v7.297h3.632v-9.84L12 4.43l-7.265 7.298v9.84h3.632zM12 1l12 12.053h-2.313V24h-8.476v-7.298h-2.422V24H2.313V13.053H0L12 1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <mask id="hhooqea1ib" fill={fill}>
          <use xmlnsXlink="#2zxwyt4cla" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#2zxwyt4cla" />
        <path fill="#000" d="M0 0H24V24H0z" mask="url(#hhooqea1ib)" />
      </g>
    </svg>
  );
}
