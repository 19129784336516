import React from "react";

export default function PlusIcon({ fill, size, className, onClickCallback }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size.width}
      height={size.height}
      viewBox="0 0 18 18"
      className={className}
      onClick={onClickCallback}
    >
      <defs>
        <path
          id="c8yxoyg3ha"
          d="M18 10.286L10.286 10.286 10.286 18 7.714 18 7.714 10.286 0 10.286 0 7.714 7.714 7.714 7.714 0 10.286 0 10.286 7.714 18 7.714z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-976 -661) translate(928 150) translate(32 488) translate(16 23)">
                  <path d="M0 0H18V18H0z" />
                  <mask id="1igm48f8tb" fill="#fff">
                    <use xlinkHref="#c8yxoyg3ha" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#c8yxoyg3ha" />
                  <path fill={fill} d="M0 0H18V18H0z" mask="url(#1igm48f8tb)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
