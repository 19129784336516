import React, { useState, lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import gql from "graphql-tag";
import { useSubscription } from "@apollo/client/react/hooks/useSubscription";
import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";

import BaseLayout from "../common/base-layout/index.component";
import { getAllCustomRoutes } from "common/helpers/getFooterLinks";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import getCurrentFallbackHandler from "../helpers/shimmerRoutingHelper";
import CustomPage from "../views/custom-page/index.component";
import { ORDERING_TYPE_QR } from "../constants/enums.constants";
import upsdkService from "common/services/upsdkService";
import { useTranslation } from "react-i18next";
import Explore from "../views/explore/index.component";

const LandingScreen = lazy(
  () =>
    import(
      "../views/landing-screen/index.component" /* webpackChunkName: "LandingScreen" */
    ),
);

// const Explore = lazy(
//   () =>
//     import("../views/explore/index.component" /* webpackChunkName: "Menu" */),
// );

const Cart = lazy(
  () => import("../views/cart/index.component" /* webpackChunkName: "Cart" */),
);
const Orders = lazy(
  () =>
    import("../views/orders/index.component" /* webpackChunkName: "Orders" */),
);
const Wallet = lazy(
  () =>
    import("../views/wallet/index.component" /* webpackChunkName: "Wallet" */),
);
const Profile = lazy(
  () =>
    import(
      "../views/profile/index.component" /* webpackChunkName: "Profile" */
    ),
);
const Coupons = lazy(
  () =>
    import(
      "../views/coupons/index.component" /* webpackChunkName: "Coupons" */
    ),
);
const Checkout = lazy(
  () =>
    import(
      "../views/checkout/index.component" /* webpackChunkName: "Checkout" */
    ),
);
const Favorites = lazy(
  () =>
    import(
      "../views/favorites/index.component" /* webpackChunkName: "Favourites" */
    ),
);
const ItemDetails = lazy(
  () =>
    import(
      "../views/item-details/index.component" /* webpackChunkName: "ItemDetails" */
    ),
);
const StoreLocator = lazy(
  () =>
    import(
      "../views/store-locator/index.component" /* webpackChunkName: "StoreLocator" */
    ),
);
const OrderSuccess = lazy(
  () =>
    import(
      "../views/order-success/index.component" /* webpackChunkName: "OrderSuccess" */
    ),
);
const ReferAndEarn = lazy(
  () =>
    import(
      "../views/refer-and-earn/index.component" /* webpackChunkName: "ReferAndEarn" */
    ),
);

const ByRef = lazy(
  () =>
    import("../views/by-ref/index.component" /* webpackChunkName: "ByRef" */),
);

const PageNotFound = lazy(
  () =>
    import(
      "../views/page-not-found/index.component" /* webpackChunkName: "PageNotFound" */
    ),
);

const PaymentRedirect = lazy(() => import("../views/pg/index.component"));

function Routes(props) {
  const [currentFallback, setCurrentFallback] = useState(<div />);

  // props
  const { config, orderingType, userData } = props;
  const { t } = useTranslation();

  // variables
  const menuPageConfig = pageSelector.getMenuPage({ config });
  const walletPageConfig = configSelector.getWalletConfig({ config });
  const itemDetailPageConfig = pageSelector.getItemDetailPage({ config });
  const orderSuccessPageConfig = pageSelector.getOrderSuccessPage({ config });
  const storeLocatorPageConfig = pageSelector.getStoreLocatorPage({ config });
  const cartPageConfig = pageSelector.getCartPage({ config });
  const checkoutPageConfig = pageSelector.getCheckoutPage({ config });
  const profilePageConfig = pageSelector.getProfilePage({ config });
  const referEarnPageConfig = pageSelector.getReferEarnPage({ config });
  const orderHistoryPageConfig = pageSelector.getOrderHistoryPage({ config });
  const favouritePageConfig = pageSelector.getFavouritesPage({ config });
  const couponsPageConfig = pageSelector.getCouponsPage({ config });
  const landingScreenConfig = pageSelector.getLandingPage({ config });
  const pageNotFoundConfig = pageSelector.getPageNotFoundPage({ config });
  const isReferralEnabled = configSelector.getReferralBool({ config });
  const isQrModeEnabled = orderingType === ORDERING_TYPE_QR;

  const allRoutes = getAllCustomRoutes(
    configSelector.getCustomPages({ config }),
  );

  const privateRoutes = [
    "/past-orders",
    "/favourites",
    "/wallet",
    "/profile",
    "/coupons",
    "/refer-and-earn",
  ];

  //they are being passed to helper function via base layout
  const parameterObj = {
    getCurrentFallbackHandler,
    setCurrentFallback,
    allRoutes,
    privateRoutes,
  };

  let rootRoute = "";
  const subPath = configSelector.getSubPath({ config });

  if (subPath) {
    rootRoute = "/" + subPath;
  }

  const menuRoute = landingScreenConfig?.enabled
    ? `${rootRoute}/menu/`
    : `${rootRoute}/`;

  const [getCart, { data: initialCart }] = useLazyQuery(gql`
    query UserCart {
      getCart {
        items
      }
    }
  `);

  useEffect(() => {
    if (userData?.username) {
      getCart();
    }
  }, [userData, getCart]);

  useEffect(() => {
    if (initialCart) {
      try {
        const existingCart = upsdkService.getCart();
        if (existingCart?.data?.items?.length) {
          return;
        }

        const parsedItems = JSON.parse(initialCart.getCart.items);

        if (parsedItems?.data) {
          upsdkService.setCart(parsedItems?.data);
        } else {
          upsdkService.clearCart();
        }
      } catch {}
    }
  }, [initialCart]);

  const { data, loading } = useSubscription(gql`
    subscription test {
      getCartUpdates {
        items
      }
    }
  `);

  useEffect(() => {
    if (!loading && data) {
      try {
        const cartItems = data.getCartUpdates.items;
        const parsedItems = JSON.parse(cartItems);
        if (parsedItems?.data) {
          upsdkService.setCart(parsedItems?.data);
        } else {
          upsdkService.clearCart();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [data, loading]);

  return (
    <BaseLayout {...parameterObj}>
      <Suspense fallback={currentFallback}>
        <Switch>
          {!isQrModeEnabled && landingScreenConfig?.enabled && (
            <Route exact path={rootRoute || "/"}>
              <LandingScreen data={data} loading={loading} />
            </Route>
          )}

          {subPath && (
            <Route exact path="/">
              <Redirect to={rootRoute} />
            </Route>
          )}

          {itemDetailPageConfig.enabled && (
            <Route
              exact
              path={`${rootRoute}/item-list/:id/:slug`}
              component={ItemDetails}
            />
          )}

          <Route
            exact
            path={`${rootRoute}/order-success/:id`}
            component={OrderSuccess}
          />

          {!isQrModeEnabled && storeLocatorPageConfig.enabled && (
            <Route
              exact
              path={`${rootRoute}/store-locator`}
              component={StoreLocator}
            />
          )}

          {allRoutes.map((page) => {
            return (
              <Route
                path={`${rootRoute}${page.path}`}
                key={page.path}
                component={CustomPage}
              />
            );
          })}

          {cartPageConfig.enabled && (
            <Route
              exact
              {...props}
              path={`${rootRoute}/cart`}
              component={Cart}
            />
          )}

          {checkoutPageConfig.enabled && (
            <Route
              {...props}
              path={`${rootRoute}/checkout`}
              component={Checkout}
            />
          )}

          {orderHistoryPageConfig.enabled && (
            <Route
              exact
              {...props}
              path={`${rootRoute}/past-orders`}
              component={Orders}
            />
          )}

          {!isQrModeEnabled && favouritePageConfig.enabled && (
            <Route
              exact
              {...props}
              path={`${rootRoute}/favourites`}
              component={Favorites}
            />
          )}

          {!isQrModeEnabled && walletPageConfig.enable && (
            <Route {...props} path={`${rootRoute}/wallet`} component={Wallet} />
          )}

          {!isQrModeEnabled && profilePageConfig.enabled && (
            <Route
              exact
              {...props}
              path={`${rootRoute}/profile`}
              component={Profile}
            />
          )}

          {couponsPageConfig.enabled && (
            <Route
              exact
              {...props}
              path={`${rootRoute}/coupons`}
              component={Coupons}
            />
          )}

          {!isQrModeEnabled &&
            referEarnPageConfig.enabled &&
            isReferralEnabled && (
              <Route
                exact
                {...props}
                path={`${rootRoute}/refer-and-earn`}
                component={ReferAndEarn}
              />
            )}

          {referEarnPageConfig.enabled && isReferralEnabled && (
            <Route
              exact
              {...props}
              path={`${rootRoute}/by_ref`}
              component={ByRef}
            />
          )}

          <Route
            {...props}
            path={`${rootRoute}/pg/:type?`}
            component={PaymentRedirect}
          />

          {/* Keep menu routes in the bottom to make sure other routes are accessible and work fine */}
          {menuPageConfig.enabled && (
            <Route exact path={menuRoute} component={Explore} />
          )}

          {menuPageConfig.enabled && (
            <Route
              exact
              path={`${menuRoute}:catSlug/:subCatSlug`}
              component={Explore}
            />
          )}

          {menuPageConfig.enabled && (
            <Route exact path={`${menuRoute}:catSlug`} component={Explore} />
          )}

          <Route
            exact
            path={`${rootRoute}/landing-page-demo/:variant`}
            component={LandingScreen}
          />

          <Route {...props}>
            {pageNotFoundConfig.enabled ? (
              <PageNotFound />
            ) : (
              <Redirect to={menuRoute} />
            )}
          </Route>
        </Switch>
      </Suspense>
    </BaseLayout>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    userData: state.auth.data,
    authToken: upsdkSelector.getAuthHeader(state),
  };
}

export default connect(mapStateToProps, null)(Routes);
