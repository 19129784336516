const defaultPaymentMode = {
  type: null,
  name: null,
  icon: null,
};

export default class PaymentMode {
  constructor(mode = defaultPaymentMode) {
    this.type = mode;
    switch (this.type) {
      case "cash":
        this.name = "Cash";
        this.icon = "";
        break;
      case "prepaid":
        this.name = "Wallet";
        this.icon = "";
        break;
      case "payment_gateway":
        this.name = "Pay Online";
        this.icon = "";
        break;
      case "paytm":
        this.name = "PayTM";
        this.icon = "";
        break;
    }
  }
}
