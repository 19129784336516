import React from "react";
import Shimmer from "react-shimmer-effect";

import { ITEM_CATEGORY_DUMMY_DATA } from "../../../mock-data/index.mock-data";
import "./index.component.scss";

const CustomPageShimmer = () => (
  <div className="custom-page-wrapper-shimmer">
    <div className="container">
      <div className="custom-page-container-shimmer">
        <Shimmer>
          <div className="custom-page-header-shimmer"></div>
        </Shimmer>
        {ITEM_CATEGORY_DUMMY_DATA.map((details) => (
          <div key={details} className="custom-page-card-shimmer">
            <Shimmer>
              <div className="custom-page-text-one-shimmer" />
            </Shimmer>
            <Shimmer>
              <div className="custom-page-text-two-shimmer" />
            </Shimmer>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default CustomPageShimmer;
