export const constructSlug = (itemName, slug) => {
  if (slug) {
    return slug;
  }

  if (itemName) {
    const lowerCaseStr = itemName.toLowerCase();
    const validatedSlug = validateUrl(lowerCaseStr);
    const constructedSlug = validatedSlug.replace(/#/g, "").replace(/\s/g, "-");

    return constructedSlug || itemName;
  }

  return itemName;
};

const validateUrl = (url) => {
  if (url && url.length) {
    return url.replace(/\//g, "");
  }
  return url;
};
