export default function (url, callback) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.crossorigin = true;
  script.defer = true;

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.innerHTML = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}
