const ROUTES_ARRAY = [
  "/item-list",
  "/cart",
  "/checkout",
  "/order-success",
  "/store-locator",
  "/past-orders",
  "/favourites",
  "/wallet",
  "/profile",
  "/coupons",
  "/refer-and-earn",
  "/by_ref",
  "/pg",
];

export default ROUTES_ARRAY;
