import React from "react";

export default function LocationCircleIcon({
  size = { width: "24", height: "24" },
  className,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      className={className}
    >
      <defs>
        <path
          id="ami2pqompa"
          d="M1.266 10.8c.553-5.006 4.528-8.98 9.534-9.534V0h2.4v1.266c5.006.553 8.98 4.528 9.534 9.534H24v2.4h-1.266c-.553 5.006-4.528 8.98-9.534 9.534V24h-2.4v-1.266c-5.006-.553-8.98-4.528-9.534-9.534H0v-2.4h1.266zm2.42 0H4.8v2.4H3.685c.526 3.679 3.436 6.589 7.115 7.115V19.2h2.4v1.115c3.679-.526 6.589-3.436 7.115-7.115H19.2v-2.4h1.115c-.526-3.679-3.436-6.589-7.115-7.115V4.8h-2.4V3.685c-3.679.526-6.589 3.436-7.115 7.115zM12 14.4c1.325 0 2.4-1.075 2.4-2.4S13.325 9.6 12 9.6 9.6 10.675 9.6 12s1.075 2.4 2.4 2.4zm0 2.4c-2.651 0-4.8-2.149-4.8-4.8S9.349 7.2 12 7.2s4.8 2.149 4.8 4.8-2.149 4.8-4.8 4.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-908 -216) translate(400 76) translate(432 96) translate(56 24) translate(20 20)">
                    <path d="M0 0H24V24H0z" />
                    <mask id="7nk68i3g7b" fill="#fff">
                      <use xlinkHref="#ami2pqompa" />
                    </mask>
                    <use
                      fill="#503E9D"
                      fillRule="nonzero"
                      xlinkHref="#ami2pqompa"
                    />
                    <path
                      fill="#000"
                      d="M0 0H24V24H0z"
                      mask="url(#7nk68i3g7b)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
