import React from "react";

export default function VegDietIndicatorIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 20 20"
      className={className}
    >
      <g fill="none">
        <path fill="#FFF" stroke={fill} d="M.3.3h19.4v19.4H.3z" />
        <circle cx="10" cy="10" r="5" fill={fill} />
      </g>
    </svg>
  );
}
