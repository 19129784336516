import React from "react";
import Shimmer from "react-shimmer-effect";

import { ITEM_CATEGORY_DUMMY_DATA } from "../../../mock-data/index.mock-data";

import "./index.component.scss";

const ProfileShimmer = () => (
  <div className="profile-wrapper-shimmer">
    <div className="container">
      <div className="profile-container-shimmer">
        <div className="profile-details-shimmer">
          <Shimmer>
            <div className="profile-header-shimmer" />
          </Shimmer>
          <Shimmer>
            <div className="profile-section-heading-shimmer" />
          </Shimmer>
          <div className="profile-card-container-shimmer">
            {ITEM_CATEGORY_DUMMY_DATA.map((details) => (
              <div key={details.id} className="profile-card-shimmer">
                <Shimmer>
                  <div className="placeholder-two-shimmer" />
                </Shimmer>
                <Shimmer>
                  <div className="placeholder-one-shimmer" />
                </Shimmer>
              </div>
            ))}
          </div>
        </div>
        <div className="profile-offers-shimmer">
          <Shimmer>
            <div className="profile-header-shimmer" />
          </Shimmer>
          {ITEM_CATEGORY_DUMMY_DATA.map((coupon) => (
            <Shimmer key={coupon.id}>
              <div className="profile-offers-coupons-shimmer" />
            </Shimmer>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default ProfileShimmer;
