import React from "react";
export default function FavouriteOutlinedIcon({ fill, size, className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.99992 2.66663C2.97492 2.66663 1.33325 4.30829 1.33325 6.33329C1.33325 9.99996 5.66659 13.3333 7.99992 14.1086C10.3333 13.3333 14.6666 9.99996 14.6666 6.33329C14.6666 4.30829 13.0249 2.66663 10.9999 2.66663C9.75992 2.66663 8.66325 3.28229 7.99992 4.22463C7.66181 3.74303 7.21264 3.34999 6.69043 3.07879C6.16822 2.80759 5.58835 2.66621 4.99992 2.66663Z"
        stroke="#CC0000"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
